export const getBlogAdmin = async (id) => {
    try {

        const token = localStorage.getItem('token');
        if (token) {
            const response = await fetch(`https://api.testbuddy.live/v1/admin/blog/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `bearer ${JSON.parse(token)}`
                }
            });

            if (response.status >= 200 && response.status <= 399) {
                const data = await response.json();
                return data;
            } else if (response.status === 401) {
                localStorage.removeItem('token');
                const data = await response.json();
                return { status: 401, message: data.message };
            } else {
                const status = response.status;
                const data = await response.json();
                return { status: status, message: data.message };
            }
        }
    } catch (error) {
        console.error('Error editing profile:', error);
    }
};

export const createBlogAdmin = async (formData) => {
    try {

        const token = localStorage.getItem('token');
        if (token) {
            const response = await fetch(`https://api.testbuddy.live/v1/admin/blog/create`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `bearer ${JSON.parse(token)}`
                },
                body: JSON.stringify(formData)
            });

            if (response.status >= 200 && response.status <= 399) {
                const data = await response.json();
                return data;
            } else if (response.status === 401) {
                localStorage.removeItem('token');
                const data = await response.json();
                return { status: 401, message: data.message };
            } else {
                const status = response.status;
                const data = await response.json();
                return { status: status, message: data.message };
            }

        }
    } catch (error) {
        console.error('Error editing profile:', error);
    }
};

export const updateBlogAdmin = async (formData) => {
    try {

        const token = localStorage.getItem('token');
        if (token) {
            const response = await fetch(`https://api.testbuddy.live/v1/admin/blog/update`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `bearer ${JSON.parse(token)}`
                },
                body: JSON.stringify(formData)
            });

            if (response.status >= 200 && response.status <= 399) {
                const data = await response.json();
                return data;
            } else if (response.status === 401) {
                localStorage.removeItem('token');
                const data = await response.json();
                return { status: 401, message: data.message };
            } else {
                const status = response.status;
                const data = await response.json();
                return { status: status, message: data.message };
            }

        }
    } catch (error) {
        console.error('Error editing profile:', error);
    }
};

export const uploadImageAdmin = async (formData) => {
    try {
        const token = localStorage.getItem('token');

        if (token) {
            const response = await fetch("https://api.testbuddy.live/v1/admin/blog/image/add", {
                method: "POST",
                headers: {
                    'Authorization': `bearer ${JSON.parse(token)}`
                },
                body: formData
            });

            if (response.status >= 200 && response.status <= 399) {
                const data = await response.json();
                return data;
            } else if (response.status === 401) {
                localStorage.removeItem('token');
                const data = await response.json();
                return { status: 401, message: data.message };
            } else {
                const status = response.status;
                const data = await response.json();
                return { status: status, message: data.message };
            }

        }
    } catch (error) {
        console.error('Error Occured! Try Again', error);
    }
}

export const getBlog = async (id) => {
    try {

        const token = localStorage.getItem('token');
        if (token) {
            const response = await fetch(`https://api.testbuddy.live/v1/blog/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `bearer ${JSON.parse(token)}`
                }
            });

            if (response.status >= 200 && response.status <= 399) {
                const data = await response.json();
                return data;
            } else if (response.status === 401) {
                localStorage.removeItem('token');
                const data = await response.json();
                return { status: 401, message: data.message };
            } else {
                const status = response.status;
                const data = await response.json();
                return { status: status, message: data.message };
            }
        }
    } catch (error) {
        console.error('Error editing profile:', error);
    }
};

export const getFilteredBlogs = async (filters) => {
    try {
        const token = localStorage.getItem('token');
        if (token) {
            const response = await fetch(`https://api.testbuddy.live/v1/blogs`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `bearer ${JSON.parse(token)}`
                },
                body: JSON.stringify(filters)
            });

            if (response.status >= 200 && response.status <= 399) {
                const data = await response.json();
                return data;
            } else if (response.status === 401) {
                localStorage.removeItem('token');
                const data = await response.json();
                return { status: 401, message: data.message };
            } else {
                const status = response.status;
                const data = await response.json();
                return { status: status, message: data.message };
            }
        }
    } catch (error) {
        console.error('Error editing profile:', error);
    }
};

export const likeBlog = async (data) => {
    try {
        const token = localStorage.getItem('token');

        if (token) {
            const response = await fetch("https://api.testbuddy.live/v1/blog/like", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `bearer ${JSON.parse(token)}`
                },
                body: JSON.stringify(data)
            });

            if (response.status >= 200 && response.status <= 399) {
                const formattedData = await response.json();
                return formattedData; 
            } else if (response.status === 401) {
                localStorage.removeItem('token');
                const formattedData = await response.json();
                return { status: 401, message: formattedData.message };
            } else {
                const status = response.status;
                const formattedData = await response.json();
                return { status: status, message: formattedData.message };
            }

        }
    } catch (error) {
        console.error('Error Occured! Try Again', error);
    }
}