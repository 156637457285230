import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Checkbox, TableCell, TableRow, Typography, Box, IconButton, Button, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeletePopUp from 'components/Admin/AdminComponents/Popups/DeletePopUp';
import ViewQuestionPopUp from 'components/Admin/AdminComponents/Popups/ViewQuestionPopUp.js';
import QuestionImageModal from 'components/Admin/AdminComponents/Popups/QuestionImagePopUp';
import MathJax from 'react-mathjax';
import { removeImgTags, renderMixedContent } from 'utils/renderMathExp';
import { CheckCircleIcon } from 'lucide-react';
import CustomChip from '../../Common/CustomChip';

export default function SingleQuestion({ Question, index, deleteFromArray, handleSelectQuestion, selected }) {

  console.log(Question);

  const { _id, question } = Question;
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [viewQuestion, setViewQuestion] = useState(false);
  const [questionImageModal, setQuestionImageModal] = useState(false);
  const [questionVerified, setQuestionVerified] = useState(Question.verified);
  const navigate = useNavigate();

  function handleDeleteQuestion() {
    deleteQuestion();
    setShowDeleteModal(false);
  }

  const deleteQuestion = async () => {
    try {
      const response = await fetch("https://api.testbuddy.live/v1/admin/question/delete",
        {
          method: "POST",
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("token")
            )}`,
          },
          body: JSON.stringify({ "questionId": _id }),
        }
      );
      if (response.status === 200) {
        deleteFromArray();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleVerifyQues = async () => {
    try {
      const response = await fetch('https://api.testbuddy.live/v1/admin/question/verify', {
        method: "POST",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
        },
        body: JSON.stringify({ "questionId": _id }),
      });
      const data = await response.json();
      if (data.verified === true) {
        setQuestionVerified(true);
        alert('Question successfully verified');
      } else if (data.verified === false) {
        alert('Question not verified');
      }
    } catch (e) {
      console.error('Error verifying question:', e);
    }
  };

  const handleUpdateQues = () => {
    navigate('/admin/dashboard/editquestion/' + Question._id);
  };

  return (
    <>
      <TableRow selected={selected}>
        <TableCell padding="checkbox">
          <Checkbox
            checked={selected}
            onChange={() => handleSelectQuestion(_id, selected, questionVerified)}
            size="small"
            disabled={!Question.verified}
          />
        </TableCell>
        <TableCell sx={{}}>{'...'}{_id.substring(_id.length - 6)}</TableCell>
        <TableCell>{Question.questionKey}</TableCell>
        <TableCell sx={{width: '75%'}}>
          <Box sx={{ maxWidth: '100%', position: 'relative', whiteSpace: 'nowrap' }}>
            {Question.hasOwnProperty("questionContent") ? (
              <MathJax.Provider>
                <Typography
                  variant="body2"
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {renderMixedContent(removeImgTags(Question.questionContent))}
                </Typography>
              </MathJax.Provider>
            ) : (
              <Box display="flex" alignItems="center">
                <Typography variant="body2">Question Text not available</Typography>
              </Box>
            )}
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
              <CustomChip label={Question.language} />
              <CustomChip label={Question.exam} />
              <CustomChip label={Question.year} />
              <CustomChip label={Question.subject} />
              <CustomChip label={Question.chapter} />
              <CustomChip label={Question.chapterGroup} />
              {Question.tags.map((value, index) => (
                <CustomChip key={index} label={value} variant="outlined" />
              ))}
              {Question.levelRating && (
                <CustomChip label={`Level ${Question.levelRating}`} />
              )}
              {Array.isArray(Question.topic) ? (
                Question.topic.map((value, index) => (
                  <CustomChip key={index} label={value} />
                ))
              ) : (
                Question.topic && <CustomChip label={Question.topic} />
              )}
              <CustomChip label={Question.status} />
              <CustomChip label={Question.section} />
              <CustomChip label={Question.verified ? "Verified" : "Not Verified"} />
              <CustomChip label={Question.completed ? "Completed" : "Incomplete"} />
              <CustomChip label={Question.questionType} />
            </Box>
          </Box>
        </TableCell>
        <TableCell>
          {Question.verified ? <Tooltip title="Verified" arrow >
            <CheckCircleIcon color='green' /> </Tooltip> :
            <Button onClick={handleVerifyQues}>Verify</Button>}
        </TableCell>
        <TableCell>
          <IconButton color="error" onClick={() => setShowDeleteModal(true)} size="small">
            <DeleteIcon />
          </IconButton>
        </TableCell>
        <TableCell>
          <IconButton color="primary" onClick={handleUpdateQues} size="small">
            <EditIcon />
          </IconButton>
        </TableCell>
        <TableCell>
          <IconButton color="info" onClick={() => setViewQuestion(true)} size="small">
            <VisibilityIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      {showDeleteModal && (
        <DeletePopUp
          showModal={() => setShowDeleteModal(true)}
          handleCloseModal={handleDeleteQuestion}
          setShowDeleteModal={setShowDeleteModal}
        />
      )}
      {viewQuestion && (
        <ViewQuestionPopUp
          handleViewQuestion={() => viewQuestion}
          handleCloseViewQuestion={() => setViewQuestion(false)}
          setShowDeleteModal={setShowDeleteModal}
          setViewQuestion={setViewQuestion}
          index={Question._id}
          isTest={false}
        />
      )}
      {questionImageModal && (
        <QuestionImageModal
          setQuestionImageModal={setQuestionImageModal}
          questionImageModal={questionImageModal}
          Question={Question}
        />
      )}
    </>
  );
}
