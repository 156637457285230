const { InputLabel, MenuItem, Select, TextField,  OutlinedInput } = require("@mui/material")

export const CustomMenu = (props) => {
  return (
    <MenuItem value={props.value} sx={{ fontSize: '13px' }} {...props}>{props.children}</MenuItem>
  )
}

export const CustomSelect = (props) => {
  return (
    <Select
      sx={{
        fontSize: '15px',
        height: 'auto',
        '.MuiSelect-select': {
          padding: '8px 14px',
        },
        '.MuiSvgIcon-root': {
          fontSize: '20px',
        },
      }}
      {...props}
    >
      {props.children}
    </Select>
  )
}

export const CustomInputLabel = (props) => {
  return (
    <InputLabel
      sx={{
        fontSize: '15px',
        top: '-9px',
      }}
      {...props}
    >
      {props.children}
    </InputLabel>
  )
}

export const CustomTextField = (props) => {
  return (
    <TextField
      sx={{
        width: 'auto',
        maxWidth: 200,
        fontSize: "15px",
        '& .MuiInputBase-input': {
          padding: '8px 14px',
        },
      }}
      {...props}
      >
      {props.children}
      </TextField>
  )
}

export const CustomOutlinedInput = (props) => {
  return (
    <OutlinedInput
      sx={{
        width: 'auto',
        maxWidth: 200,
        fontSize: 15,
        '& .MuiInputBase-input': {
          padding: '8px 14px',
        },
      }}
      {...props}
    >{props.children}

    </OutlinedInput>
  )
}