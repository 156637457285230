import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import InfoState from './contexts/InfoState';
import ErrorPage from './components/Common/ErrorPage';

import CreateTest from './components/Admin/AdminComponents/TestCreation/CreateTest';
import TestContext from './components/Admin/AdminComponents/Contexts/TestContext';
import ViewTest from './components/Admin/AdminComponents/ViewTest/ViewTest';

import ScrollToTop from './utils/ScrollToTop';
import VerifyTest from './components/Admin/AdminComponents/TestCreation/VerifyTest';

import EditorPage from './components/Editor/EditorPage';
import Blog from './components/Editor/Blog';
import ViewQuestion from 'components/Admin/AdminComponents/ViewQuestions/ViewQuestion';
import AdminDashboardLayout from 'components/Admin/AdminDashboardLayout';
import QuestionEditorLayout from 'components/Admin/AdminComponents/AddQuestion/QuestionEditorLayout';
import BlogEditor from 'components/Admin/AdminComponents/Blog/BlogEditor';
import 'jodit/es5/jodit.min.css';
import SingleBlog from 'components/Admin/AdminComponents/Blog/SingleBlog';
import ViewBlog from 'components/Admin/AdminComponents/Blog/ViewBlog';
import OpenBlog from 'components/Admin/AdminComponents/Blog/OpenBlog';
import BlogEditorLayout from 'components/Admin/AdminComponents/Blog/BlogEditorLayout';

function App() {

  return (
      <Router>
        <ScrollToTop />
        <InfoState>
          <TestContext>
            <Routes>
              <Route path="/" exact element={<AdminDashboardLayout />} />


              <Route path="/admin" element={<AdminDashboardLayout />}>
                <Route path="dashboard/addquestion" element={<QuestionEditorLayout />} />
                <Route path="dashboard/editquestion/:quesId" element={<QuestionEditorLayout />} />
                <Route path="dashboard/viewquestions" element={<ViewQuestion />} />

                <Route path="dashboard/createtest" element={<CreateTest />} />
                <Route path="dashboard/viewtest" element={<ViewTest />} />
                <Route path="dashboard/edittest/:testId" element={<CreateTest />} />
                <Route path="dashboard/verifytest/:id" element={<VerifyTest />} />

                <Route path="dashboard/createblog" element={<EditorPage />} />
                <Route path="editblog/:blogId" element={<BlogEditorLayout/>} />
                {/* <Route path="updateblog/:id" element={<EditorPage />} /> */}
                {/* <Route path="blogeditor" element={<BlogEditor />} /> */}
                <Route path="dashboard/viewblog" element={<ViewBlog/>} />
                <Route path="blog/:id" element={<OpenBlog />} />
              </Route>
              <Route path="*" element={<ErrorPage />} />
            </Routes>
          </TestContext>
        </InfoState>
      </Router>
  );
}

export default App;