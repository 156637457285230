import { questionStore } from "components/store/questionStore";

import languageData from 'assets/data/LanguageData.json';
import SubjectData from 'assets/data/SubjectData.json';
import ChaptersData from 'assets/data/ChaptersData.json'
import LevelRating from 'assets/data/LevelData.json';
import TagsData from 'assets/data/Test/TagsData.json';


const { Accordion, AccordionSummary, Typography, AccordionDetails, Divider, FormControl, Box } = require("@mui/material")
const { MdExpandMore } = require("react-icons/md")
const { CustomInputLabel, CustomSelect, CustomMenu, CustomOutlinedInput } = require("../../Common/CustomComponents");

export const QuesDetailDropdowns = () => {

    const {
        language,
        questionKey,
        questionType,
        section,
        questionContent,
        options,
        correct_options,
        answerExplanation,
        marks,
        negMarks,
        isPartialMarks,
        subject,
        chapter,
        chapterGroup,
        topic,
        levelRating,
        tags,
        paperId,
        paperTitle,
        exam,
        year,
        isOutOfSyllabus,
        isBonus,
        setLanguage,
        setQuestionKey,
        setQuestionType,
        setSection,
        setQuestionContent,
        setOptions,
        addOptions,
        deleteOption,
        setCorrectOptions,
        setAnswerExplanation,
        setMarks,
        setNegMarks,
        setIsPartialMarks,
        setSubject,
        setChapter,
        setChapterGroup,
        setTopic,
        setLevelRating,
        setTags,
        setPaperId,
        setPaperTitle,
        setExam,
        setYear,
        setIsOutOfSyllabus,
        setIsBonus,
    } = questionStore(state => state);

    const handleTagChange = (event) => {
        const { value } = event.target;
        setTags(typeof value === 'string' ? value.split(',') : value);
    };

    const years = Array.from({ length: 2024 - 2000 + 1 }, (_, i) => 2000 + i).reverse();



    return (
        <Accordion elevation={0} sx={{ width: '100%', border: '1px solid #ccc', borderRadius: 2, }}>
            <AccordionSummary
                expandIcon={<MdExpandMore />}
                aria-controls="question-content"
                id="question-header"

            >
                <Typography>
                    Question Details
                </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>

                <Divider sx={{ fontSize: '0.9rem' }}>Language and Question Type</Divider>
                <StyledBoxLayout>
                    <FormControl sx={{ minWidth: 120 }}>
                        <CustomInputLabel id="language-label">Language</CustomInputLabel>
                        <CustomSelect
                            labelId="language-label"
                            value={language}
                            label="Language"
                            onChange={(e) => setLanguage(e.target.value)}
                        >
                            {
                                languageData.languages.map((lang, index) => (
                                    <CustomMenu key={index} value={lang.value}>{lang.name}</CustomMenu>
                                ))
                            }
                        </CustomSelect>
                    </FormControl>
                    {
                        language !== 'english' &&
                        <FormControl sx={{ minWidth: 120 }}>
                            <CustomInputLabel id="question-key-label">Question Key</CustomInputLabel>
                            <CustomOutlinedInput
                                id="question-key-label"
                                value={questionKey}
                                onChange={(e) => setQuestionKey(e.target.value)}
                            />
                        </FormControl>

                    }
                    <FormControl sx={{ minWidth: 120 }}>
                        <CustomInputLabel id="questionType-label">Question Type</CustomInputLabel>
                        <CustomSelect
                            labelId="questionType-label"
                            value={questionType}
                            label="Question Type"
                            onChange={(e) => setQuestionType(e.target.value)}
                        >
                            <CustomMenu value="mcq">MCQ</CustomMenu>
                            <CustomMenu value="numerical">Numerical</CustomMenu>
                            <CustomMenu value="multi-correct">Multi Correct</CustomMenu>
                        </CustomSelect>
                    </FormControl>
                </StyledBoxLayout>

                <Divider sx={{ fontSize: '0.9rem' }}>Section and Marking</Divider>
                <StyledBoxLayout>
                    <FormControl sx={{ minWidth: 120 }}>
                        <CustomInputLabel id="section-label">Section</CustomInputLabel>
                        <CustomSelect
                            labelId="section-label"
                            value={section}
                            label="Section"
                            onChange={(e) => setSection(e.target.value)}
                        >
                            <CustomMenu value="A">A</CustomMenu>
                            <CustomMenu value="B">B</CustomMenu>
                            <CustomMenu value="C">C</CustomMenu>
                            <CustomMenu value="D">D</CustomMenu>
                            <CustomMenu value="E">E</CustomMenu>
                            <CustomMenu value="F">F</CustomMenu>
                        </CustomSelect>
                    </FormControl>
                    <FormControl sx={{ minWidth: 140 }}>
                        <CustomInputLabel id="partialMarks-label">Partial Marking</CustomInputLabel>
                        <CustomSelect
                            labelId="partialMarks-label"
                            value={isPartialMarks}
                            label="Partial Marking"
                            onChange={(e) => setIsPartialMarks(e.target.value)}
                        >
                            <CustomMenu value="true">Yes</CustomMenu>
                            <CustomMenu value="false">No</CustomMenu>
                        </CustomSelect>
                    </FormControl>
                    <FormControl sx={{ minWidth: 120 }}>
                        <CustomInputLabel id="marks-label">Marks</CustomInputLabel>
                        <CustomOutlinedInput
                            id="marks-label"
                            value={marks}
                            inputProps={{
                                readOnly: true,
                            }}
                        />
                    </FormControl>

                    <FormControl sx={{ minWidth: 120 }}>
                        <CustomInputLabel id="neg-marks-label">Negative Marks</CustomInputLabel>
                        <CustomOutlinedInput
                            id="neg-marks-label"
                            value={negMarks}
                            inputProps={{
                                readOnly: true,
                            }}
                        />
                    </FormControl>

                </StyledBoxLayout>

                <Divider sx={{ fontSize: '0.9rem' }}>Subject and Curriculum Details</Divider>
                <StyledBoxLayout>

                    <FormControl sx={{ minWidth: 120 }}>
                        <CustomInputLabel id="subject-label">Subject</CustomInputLabel>
                        <CustomSelect
                            labelId="subject-label"
                            value={subject}
                            onChange={(e) => setSubject(e.target.value)}
                            label="Subject"
                        >
                            {
                                SubjectData.subjects.map((subject, index) => (
                                    <CustomMenu key={index} value={subject.value}>{subject.name}</CustomMenu>
                                ))
                            }
                        </CustomSelect>
                    </FormControl>
                    {<FormControl sx={{ minWidth: 160 }}>
                        <CustomInputLabel id="chapter-label">Chapter</CustomInputLabel>
                        <CustomSelect
                            labelId="chapter-label"
                            value={chapter}
                            label="Chapter"
                            onChange={(e) => setChapter(e.target.value)}
                        >
                            {ChaptersData[subject] &&
                                ChaptersData[subject].map((chapter, index) => (
                                    <CustomMenu key={index} value={chapter}>{chapter}</CustomMenu>
                                ))
                            }
                        </CustomSelect>
                    </FormControl>}
                    <FormControl sx={{ minWidth: 160 }}>
                        <CustomInputLabel id="chapterGroup-label">Chapter Group</CustomInputLabel>
                        <CustomSelect
                            labelId="chapterGroup-label"
                            value={chapterGroup}
                            label="Chapter Group"
                            onChange={(e) => setChapterGroup(e.target.value)}
                        >
                            <CustomMenu value="inorganic-chemistry">Inorganic Chemistry</CustomMenu>
                            <CustomMenu value="organic-chemistry">Organic Chemistry</CustomMenu>
                            <CustomMenu value="physical-chemistry">Physical Chemistry</CustomMenu>
                        </CustomSelect>
                    </FormControl>
                    <FormControl sx={{ minWidth: 180 }}>
                        <CustomInputLabel id="topic-label">Topic</CustomInputLabel>
                        <CustomSelect
                            labelId="topic-label"
                            value={topic}
                            label="Topic"
                            onChange={(e) => setTopic(e.target.value)}
                        >
                            <CustomMenu value="group-14-elements-carbon-family">group-14-elements-carbon-family</CustomMenu>
                        </CustomSelect>
                    </FormControl>
                </StyledBoxLayout>

                <Divider sx={{ fontSize: '0.9rem' }}>Difficulty and Tags</Divider>
                <StyledBoxLayout>
                    <FormControl sx={{ minWidth: 120 }}>
                        <CustomInputLabel id="level-rating-label">Level Rating</CustomInputLabel>
                        <CustomSelect
                            labelId="level-rating-label"
                            value={levelRating}
                            label="Level Rating"
                            onChange={(e) => setLevelRating(e.target.value)}
                        >
                            {
                                LevelRating.levels.map((level, index) => (
                                    <CustomMenu key={index} value={level}>{level}</CustomMenu>
                                ))

                            }
                        </CustomSelect>
                    </FormControl>
                    <FormControl sx={{ minWidth: 120 }}>
                        <CustomInputLabel id="tags-label">Tags</CustomInputLabel>
                        <CustomSelect
                            labelId="tags-label"
                            multiple
                            value={tags}
                            onChange={handleTagChange}
                            label="Tags"
                            renderValue={(selected) => selected.join(', ')}
                        >
                            {
                                TagsData.tags.map((tag, index) => (
                                    <CustomMenu key={index} value={tag.value}>{tag.name}</CustomMenu>
                                ))
                            }
                        </CustomSelect>
                    </FormControl>
                </StyledBoxLayout>

                <Divider sx={{ fontSize: '0.9rem' }}>Paper and Exam Details</Divider>
                <StyledBoxLayout>
                    <FormControl sx={{ minWidth: 120 }}>
                        <CustomInputLabel id="paperid-label">Paper ID</CustomInputLabel>
                        <CustomSelect
                            labelId="paperid-label"
                            value={paperId}
                            label="Paper ID"
                            onChange={(e) => setPaperId(e.target.value)}
                        >
                            <CustomMenu value="jee-main-2023-online-15th-april-morning-shift">jee-main-2023-online-15th-april-morning-shift</CustomMenu>
                        </CustomSelect>
                    </FormControl>

                    <FormControl sx={{ minWidth: 120 }}>
                        <CustomInputLabel id="paper-title-label">Paper Title</CustomInputLabel>
                        <CustomOutlinedInput
                            id="paper-title-label"
                            value={paperTitle}
                            onChange={(e) => setPaperTitle(e.target.value)}
                        />
                    </FormControl>

                    <FormControl sx={{ minWidth: 120 }}>
                        <CustomInputLabel id="exam-label">Exam</CustomInputLabel>
                        <CustomSelect
                            labelId="exam-label"
                            value={exam}
                            label="Exam"
                            onChange={(e) => setExam(e.target.value)}
                        >
                            <CustomMenu value="jee-main">JEE Main</CustomMenu>
                            <CustomMenu value="jee-advanced">JEE Advanced</CustomMenu>
                        </CustomSelect>
                    </FormControl>
                    <FormControl sx={{ minWidth: 120 }}>
                        <CustomInputLabel id="year-label">Year</CustomInputLabel>
                        <CustomSelect
                            labelId="year-label"
                            value={year}
                            label="Year"
                            onChange={(e) => setYear(e.target.value)}
                        >
                            {years.map((year) => (
                                <CustomMenu key={year} value={year}>
                                    {year}
                                </CustomMenu>
                            ))}
                        </CustomSelect>
                    </FormControl>
                </StyledBoxLayout>

                <Divider sx={{ fontSize: '0.9rem' }}>Special Conditions</Divider>
                <StyledBoxLayout>
                    <FormControl sx={{ minWidth: 120 }}>
                        <CustomInputLabel id="outOfSyllabus-label">Out of Syllabus</CustomInputLabel>
                        <CustomSelect
                            labelId="outOfSyllabus-label"
                            value={isOutOfSyllabus}
                            label="Out of Syllabus"
                            onChange={(e) => setIsOutOfSyllabus(e.target.value)}
                        >
                            <CustomMenu value="true">Yes</CustomMenu>
                            <CustomMenu value="false">No</CustomMenu>
                        </CustomSelect>
                    </FormControl>
                    <FormControl sx={{ minWidth: 120 }}>
                        <CustomInputLabel id="bonus-label" >Bonus</CustomInputLabel>
                        <CustomSelect
                            labelId="bonus-label"
                            value={isBonus}
                            label="Bonus"
                            onChange={(e) => setIsBonus(e.target.value)}
                        >
                            <CustomMenu value="true">Yes</CustomMenu>
                            <CustomMenu value="false">No</CustomMenu>
                        </CustomSelect>
                    </FormControl>
                </StyledBoxLayout>

            </AccordionDetails>
        </Accordion>
    )
}

const StyledBoxLayout = ({ children, flexDirection = 'row', flexWrap = 'wrap', gap = 2 }) => {
    return (
        <Box sx={{ display: 'flex', flexDirection, flexWrap, gap }}>
            {children}
        </Box>
    );
};