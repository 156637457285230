// result page
// graphs
// blogs to mdn type

import React, { useEffect, useState } from 'react'
import draftToHtml from 'draftjs-to-html'
import { Link, useParams } from 'react-router-dom';
import { getBlog, getFilteredBlogs, likeBlog } from '../../services/blog/blogServices';
import ProfileImg from '../../assets/images/profile.png';
import Image from 'react-bootstrap/Image';
import moment from 'moment';
import CustomDropDown from '../Admin/AdminComponents/Common/CustomDropdown';
import styled from 'styled-components';
import Offcanvas from 'react-bootstrap/Offcanvas';

const Blog = () => {

    const [ blogData, setBlogData ] = useState({ title: '', description: '', tags: [], category: '', blogId: '', postedBy: {}, createdAt: '', noOfLikes: '', noOfViews: '' });
    const [ linkArray, setLinkArray ] = useState([]);
    const [ filters, setFilters ] = useState({tags: []});
    const [ tagsData, setTagsData ] = useState([
        { label: 'Maths', value: 'Maths' },
        { label: 'Physics', value: 'Physics' },
        { label: 'Chemistry', value: 'Chemistry' },
        { label: 'JEE', value: 'JEE' },
    ]);
    const [ tagsValue, setTagsValue ] = useState({});
    const [ otherBlogsData, setOtherBlogsData ] = useState([]);
    const [htmlString, setHtmlString] = useState({blocks: [], entityMap: []});
    const [ coverImg, setCoverImg ] = useState(null);
    const [ loading, setLoading ] = useState(true);
    const [ like, setLike ] = useState(false);
    const { id } = useParams();
    const [ rightPanelShow, setRightPanelShow ] = useState(false);

    const handleRightPanelClose = () => setRightPanelShow(false);
    const handleRightPanelShow = () => setRightPanelShow(true);

    useEffect(() => {
        if (id) {
            getBlogData(id);
        }
        console.log(htmlString);
    }, [id]);

    useEffect(() => {
        getBlogs();
    }, [filters])

    const handleTagsCustomOptions = (data) => {
        setTagsData([...tagsData, data]);
    }

    const handleFilterState = (data, name, multi) => {
        const values = data.map((val) => val.value);
        setFilters((prevValue) => ({ ...prevValue, [name]: values }));
    }

    const getBlogData = async (id) => {
        try {
            const response = await getBlog(id);
            console.log(response);
            if (response.error) {
                throw Error(response.error);
            }

            let temp = [...response.entityMap];
            const obj = new Object;
            temp.forEach((entity, i) => {
                obj[`${i}`] = entity;
            })

            setBlogData({ title: response.title, description: response.description, tags: [...response.tags], category: response.category, blogId: response._id, postedBy: response.postedBy, createdAt: response.createdAt, noOfLikes: response.noOfLikes, noOfViews: response.noOfViews });
            const htmlData = draftToHtml({ blocks: response.blocks, entityMap: obj });
            linkBlogs(htmlData);

        } catch (e) {
            console.error('Error Occured: ', e);
            alert('Error Occured! Please try again.')
        } finally {
            setLoading(false);
        }
    }

    const getBlogs = async () => {
        const data = { filter: filters };
        try {
            const response = await getFilteredBlogs(data);
            console.log(response);
            if (response.error) {
                throw Error(response.error);
            }
            setOtherBlogsData(response);
        } catch (e) {
            console.error('Error Occured: ', e);
            alert('Error Occured! Please try again.')
        } 
    }

    const linkBlogs = (temp) => {
        const tempArray = [];
        const parser = new DOMParser();
        const doc = parser.parseFromString(temp, "text/html");

        const headings = doc.querySelectorAll("h1, h2, h3, h4, h5, h6");

        headings.forEach((heading) => {
            const content = heading.textContent;
            heading.id = content.replace(/\s+/g, '-').toLowerCase();
            heading.style.scrollMarginTop = "calc(6rem + 2px)";
            tempArray.push({ label: content, href: content.replace(/\s+/g, '-').toLocaleLowerCase() })
        });

        const modifiedHTML = new XMLSerializer().serializeToString(doc);

        setLinkArray(tempArray);
        setHtmlString(modifiedHTML);
    }

    const handleLikeBlog = async () => {
        try {
            const data = { like: !like, blogId: blogData.blogId };
            console.log(data);
            const response = await likeBlog(data);
            console.log(response);
            if (response.error) {
                throw Error(response.error);
            }
            setLike(response.like);
            setBlogData({...blogData, noOfLikes: (blogData.noOfLikes + (response.like ? 1 : -1))})
        } catch (e) {
            console.error('Error Occured: ', e);
            alert('Error Occured! Please try again.')
        }
    }

    return ( !loading &&

        <div className='' style={{ backgroundColor: '#1b1b1b', minHeight: '100vh', color:'#fff'}}>
            <div className='container d-flex flex-row gap-4' style={{ position:'relative' }}>

                <div style={{display:'contents'}}>
                    <div className='d-lg-block d-none flex-1 pt-4' style={{ maxWidth: '300px', minWidth: '240px', position:'sticky', top:'6rem', maxHeight:'80vh' }}>
                        <div className='fw-bold fs-5 pb-2'>Explore More</div>
                        <div className='pb-2'>
                            <CustomDropDown
                                options={tagsData}
                                create={true}
                                placeholder='Tags'
                                onChange={handleFilterState}
                                name='tags'
                                multi={true}
                                onCreate={handleTagsCustomOptions}
                                values={filters.tags.length ? tagsValue : []}
                                style={{ minWidth: '120px', maxWidth: '160px', fontSize: '14px', color: 'black' }}
                            />
                        </div>
                        <ScrollDiv className='d-flex flex-column gap-2 ps-1' style={{ maxHeight: '67vh'}} >
                            {
                                otherBlogsData.map((blog) => (
                                    <div>
                                        <Link to={'/blog/' + blog._id} className='fw-bold fs-6' style={{ textDecoration: 'none', color: '#fff' }}>
                                            {blog.title}
                                        </Link>
                                    </div>
                                ))
                            }
                        </ScrollDiv>
                    </div>

                    <ScrollDiv className='d-md-block d-none flex-1 pt-4 pb-4' style={{ maxWidth: '240px', minWidth: '180px', overflow: 'auto', maxHeight: '360px', position: 'sticky', top: '6rem', order: '2', color:'#fff' }}>
                        <div className='fw-bold fs-5 pb-2'>In this article</div>
                        <div className='ps-3 py-2' style={{ borderLeft: '2px solid #4f4f4f', fontSize: '14px' }}>
                            {
                                linkArray.map((link, i) => (
                                    <div className='text-nowrap text-truncate py-1' key={i}>
                                        <a href={'#' + link.href} style={{ color: '#fff', textDecoration: 'none' }}>{link.label}</a>
                                    </div>
                                ))
                            }

                        </div>
                    </ScrollDiv>

                </div>

                

                <ScrollDiv className='container d-flex flex-column flex-1 pt-3' style={{ overflowY:'auto',  maxWidth:'640px' }}>
                    <div className='d-flex flex-row gap-4 align-items-start justify-content-between'>
                        <div className='fw-bold fs-1 pb-4'>{blogData.title}</div>
                        <button className='pt-2 d-md-none d-block' style={{backgroundColor:'transparent', border:'0'}} onClick={handleRightPanelShow}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" style={{height:'24px', width:'24px'}}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                            </svg>
                        </button>
                    </div>
                    <div className='d-flex flex-column gap-2 pb-4'>
                        <div className='d-flex flex-row gap-3 align-items-top pb-2'>
                            <div className=''>
                                <img src={ProfileImg} style={{ width: '64px', height: '64px' }} />
                            </div>
                            <div className='d-flex flex-column gap-1' style={{ fontSize: '20px' }}>
                                <div className='fw-bold'>{blogData.postedBy.name}</div>
                                <div style={{ color: '#727574', fontSize: '14px' }}>Published on {new Date(blogData.createdAt).toDateString() + ", " + moment(blogData.createdAt).fromNow()} </div>
                            </div>
                        </div>

                        <div className='d-flex gap-5 ps-2 py-2 fw-light' style={{ borderTop: '1px solid #f0f5f2', borderBottom: '1px solid #f0f5f2', color: '#727574' }}>
                            <div className='d-flex flex-row gap-2 align-items-center'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" style={{ height: '24px', width: '24px' }}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                </svg>
                                <div >{blogData.noOfViews}</div>
                            </div>
                            <div className='d-flex flex-row gap-2 align-items-center'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" onClick={handleLikeBlog} style={{ height: '24px', width: '24px', fill: (like ? 'red' : 'white'), color: (like ? 'red' : '') }}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z" />
                                </svg>
                                <div >{blogData.noOfLikes}</div>
                            </div>
                        </div>
                    </div>

                    <Image src={coverImg ? coverImg : 'https://media.nomadicmatt.com/currentfave.jpg'} alt='CoverPic' style={{ objectFit: 'cover' }} fluid />
                
                    <div className='py-4'>
                        <div dangerouslySetInnerHTML={{ __html: htmlString }} />
                    </div>
                </ScrollDiv>
                
            </div>

            <Offcanvas show={rightPanelShow} onHide={handleRightPanelClose}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>In this article</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className='ps-3 pb-2' style={{ borderLeft: '2px solid #4f4f4f', fontSize: '14px', color:'#fff' }}>
                        {
                            linkArray.map((link, i) => (
                                <div className='text-nowrap text-truncate py-1' key={i} onClick={handleRightPanelClose}>
                                    <a href={'#' + link.href} style={{ color: '#fff', textDecoration: 'none' }}>{link.label}</a>
                                </div>
                            ))
                        }

                    </div>
                </Offcanvas.Body>
            </Offcanvas>
            
        </div>
    );
};

export default Blog;

const ScrollDiv = styled.div`
    overflow-y: auto;
    -ms-overflow-style: none;  
    scrollbar-width: none;

    &::-webkit-scrollbar { 
        display: none;
    }
`
