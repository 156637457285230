import React, { useState } from 'react';
import {
  TextField,
  Button,
  Box,
  Typography,
  Paper,
  Grid,
  MenuItem,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate, useParams } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast, ToastContainer } from 'react-toastify';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
}));

export default function VerifyTest() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [date, setDate] = useState("");
  const [testId, setTestId] = useState(id);
  const [resumeEnabled, setResumeEnabled] = useState(true);
  const [verify, setVerify] = useState(false);
  const [verifiedDetails, setVerifiedDetails] = useState({});

  const handleTestState = (event) => {
    setTestId(event.target.value);
  };

  const clearState = () => {
    setDate(new Date().toLocaleString());
    setTestId("");
    setResumeEnabled(true);
    setVerify(false);
    setVerifiedDetails({});
  };

  const handleVerifyTest = async () => {
    try {
      if (validate()) {
        const object = {
          resumeEnabled,
          testId: id,
          ...(date && { publishDate: date }),
        };

        const response = await fetch("https://api.testbuddy.live/v1/admin/test/verify", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`,
          },
          body: JSON.stringify(object),
        });

        const data = await response.json();
        if (response.status === 200) {
          setVerifiedDetails(data);
          setVerify(true);
        } else {
          console.log(response);
        }
      } else {
        toast.error("Enter all required fields");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const validate = () => resumeEnabled !== false;

  return (
    <>
      <Box sx={{ p: 3 }}>
        <StyledPaper>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                onChange={handleTestState}
                value={testId}
                name="testId"
                label="Test ID"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <DatePicker
                showTimeSelect
                placeholderText="Enter Publish date"
                selected={date}
                onChange={(date) => setDate(date)}
                dateFormat="Pp"
                customInput={<TextField fullWidth variant="outlined" />}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                select
                fullWidth
                label="Resume Enabled"
                name="resumeEnabled"
                value={resumeEnabled}
                onChange={(e) => setResumeEnabled(e.target.value)}
                variant="outlined"
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </TextField>
            </Grid>
          </Grid>
        </StyledPaper>

        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
          <Button variant="outlined" onClick={clearState}>
            Clear Fields
          </Button>
          <Button variant="contained" color="primary" onClick={handleVerifyTest}>
            Verify Test
          </Button>
        </Box>

        {verify && (
          <StyledPaper>
            <Typography variant="h6" gutterBottom>Verification Results</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography><strong>Verified Status:</strong> {verifiedDetails.verified ? 'Yes' : 'No'}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography><strong>Error:</strong> {verifiedDetails.error}</Typography>
              </Grid>
            </Grid>

            {verifiedDetails.test && (
              <>
                <Grid container spacing={2} sx={{ mt: 2 }}>
                  <Grid item xs={12} sm={6}>
                    <Typography><strong>Test Name:</strong> {verifiedDetails.test.testName}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography><strong>Test Description:</strong> {verifiedDetails.test.testDescription}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography><strong>No of Questions:</strong> {verifiedDetails.test.qCount}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography><strong>Maximum Marks:</strong> {verifiedDetails.test.maxMarks}</Typography>
                  </Grid>
                </Grid>

                <Box sx={{ mt: 2 }}>
                  <Typography variant="h6" gutterBottom>Instructions:</Typography>
                  <ul>
                    {verifiedDetails.test.instructions && verifiedDetails.test.instructions.map((instruction, index) => (
                      <li key={index}>{instruction}</li>
                    ))}
                  </ul>
                </Box>
              </>
            )}

            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
              <Button variant="contained" color="primary" onClick={() => navigate("/admin/dashboard/")}>
                Return to Dashboard
              </Button>
              <Button variant="contained" color="secondary" onClick={clearState}>
                Verify Another
              </Button>
            </Box>
          </StyledPaper>
        )}
      </Box>
      <ToastContainer />
    </>
  );
}