import React, { useState, useEffect } from 'react';
import { getBlogAdmin } from '../../../../services/blog/blogServices';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Typography, TableCell, TableRow, IconButton } from '@mui/material';
import MathJax from 'react-mathjax';
import { CheckCircleIcon } from 'lucide-react';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CustomChip from '../Common/CustomChip.js';
import { removeImgTags, renderMixedContent } from 'utils/renderMathExp';
import DeletePopUp from './DeleteBlog';
import ViewBlogPopUp from './ViewBlogPopUp';

const SingleBlog = ({ Blog, index, deleteFromArray, handleSelectBlog, selected }) => {
    const [fetchedBlogData, setFetchedBlogData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { id } = useParams();
    const navigate = useNavigate();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [viewBlog, setViewBlog] = useState(false);
    const [BlogImageModal, setBlogImageModal] = useState(false);

    const { _id } = Blog;

    const extractFirstImage = (content) => {
        const imgTagMatch = content.match(/<img[^>]+src=["']([^">]+)["']/);
        return imgTagMatch ? imgTagMatch[1] : null;
    };

    const firstImageSrc = extractFirstImage(Blog.blogContent);

    const handleDeleteBlog = async () => {
        try {
            const response = await fetch("https://api.testbuddy.live/v1/admin/blogs", {
                method: "POST", 
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
                },
                body: JSON.stringify({
                    blogId: _id,
                    filter: {
                        status: "archived"
                    }
                }),
            });
    
            const data = await response.json();
            
            if (response.ok && data.success) {
                deleteFromArray();
            } else {
                console.error('Failed to archive blog.');
            }
        } catch (error) {
            console.error('Error deleting blog:', error);
        }
    };

    const handleVerifyBlog = async () => {
        try {
            const response = await fetch('https://api.testbuddy.live/v1/admin/blogs', {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
                },
                body: JSON.stringify({ 
                    blogId: _id,
                    filter: {
                        status: "published"
                    }
                }),
            });
            const data = await response.json();
            if (data.verified) {
                alert('Blog successfully verified');
            } else {
                alert('Blog not verified');
            }
        } catch (e) {
            console.error('Error verifying blog:', e);
        }
    };

    const handleUpdateBlog = () => {
        navigate(`/admin/editblog/${_id}`, { state: { blogData: Blog } });
    };

    const handleViewBlog = () => {
        navigate(`/admin/blog/${_id}`, { state: { blogData: Blog } });
    };

    useEffect(() => {
        const fetchBlogData = async () => {
            try {
                const response = await getBlogAdmin(id);
                if (response.error) {
                    throw new Error(response.error);
                }
                setFetchedBlogData(response);
            } catch (error) {
                setError(error.message);
                console.error('Error fetching blog data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchBlogData();
    }, [id]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!fetchedBlogData) {
        return <div>No blog data found.</div>;
    }

    const tags = fetchedBlogData.tags || [];

    return (
        <>
        <TableRow selected={selected}>
            <TableCell padding="checkbox"></TableCell>
            <TableCell>{_id.substring(_id.length-6)}</TableCell>
            <TableCell>{Blog.blogType}</TableCell>
            <TableCell sx={{ width: '75%' }}>
                <Box sx={{ display: 'flex', alignItems: 'flex-start', maxWidth: '100%' }}>
                    {firstImageSrc ? (
                        <img src={firstImageSrc} alt="Blog" style={{ width: '120px', height:"90px", marginRight: '20px', marginTop:"5%" }} />
                    ) : (
                        <Typography variant="body2" sx={{ color: 'red', marginRight: '10px' }}>
                            No image found
                        </Typography>
                    )}
                    <Box sx={{ flexGrow: 1 }}>
                    {Blog.hasOwnProperty("blogContent") ? (
                            <MathJax.Provider>
                                <Typography
                                    variant="body2"
                                    sx={{
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                    }}
                                >
                                    {renderMixedContent(removeImgTags(Blog.blogContent.split(' ').slice(0, 8).join(' ')))}
                                </Typography>
                            </MathJax.Provider>
                        ) : (
                            <Typography variant="body2">Blog Text not available</Typography>
                        )}

                        <Box sx={{ display: 'flex', flexWrap: 'wrap', mt: 2 }}>
                            <CustomChip label={Blog.category} />
                            <CustomChip label={Blog.subject} />
                            <Box sx={{ width: '100%' }}>
                                <CustomChip label={Blog.chapter} />
                                <CustomChip label={Blog.title} />
                            </Box>
                            <Box sx={{ width: '100%' }}>
                                {Blog.tags.map((value, index) => (
                                    <CustomChip key={index} label={value} variant="outlined" />
                                ))}
                            </Box>
                            {Array.isArray(Blog.topic) ? (
                                Blog.topic.map((value, index) => (
                                    <CustomChip key={index} label={value} />
                                ))
                            ) : (
                                Blog.topic && <CustomChip label={Blog.topic} />
                            )}
                            <CustomChip label={Blog.status} />
                        </Box>
                    </Box>
                </Box>
            </TableCell>
            <TableCell>
                <IconButton onClick={handleVerifyBlog} size="small">
                    <CheckCircleIcon color='green' />
                </IconButton>
            </TableCell>
            <TableCell>
                <IconButton color="error" onClick={() => setShowDeleteModal(true)} size="small">
                    <DeleteIcon />
                </IconButton>
            </TableCell>
            <TableCell>
                <IconButton color="primary" onClick={handleUpdateBlog} size="small">
                    <EditIcon />
                </IconButton>
            </TableCell>
            <TableCell>
                <IconButton color="info" onClick={handleViewBlog} size="small">
                    <VisibilityIcon />
                </IconButton>
            </TableCell>
        </TableRow>
        {showDeleteModal && (
            <DeletePopUp
                showModal={() => setShowDeleteModal(true)}
                handleCloseModal={handleDeleteBlog}
                setShowDeleteModal={setShowDeleteModal}
            />
        )}
        {viewBlog && (
            <ViewBlogPopUp
                handleViewBlog={() => viewBlog}
                handleCloseViewBlog={() => setViewBlog(false)}
                setShowDeleteModal={setShowDeleteModal}
                setViewBlog={setViewBlog}
                index={Blog._id}
                isTest={false}
            />
        )}
        {BlogImageModal && (
            <BlogImageModal
                setBlogImageModal={setBlogImageModal}
                blogImageModal={BlogImageModal}
                Blog={Blog}
            />
        )}
        </>
    );
};

export default SingleBlog;
