import { create } from 'zustand';

export const programStore = create((set) => ({
    programs: [{
        count: 0,
        programName: '',
        programTitle: '',
        stream: '',
        exam: '',
        programDescription: '',
        testsOrderSequenceAscending: true,
        tests: [{
            _id: '',
            testName: '',
            testFormat: '',
            tags: [],
            levelRating: 0,
            stream: '',
            subjects: [],
            status: '',
            verified: false,
            orderSequence: 0,
            instructions: [],
            exam: '',
            testTitle: '',
            resumeEnabled: true,
            sectionPartition: false,
            maxMarks: 0,
        }],
    }],

    addProgram: (program) => set((state) => ({ programs: [...state.programs, program] })),
    updateProgram: (index, program) => set((state) => ({ programs: state.programs.map((p, i) => i === index ? program : p) })),
    removeProgram: (index) => set((state) => ({ programs: state.programs.filter((p, i) => i !== index) })),
    addTest: (programIndex, test) => set((state) => ({ programs: state.programs.map((p, i) => i === programIndex ? { ...p, tests: [...p.tests, test] } : p) })),
    updateTest: (programIndex, testIndex, test) => set((state) => ({ programs: state.programs.map((p, i) => i === programIndex ? { ...p, tests: p.tests.map((t, j) => j === testIndex ? test : t) } : p) })),
    removeTest: (programIndex, testIndex) => set((state) => ({ programs: state.programs.map((p, i) => i === programIndex ? { ...p, tests: p.tests.filter((t, j) => j !== testIndex) } : p) })),
    setPrograms: (programs) => set({ programs }),
}));