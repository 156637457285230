import React, { useState } from 'react';
import { Box, Typography, CircularProgress, IconButton, Paper, Grid, Dialog, DialogContent, DialogTitle, Button, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Copy, Eye, Upload } from 'lucide-react';
import axios from 'axios';
import Zoom from '@mui/material/Zoom';

const ImageUploader = (props) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [imageUrls, setImageUrls] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogImageUrl, setDialogImageUrl] = useState('');
  const [dragOver, setDragOver] = useState(false);

  const handleFileChange = (event) => {
    setSelectedFiles(prevFiles => [...prevFiles, ...event.target.files]);
  };

  const handleRemoveFile = (index) => {
    setSelectedFiles(prevFiles => {
      const newFiles = Array.from(prevFiles);
      newFiles.splice(index, 1);
      return newFiles;
    });
  };

  const handleUpload = async () => {
    if (selectedFiles.length === 0) return;

    setUploading(true);

    for (const file of selectedFiles) {
      const formData = new FormData();
      formData.append('image', file);

      try {
        const response = await axios.post('https://api.testbuddy.live/v1/admin/image/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
          },
        });
        setImageUrls(prevUrls => [...prevUrls, response.data.imageUrl]);
      } catch (error) {
        console.error('Error uploading image:', error);
      }
    }
    setUploading(false);
    setSelectedFiles([]);
  };

  const handleCopy = (url) => {
    navigator.clipboard.writeText(url);
  };

  const handleOpenDialog = (url) => {
    setDialogImageUrl(url);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setDialogImageUrl('');
  };

  const handlePaste = (event) => {
    const items = event.clipboardData.items;
    for (const item of items) {
      if (item.kind === 'file' && item.type.startsWith('image/')) {
        const file = item.getAsFile();
        setSelectedFiles(prevFiles => [...prevFiles, file]);
      }
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setDragOver(true);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    setDragOver(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setDragOver(false);
    const files = Array.from(event.dataTransfer.files);
    setSelectedFiles(prevFiles => [...prevFiles, ...files]);
  };

  return (
    <Box {...props} sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 2 }}>

      {/* dropzone */}
      <Tooltip title="Click anywhere to paste images" arrow TransitionComponent={Zoom} followCursor>
        <Box
          onPaste={handlePaste}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          sx={{
            width: '100%',
            height: '150px',
            border: dragOver ? '2px dashed #000' : '2px dashed #ccc',
            borderRadius: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            backgroundColor: dragOver ? 'rgba(0,0,0,0.1)' : 'transparent',
            transition: 'all 0.3s ease',
          }}
        >
          <input
            type="file"
            hidden
            multiple
            onChange={handleFileChange}
            id="file-input"
          />
          <label htmlFor="file-input" style={{ cursor: 'pointer', textAlign: 'center' }}>
            <Upload size={30} color="#666" />
            <Typography variant="h6" sx={{ mt: 2, color: '#666' }}>
              Drop images/ Click here to upload
            </Typography>
          </label>
        </Box>
      </Tooltip>

      {/* selected files */}
      {selectedFiles.length > 0 && (
        <Box sx={{ width: "100%", height: 'auto', flexWrap: 'wrap', display: 'flex', placeContent: 'center', gap: 1 }}>
          {Array.from(selectedFiles).map((file, index) => (
            <Box key={index} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', p: 0.5, backgroundColor: '#f5f5f5', borderRadius: 1, maxWidth: 100 }}>
              <Typography variant="body2" sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: 50 }}>
                {file.name}
              </Typography>
              <IconButton size="small" onClick={() => handleRemoveFile(index)} sx={{ color: 'black' }}>
                <CloseIcon />
              </IconButton>
            </Box>
          ))}
        </Box>
      )}

      {/* upload button */}
      {selectedFiles.length > 0 && (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            onClick={handleUpload}
            disabled={selectedFiles.length === 0 || uploading}
            sx={{ backgroundColor: 'primary.main', color: 'white', '&:hover': { backgroundColor: 'primary.dark' } }}
          >
            {uploading ? <CircularProgress size={24} color="inherit" /> : <><Upload /> Upload</>}
          </Button>
        </Box>
      )}

      {/* uploaded images */}
      {imageUrls.length > 0 && (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Typography variant="h6">Uploaded Images:</Typography>
          <Grid container spacing={1}>
            {imageUrls.map((url, index) => (
              <Grid item xs={6} sm={2.5} key={index}>
                <Paper sx={{ p: 1, borderRadius: 1, position: 'relative' }}>
                  <img src={url} alt={`Image ${index + 1}`} style={{ width: '100%', height: 'auto', borderRadius: '4px', cursor: 'pointer' }} onClick={() => handleOpenDialog(url)} />
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <IconButton onClick={() => handleCopy(url)} size="small">
                      <Copy />
                    </IconButton>
                    <IconButton onClick={() => handleOpenDialog(url)} size="small">
                      <Eye />
                    </IconButton>
                  </Box>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}

      {/* image view */}
      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="md" fullWidth>
        <DialogTitle>View Image</DialogTitle>
        <DialogContent>
          <img src={dialogImageUrl} alt="Dialog Image" style={{ width: '100%', height: 'auto' }} />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default ImageUploader;