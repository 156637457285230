import React from 'react'
import ViewQues from './ViewQues';
import { ToastContainer } from 'react-toastify';
import ViewQuestion from 'components/Admin/AdminComponents/ViewQuestions/ViewQuestion.js';
import { useParams } from 'react-router-dom';


import programData from 'assets/data/Test/ProgramData.json';
import languageData from 'assets/data/LanguageData.json';
import testFormatData from 'assets/data/Test/TestFormatData.json';
import SubjectData from 'assets/data/SubjectData.json';
import LevelRating from 'assets/data/LevelData.json';
import TagsData from 'assets/data/Test/TagsData.json';

import {
    Button,
    Box,
    Paper,
    Divider,
    FormControl,
    Accordion,
    AccordionSummary,
    Typography,
    AccordionDetails,
    Tabs,
    Tab,
    CircularProgress,
} from '@mui/material';
import { CustomInputLabel, CustomMenu, CustomOutlinedInput, CustomSelect } from '../Common/CustomComponents';
import { createtestStore } from 'components/store/createtestStore';
import { ChevronDown, X } from 'lucide-react';
import Editor2 from 'components/Editor2/Editor2';
import { renderMixedContent } from 'utils/renderMathExp';
import axios from 'axios';

const tabs = ['instructions', 'syllabus'];

export default function CreateTest() {
    const { testId } = useParams();

    const {
        _id,
        programName,
        orderSequence,
        instructions,
        exam,
        syllabus,
        testTitle,
        testAbr,
        testName,
        testDescription,
        testFormat,
        levelRating,
        testQuestions,
        tags,
        attemptTime,
        maxMarks,
        language,
        subjects,
        stream,
        status,
        verified,
        sectionPartition,
        resumeEnabled,

        setProgramName,
        setOrderSequence,
        setExam,
        setTestTitle,
        setTestAbr,
        setTestName,
        setTestDescription,
        setTestFormat,
        setLevelRating,
        setTestQuestions,
        setTags,
        setAttemptTime,
        setMaxMarks,
        setLanguage,
        setSubjects,
        setStream,
        setStatus,
        setVerified,
        setSectionPartition,
        setResumeEnabled,

        addSomeValues,
        resetValues,
        setTest
    } = createtestStore(state => state);

    const [viewQuestion, setViewQuestion] = React.useState(false);
    const [addQuestions, setAddQuestions] = React.useState(false);
    const [tabValue, setTabValue] = React.useState(0);

    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        resetValues();
        if (testId) {
            setLoading(true);
            console.log('testId:', testId);
            fetchTestDetails();
        }
    }, [testId]);

    const fetchTestDetails = async () => {
        try {
            const response = await axios.post(`https://api.testbuddy.live/v1/admin/test/detail`, { testId: testId }, {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem("token")
                    )}`,
                },
            })
                .then(response => {
                    // console.log("data: ", (response.data));
                    resetValues();
                    setTest(response.data);
                })
                .catch(error => {
                    // handle error
                    console.log("error:", error);
                });
        }
        catch (error) {
            console.error(error);
        }
        finally {
            setLoading(false);
        }
    }

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };


    const handleViewQuestion = () => {
        setAddQuestions(false);
        setViewQuestion(!viewQuestion);
    }

    const handleAddQuestions = () => {
        setViewQuestion(false);
        setAddQuestions(!addQuestions);
    }

    const handleSubjectsChange = (event) => {
        const { value } = event.target;
        value && setSubjects(typeof value === 'string' ? value.split(',') : value);
    }

    const handleTagChange = (event) => {
        const { value } = event.target;
        value && setTags(typeof value === 'string' ? value.split(',') : value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        //TODO: validate the data
        const errors = {};
        if (!_id) errors._id = 'Please enter the test id';
        if (!programName) errors.programName = 'Please enter the program name';
        if (!orderSequence) errors.orderSequence = 'Please enter the order sequence';
        if (!instructions) errors.instructions = 'Please enter the instructions';
        if (!exam) errors.exam = 'Please select an exam';
        if (!syllabus) errors.syllabus = 'Please select a syllabus';
        if (!testTitle) errors.testTitle = 'Please enter the test title';
        if (!testAbr) errors.testAbr = 'Please enter the test abbreviation';
        if (!testName) errors.testName = 'Please enter the test name';
        if (!testDescription) errors.testDescription = 'Please enter the test description';
        if (!testFormat) errors.testFormat = 'Please select a test format';
        if (!levelRating) errors.levelRating = 'Please select a level rating';
        if (!setTestQuestions || setTestQuestions.length === 0) errors.setTestQuestions = 'Please add at least one question';
        if (!tags) errors.tags = 'Please enter the tags';
        if (!attemptTime) errors.attemptTime = 'Please enter the attempt time';
        if (!maxMarks) errors.maxMarks = 'Please enter the maximum marks';
        if (!language) errors.language = 'Please select a language';
        if (!subjects || subjects.length === 0) errors.subjects = 'Please select at least one subject';
        if (!stream) errors.stream = 'Please select a stream';
        if (!status) errors.status = 'Please select a status';
        if (!String(verified)) errors.verified = 'Please select a verified option';
        if (!String(sectionPartition)) errors.sectionPartition = 'Please select a section partition';
        if (!resumeEnabled) errors.resumeEnabled = 'Please select a resume enabled option';

        if (Object.keys(errors).length > 0) {
            alert(Object.values(errors).join('\n'));
            return;
        }

        const jsondata = {
            testId:_id,
            programName,
            orderSequence,
            instructions,
            exam,
            syllabus,
            testTitle,
            testAbr,
            testName,
            testDescription,
            testFormat,
            levelRating,
            questions: testQuestions,
            tags,
            attemptTime,
            maxMarks,
            language,
            subjects,
            stream,
            status,
            verified,
            sectionPartition,
            resumeEnabled,
            course: "123456789012345678901234",
        };

        console.log(jsondata);

        submitTest(JSON.stringify(jsondata));

    }

    const submitTest = async (data) => {
        console.log(data);

        try {

            const url = testId ? 'https://api.testbuddy.live/v1/admin/test/update' : 'https://api.testbuddy.live/v1/admin/test/add';
            console.log(url)

            const response = await axios.post(url, data, {
                headers: {
                    "content-type": "application/json",
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem("token")
                    )}`,
                },
            })
                .then(response => {
                    // handle response
                    console.log("response: ", response);

                    // clear the form
                    resetValues();
                })
                .catch(error => {
                    // handle error
                    console.log("error:", error);
                });
            console.log(response);
        }
        catch (error) {
            console.error(error);
        }
    }

    return (
        loading ?
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}><CircularProgress /></Box>
            :
            <Box sx={{ bgcolor: 'background.paper', display: 'flex', flexDirection: 'column', flexGrow: 1, p: 2, gap: 2 }}>
                <Paper
                    elevation={0}
                    sx={{
                        p: 2,
                        border: '1px solid #C4C4C4',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        flexGrow: 1,
                    }}
                >
                    <Accordion elevation={0} sx={{ width: '100%', border: '1px solid #ccc', borderRadius: 2, }}>
                        <AccordionSummary
                            expandIcon={<ChevronDown />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography variant="body1">Test Details</Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ display: 'flex', flexDirection: 'column', gap: 2, flexWrap: 'wrap', }}>

                            {_id && <FormControl sx={{ minWidth: 120 }}>
                                <CustomInputLabel id="test-id-label">Test Id</CustomInputLabel>
                                <CustomOutlinedInput
                                    id="test-id-label"
                                    value={_id}
                                    disabled
                                />
                            </FormControl>}

                            <Divider sx={{ fontSize: '0.9rem' }}>Program related</Divider>
                            <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }} >

                                <FormControl sx={{ minWidth: 120 }}>
                                    <CustomInputLabel id="program-name-label">Program Name</CustomInputLabel>
                                    <CustomSelect
                                        labelId="program-name-label"
                                        value={programName}
                                        label="Program Name"
                                        onChange={(e) => setProgramName(e.target.value)}
                                    >
                                        {programData.programsArray.map((program, index) => (
                                            <CustomMenu key={index} value={program}>{program}</CustomMenu>
                                        ))}
                                    </CustomSelect>
                                </FormControl>

                                <FormControl sx={{ minWidth: 120 }}>
                                    <CustomInputLabel id="order-sequence-label">Order Sequence</CustomInputLabel>
                                    <CustomOutlinedInput
                                        id="order-sequence-label"
                                        value={orderSequence}
                                        onChange={(e) => setOrderSequence(e.target.value)}
                                    />
                                </FormControl>

                            </Box>


                            <Divider sx={{ fontSize: '0.9rem' }}>Test Informations</Divider>
                            <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }} >

                                <FormControl sx={{ minWidth: 120 }}>
                                    <CustomInputLabel id="exam-label">Exam</CustomInputLabel>
                                    <CustomOutlinedInput
                                        id="exam-label"
                                        value={exam}
                                        onChange={(e) => setExam(e.target.value)}
                                    />
                                </FormControl>

                                <FormControl sx={{ minWidth: 120 }}>
                                    <CustomInputLabel id="test-title-label">Test Title</CustomInputLabel>
                                    <CustomOutlinedInput
                                        id="test-title-label"
                                        value={testTitle}
                                        onChange={(e) => setTestTitle(e.target.value)}
                                    />
                                </FormControl>

                                <FormControl sx={{ minWidth: 120 }}>
                                    <CustomInputLabel id="test-abr-label">Test Abbreviation</CustomInputLabel>
                                    <CustomOutlinedInput
                                        id="test-abr-label"
                                        value={testAbr}
                                        onChange={(e) => setTestAbr(e.target.value)}
                                    />
                                </FormControl>

                                <FormControl sx={{ minWidth: 120 }}>
                                    <CustomInputLabel id="test-name-label">Test Name</CustomInputLabel>
                                    <CustomOutlinedInput
                                        id="test-name-label"
                                        value={testName}
                                        onChange={(e) => setTestName(e.target.value)}
                                    />
                                </FormControl>

                                <FormControl sx={{ minWidth: 120 }}>
                                    <CustomInputLabel id="test-description-label">Test Description</CustomInputLabel>
                                    <CustomOutlinedInput
                                        id="test-description-label"
                                        value={testDescription}
                                        onChange={(e) => setTestDescription(e.target.value)}
                                    />
                                </FormControl>

                                <FormControl sx={{ minWidth: 120 }}>
                                    <CustomInputLabel id="stream-label">Stream</CustomInputLabel>
                                    <CustomSelect
                                        labelId="stream-label"
                                        value={stream}
                                        label="Stream"
                                        onChange={(e) => setStream(e.target.value)}
                                    >
                                        <CustomMenu value="engineering">Engineering</CustomMenu>
                                        <CustomMenu value="medical">Medical</CustomMenu>
                                    </CustomSelect>
                                </FormControl>
                            </Box>

                            <Divider sx={{ fontSize: '0.9rem' }}>Additional Informations based on questions</Divider>
                            <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }} >

                                <FormControl sx={{ minWidth: 120 }}>
                                    <CustomInputLabel id="test-format-label">Test Format</CustomInputLabel>
                                    <CustomSelect
                                        labelId="test-format-label"
                                        value={testFormat}
                                        label="Test Format"
                                        onChange={(e) => setTestFormat(e.target.value)}
                                    >
                                        {
                                            testFormatData.tests.map((format, index) => (
                                                <CustomMenu key={index} value={format}>{format}</CustomMenu>
                                            ))
                                        }
                                    </CustomSelect>
                                </FormControl>



                                <FormControl sx={{ minWidth: 120 }}>
                                    <CustomInputLabel id="language-label">Language</CustomInputLabel>
                                    <CustomSelect
                                        labelId="language-label"
                                        value={language}
                                        label="Language"
                                        onChange={(e) => setLanguage(e.target.value)}
                                    >
                                        {
                                            languageData.languages.map((lang, index) => (
                                                <CustomMenu key={index} value={lang.value}>{lang.name}</CustomMenu>
                                            ))
                                        }
                                    </CustomSelect>
                                </FormControl>

                                <FormControl sx={{ minWidth: 120 }}>
                                    <CustomInputLabel id="attempt-time-label">Attempt Time</CustomInputLabel>
                                    <CustomOutlinedInput
                                        id="attempt-time-label"
                                        value={attemptTime}
                                        onChange={(e) => setAttemptTime(e.target.value)}
                                    />
                                </FormControl>

                                <FormControl sx={{ minWidth: 120 }}>
                                    <CustomInputLabel id="max-marks-label">Max Marks</CustomInputLabel>
                                    <CustomOutlinedInput
                                        id="max-marks-label"
                                        value={maxMarks}
                                        onChange={(e) => setMaxMarks(e.target.value)}
                                    />
                                </FormControl>

                                <FormControl sx={{ minWidth: 120 }}>
                                    <CustomInputLabel id="level-rating-label">Level Rating</CustomInputLabel>
                                    <CustomSelect
                                        labelId="level-rating-label"
                                        value={levelRating}
                                        label="Level Rating"
                                        onChange={(e) => setLevelRating(e.target.value)}
                                    >
                                        {
                                            LevelRating.levels.map((level, index) => (
                                                <CustomMenu key={index} value={level}>{level}</CustomMenu>
                                            ))

                                        }
                                    </CustomSelect>
                                </FormControl>

                                <FormControl sx={{ minWidth: 120 }}>
                                    <CustomInputLabel id="tags-label">Tags</CustomInputLabel>
                                    <CustomSelect
                                        labelId="tags-label"
                                        multiple
                                        value={tags}
                                        onChange={handleTagChange}
                                        label="Tags"
                                        renderValue={(selected) => selected.join(', ')}
                                    >
                                        {
                                            TagsData.tags.map((tag, index) => (
                                                <CustomMenu key={index} value={tag.value}>{tag.name}</CustomMenu>
                                            ))
                                        }
                                    </CustomSelect>
                                </FormControl>

                                <FormControl sx={{ minWidth: 120 }}>
                                    <CustomInputLabel id="subjects-label">Subjects</CustomInputLabel>
                                    <CustomSelect
                                        labelId="subjects-label"
                                        multiple
                                        value={subjects}
                                        onChange={handleSubjectsChange}
                                        label="Subjects"
                                        renderValue={(selected) => selected.join(', ')}
                                    >
                                        {
                                            SubjectData.subjects.map((subject, index) => (
                                                <CustomMenu key={index} value={subject.value}>{subject.name}</CustomMenu>
                                            ))
                                        }
                                    </CustomSelect>
                                </FormControl>
                            </Box>

                            <Divider sx={{ fontSize: '0.9rem' }}>Admin Informations</Divider>
                            <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }} >

                                <FormControl sx={{ minWidth: 120 }}>
                                    <CustomInputLabel id="status-label">Status</CustomInputLabel>
                                    <CustomSelect
                                        labelId="status-label"
                                        value={status}
                                        label="Status"
                                        onChange={(e) => setStatus(e.target.value)}
                                    >
                                        <CustomMenu value="active">Active</CustomMenu>
                                        <CustomMenu value="inactive">Inactive</CustomMenu>
                                    </CustomSelect>
                                </FormControl>

                                <FormControl sx={{ minWidth: 120 }}>
                                    <CustomInputLabel id="verified-label">Verified</CustomInputLabel>
                                    <CustomSelect
                                        labelId="verified-label"
                                        value={verified}
                                        label="Verified"
                                        onChange={(e) => setVerified(e.target.value)}
                                    >
                                        <CustomMenu value={true}>Verified</CustomMenu>
                                        <CustomMenu value={false}>Not Verified</CustomMenu>
                                    </CustomSelect>
                                </FormControl>

                                <FormControl sx={{ minWidth: 120 }}>
                                    <CustomInputLabel id="section-partition-label">Section Partition</CustomInputLabel>
                                    <CustomSelect
                                        labelId="section-partition-label"
                                        value={sectionPartition}
                                        label="Section Partition"
                                        onChange={(e) => setSectionPartition(e.target.value)}
                                    >
                                        <CustomMenu value={true}>Yes</CustomMenu>
                                        <CustomMenu value={false}>No</CustomMenu>
                                    </CustomSelect>
                                </FormControl>

                                <FormControl sx={{ minWidth: 120 }}>
                                    <CustomInputLabel id="resume-enabled-label">Resume Enabled</CustomInputLabel>
                                    <CustomSelect
                                        labelId="resume-enabled-label"
                                        value={resumeEnabled}
                                        label="Resume Enabled"
                                        onChange={(e) => setResumeEnabled(e.target.value)}
                                    >
                                        <CustomMenu value={true}>Yes</CustomMenu>
                                        <CustomMenu value={false}>No</CustomMenu>
                                    </CustomSelect>
                                </FormControl>

                            </Box>

                        </AccordionDetails>
                    </Accordion>


                    <Paper elevation={0} sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, }}>
                        <Tabs
                            value={tabValue}
                            onChange={handleTabChange}
                            indicatorColor="primary"
                            textColor="primary"
                        >
                            {tabs.map((tab, index) => (
                                <Tab key={index} label={tab} />
                            ))}
                        </Tabs>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: 2,
                            width: '100%',
                        }}>
                            <Box sx={{
                                flex: 1, overflow: 'auto', display: 'flex', flexDirection: 'column', gap: 2, border: '1px solid #ccc', borderRadius: 2, p: 4,
                                // Hide scrollbar for Chrome, Safari, and Opera
                                '&::-webkit-scrollbar': {
                                    display: 'none',
                                },
                                // Hide scrollbar for IE, Edge, and Firefox
                                '-ms-overflow-style': 'none', // IE and Edge
                                'scrollbar-width': 'none', // Firefox
                            }}>
                                {
                                    tabs[tabValue] === 'instructions' &&
                                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                                        <Typography variant="h6">Instructions</Typography>
                                        <div style={{ whiteSpace: 'normal' }}>
                                            <Typography variant="body1">
                                                {renderMixedContent(instructions)}
                                            </Typography>
                                        </div>
                                    </Box>}
                                {
                                    tabs[tabValue] === 'syllabus' &&
                                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                                        <Typography variant="h6">Syllabus</Typography>
                                        <div style={{ whiteSpace: 'normal' }}>
                                            <Typography variant="body1">
                                                {renderMixedContent(syllabus)}
                                            </Typography>
                                        </div>
                                    </Box>}

                            </Box>
                            <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', gap: 2 }}>
                                <Editor2 tab={tabs[tabValue]} storename={'createtest'} />
                            </Box>
                        </Box>
                    </Paper>

                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexWrap: 'wrap',
                        columnGap: 4,
                        rowGap: 2
                    }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleViewQuestion}
                            sx={{ width: 180 }}
                        >
                            {viewQuestion ? <X /> : 'View Questions'}
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleAddQuestions}
                            sx={{ width: 180 }}

                        >
                            {addQuestions ? <X /> : 'Add Questions'}
                        </Button>
                        {<Button
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                            sx={{ width: 180 }}

                        >
                            Submit
                        </Button>
                        }
                    </Box>
                    {viewQuestion && <ViewQues />}
                    {addQuestions && <ViewQuestion isTest={true} setAddQuestions={setAddQuestions} />}

                    <ToastContainer />
                </Paper>
            </Box>
    )
}