import React, { useState, useEffect } from 'react';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Paper, Typography, Switch, FormControlLabel, TextField, Box } from '@mui/material';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { cleanupParagraphTags } from './utils/helpers';
import {  useParams } from 'react-router-dom';
import { getContent, getSetContent } from './utils/storeUtils';

const Editor2 = ({ tab, storename }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [isHtmlMode, setIsHtmlMode] = useState(false);

  const content = getContent(storename, tab);
  const setContent = getSetContent(storename, tab);

  const { quesId } = useParams();

  useEffect(() => {
    if (content) {
      const contentBlock = htmlToDraft("<p/>" + content + "<p/>");
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        setEditorState(EditorState.createWithContent(contentState));
      }
    } else {
      setEditorState(EditorState.createEmpty());
    }
  }, [tab, quesId]);

  useEffect(() => {
    const newHtmlCode = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    const cleanedHtmlCode = cleanupParagraphTags(newHtmlCode);
    if (cleanedHtmlCode !== '') {
      setContent(cleanedHtmlCode);
    }
  }, [editorState]);


  const onEditorStateChange = (newEditorState) => {
    setEditorState(newEditorState);
  };

  return (
    <Paper elevation={0} sx={{ width: '100%', padding: 2, border: '1px solid #ccc' }}>
      <FormControlLabel
        control={
          <Switch
            checked={isHtmlMode}
            onChange={(event) => setIsHtmlMode(event.target.checked)}
            color="primary"
          />
        }
        label="HTML code"
      />
      {isHtmlMode ? (
        <TextField
          readOnly
          multiline
          fullWidth
          value={content}
          variant="outlined"
        />
      ) : (
        <Box>
          <Typography variant="h6" gutterBottom>
            Rich Text Editor
          </Typography>
          <Editor
            editorState={editorState}
            onEditorStateChange={onEditorStateChange}
            toolbar={{
              options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'link', 'embedded', 'image', 'history'],
              inline: { inDropdown: false, options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace', 'superscript', 'subscript'] },
              blockType: { inDropdown: true, options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code'] },
              fontSize: { options: [10, 12, 14, 16, 18, 24, 30, 36, 48] },
              list: { inDropdown: false, options: ['unordered', 'ordered', 'indent', 'outdent'] },
              textAlign: { inDropdown: false },
              link: { inDropdown: false },
              image: {
                uploadCallback: async (file) => {
                  return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onload = (e) => resolve({ data: { link: e.target.result } });
                    reader.onerror = reject;
                    reader.readAsDataURL(file);
                  });
                },
                previewImage: true,
                inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                alt: { present: true, mandatory: false },
                defaultSize: {
                  height: 'full',
                  width: 'full',
                },
              },
              history: { inDropdown: false },
            }}
          />
        </Box>
      )}
    </Paper>
  );
};

export default Editor2;