import React, { useEffect } from 'react';
import {
  Button,
  Box,
  FormControl,
  Paper,
} from '@mui/material';
import { CustomInputLabel, CustomMenu, CustomOutlinedInput, CustomSelect } from '../../Common/CustomComponents';

import { viewtestFilterStore } from 'components/store/ViewtestFilterStore';


import programData from 'assets/data/Test/ProgramData.json';
import testFormatData from 'assets/data/Test/TestFormatData.json';
import SubjectData from 'assets/data/SubjectData.json';
import LevelRating from 'assets/data/LevelData.json';
import TagsData from 'assets/data/Test/TagsData.json';
import ExamData from 'assets/data/ExamData.json';


export default function Filters({ applyFilters}) {

  const {
    testName,
    testFormat,
    tags,
    levelRating,
    stream,
    subjects,
    status,
    verified,
    programName,
    orderSequence,
    instructions,
    exam,
    testTitle,
    resumeEnabled,
    sectionPartition,
    maxMarks,
    setTestName,
    setTestFormat,
    setTags,
    setLevelRating,
    setStream,
    setSubjects,
    setStatus,
    setVerified,
    setProgramName,
    setOrderSequence,
    setInstructions,
    setExam,
    setTestTitle,
    setResumeEnabled,
    setSectionPartition,
    setMaxMarks,
    resetFilters,
    addSomeValues
  } = viewtestFilterStore(state => state);


  useEffect(() => {
    // addSomeValues();
  }, []);

  const handleTagChange = (event) => {
    const { value } = event.target;
    setTags(typeof value === 'string' ? value.split(',') : value);
  };

  const handleSubjectsChange = (event) => {
    const { value } = event.target;
    setSubjects(typeof value === 'string' ? value.split(',') : value);
  }

  return (
    <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Paper elevation={0} sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 2 }}>

        <FormControl sx={{ minWidth: 120 }}>
          <CustomInputLabel id="test-name-label">Test Name</CustomInputLabel>
          <CustomOutlinedInput
            id="test-name-label"
            value={testName}
            onChange={(e) => setTestName(e.target.value)}
          />
        </FormControl>

        <FormControl sx={{ minWidth: 120 }}>
          <CustomInputLabel id="test-format-label">Test Format</CustomInputLabel>
          <CustomSelect
            labelId="test-format-label"
            value={testFormat}
            label="Test Format"
            onChange={(e) => setTestFormat(e.target.value)}
          >
            {
              testFormatData.tests.map((format, index) => (
                <CustomMenu key={index} value={format}>{format}</CustomMenu>
              ))
            }
          </CustomSelect>
        </FormControl>

        <FormControl sx={{ minWidth: 120 }}>
          <CustomInputLabel id="tags-label">Tags</CustomInputLabel>
          <CustomSelect
            labelId="tags-label"
            multiple
            value={tags}
            onChange={handleTagChange}
            label="Tags"
            renderValue={(selected) => selected.join(', ')}
          >
            {
              TagsData.tags.map((tag, index) => (
                <CustomMenu key={index} value={tag.value}>{tag.name}</CustomMenu>
              ))
            }
          </CustomSelect>
        </FormControl>

        <FormControl sx={{ minWidth: 120 }}>
          <CustomInputLabel id="level-rating-label">Level Rating</CustomInputLabel>
          <CustomSelect
            labelId="level-rating-label"
            value={levelRating}
            label="Level Rating"
            onChange={(e) => setLevelRating(e.target.value)}
          >
            {
              LevelRating.levels.map((level, index) => (
                <CustomMenu key={index} value={level}>{level}</CustomMenu>
              ))
            }
          </CustomSelect>
        </FormControl>

        <FormControl sx={{ minWidth: 120 }}>
          <CustomInputLabel id="stream-label">Stream</CustomInputLabel>
          <CustomSelect
            labelId="stream-label"
            value={stream}
            label="Stream"
            onChange={(e) => setStream(e.target.value)}
          >
            <CustomMenu value="engineering">Engineering</CustomMenu>
            <CustomMenu value="medical">Medical</CustomMenu>
          </CustomSelect>
        </FormControl>

        <FormControl sx={{ minWidth: 120 }}>
          <CustomInputLabel id="subjects-label">Subjects</CustomInputLabel>
          <CustomSelect
            labelId="subjects-label"
            multiple
            value={subjects}
            onChange={handleSubjectsChange}
            label="Subjects"
            renderValue={(selected) => selected.join(', ')}
          >
            {
              SubjectData.subjects.map((subject, index) => (
                <CustomMenu key={index} value={subject.value}>{subject.name}</CustomMenu>
              ))
            }
          </CustomSelect>
        </FormControl>

        <FormControl sx={{ minWidth: 120 }}>
          <CustomInputLabel id="status-label">Status</CustomInputLabel>
          <CustomSelect
            labelId="status-label"
            value={status}
            label="Status"
            onChange={(e) => setStatus(e.target.value)}
          >
            <CustomMenu value="active">Active</CustomMenu>
            <CustomMenu value="inactive">Inactive</CustomMenu>
          </CustomSelect>
        </FormControl>

        <FormControl sx={{ minWidth: 120 }}>
          <CustomInputLabel id="verified-label">Verified</CustomInputLabel>
          <CustomSelect
            labelId="verified-label"
            value={verified}
            label="Verified"
            onChange={(e) => setVerified(e.target.value)}
          >
            <CustomMenu value={true}>True</CustomMenu>
            <CustomMenu value={false}>False</CustomMenu>
          </CustomSelect>
        </FormControl>

        <FormControl sx={{ minWidth: 120 }}>
          <CustomInputLabel id="program-name-label">Program Name</CustomInputLabel>
          <CustomOutlinedInput
            id="program-name-label"
            value={programName}
            onChange={(e) => setProgramName(e.target.value)}
          />
        </FormControl>

        <FormControl sx={{ minWidth: 120 }}>
          <CustomInputLabel id="order-sequence-label">Order Sequence</CustomInputLabel>
          <CustomOutlinedInput
            id="order-sequence-label"
            value={orderSequence}
            onChange={(e) => setOrderSequence(e.target.value)}
          />
        </FormControl>

        <FormControl sx={{ minWidth: 120 }}>
          <CustomInputLabel id="instructions-label">Instructions</CustomInputLabel>
          <CustomSelect
            labelId="instructions-label"
            value={instructions}
            label="Instructions"
            onChange={(e) => setInstructions(e.target.value)}
          >
            <CustomMenu value={true}>Present</CustomMenu>
            <CustomMenu value={false}>Absent</CustomMenu>
          </CustomSelect>
        </FormControl>

        <FormControl sx={{ minWidth: 120 }}>
          <CustomInputLabel id="exam-label">Exam</CustomInputLabel>
          <CustomSelect
            labelId="exam-label"
            value={exam}
            label="Exam"
            onChange={(e) => setExam(e.target.value)}
          >
            {
              ExamData.exams.map((exam, index) => (
                <CustomMenu key={index} value={exam.value}>{exam.name}</CustomMenu>
              ))
            }
          </CustomSelect>
        </FormControl>

        <FormControl sx={{ minWidth: 120 }}>
          <CustomInputLabel id="test-title-label">Test Title</CustomInputLabel>
          <CustomOutlinedInput
            id="test-title-label"
            value={testTitle}
            onChange={(e) => setTestTitle(e.target.value)}
          />
        </FormControl>

        <FormControl sx={{ minWidth: 120 }}>
          <CustomInputLabel id="resume-enabled-label">Resume Enabled</CustomInputLabel>
          <CustomSelect
            labelId="resume-enabled-label"
            value={resumeEnabled}
            label="Resume Enabled"
            onChange={(e) => setResumeEnabled(e.target.value)}
          >
            <CustomMenu value={true}>Yes</CustomMenu>
            <CustomMenu value={false}>No</CustomMenu>
          </CustomSelect>
        </FormControl>

        <FormControl sx={{ minWidth: 120 }}>
          <CustomInputLabel id="section-partition-label">Section Partition</CustomInputLabel>
          <CustomSelect
            labelId="section-partition-label"
            value={sectionPartition}
            label="Section Partition"
            onChange={(e) => setSectionPartition(e.target.value)}
          >
            <CustomMenu value={true}>Yes</CustomMenu>
            <CustomMenu value={false}>No</CustomMenu>
          </CustomSelect>
        </FormControl>

        <FormControl sx={{ minWidth: 120 }}>
          <CustomInputLabel id="max-marks-label">Max Marks</CustomInputLabel>
          <CustomOutlinedInput
            id="max-marks-label"
            value={maxMarks}
            onChange={(e) => setMaxMarks(e.target.value)}
          />
        </FormControl>

      </Paper>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, }}>
        <Button variant="contained" color="primary" onClick={() => applyFilters(
          testName,
          testFormat,
          tags,
          levelRating,
          stream,
          subjects,
          status,
          verified,
          programName,
          orderSequence,
          instructions,
          exam,
          testTitle,
          resumeEnabled,
          sectionPartition,
          maxMarks
        )}>
          Filter
        </Button>
        <Button variant="outlined" color="secondary" onClick={resetFilters}>
          Clear Filter
        </Button>
      </Box>
    </Box>
  );
}