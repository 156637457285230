import MathJax from 'react-mathjax';
import React from 'react';

const renderMathJax = (formula) => (
    <MathJax.Node inline formula={formula} />
);

const renderHTML = (html) => (
    <span dangerouslySetInnerHTML={{ __html: html }} />
);

export const renderMixedContent = (text) => {
    // split text into parts with regular expressions for $...$ and $$...$$
    const parts = text.split(/(\$\$.*?\$\$|\$.*?\$)/);

    return parts.map((part, index) => {
        if (part.startsWith('$$') && part.endsWith('$$')) {
            return <span key={index} style={{ display: 'inline' }}>{renderMathJax(part.slice(2, -2))}</span>;
        }
        else if (part.startsWith('$') && part.endsWith('$')) {
            return <span key={index} style={{ display: 'inline' }}>{renderMathJax(part.slice(1, -1))}</span>;
        }
        else {
            return <span key={index} style={{ display: 'inline' }}>{renderHTML(part)}</span>;
        }
    });
}

export const removeImgTags = (html) => {
    // Use a regular expression to remove all <img ...> tags
    return html.replace(/<img[^>]*>/g, '');
};

export const createCoverImage = (imageUrl) => {
    return `<img src="${imageUrl}" alt="Cover Image" style="width: 100%; height: 300px; object-fit: cover; border-radius: 10px;">`;
};