import { Chip } from "@mui/material";
import { memo } from "react";

const customColors = [
  '#582f0e',
  '#7f4f24',
  '#936639',
  '#a68a64',
  '#b6ad90',
  '#c2c5aa',
  '#a4ac86',
  '#656d4a',
  '#414833',
  '#333d29'
];

const CustomChip = ({ label, ...props }) => {
  const randomColor = customColors[Math.floor(Math.random() * customColors.length)];
  return (
    <Chip
      label={label}
      size="small"
      sx={{
        mr: 0.5,
        mb: 0.5,
        backgroundColor: randomColor,
        color: 'white',
      }}
      {...props}
    />
  );
};

export default memo(CustomChip);
