import React from 'react';
import { Container, Typography, Button, Box, Link } from '@mui/material';
import { Header } from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import { ArrowLeftIcon } from 'lucide-react';

function ErrorPage() {

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <Header />
      <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
        <Container maxWidth="sm">
          <Typography variant="h1" component="h1" gutterBottom>
            404
          </Typography>
          <Typography variant="h5" component="h2" gutterBottom>
            Page Not Found
          </Typography>
          <Typography variant="body1" gutterBottom>
            We apologize, but the page you are looking for does not exist or has been moved.
          </Typography>
          <Link href="/" variant="contained" color="primary" sx={{ marginTop: 3 }}>
            <ArrowLeftIcon size={15} />
            Go Back to Home
          </Link>
        </Container>
      </Box>
      <Footer />
    </Box>
  );
}

export default ErrorPage;
