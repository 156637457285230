import React from 'react';
import { Button, FormControl, Box, Paper } from '@mui/material';
import { CustomInputLabel, CustomMenu, CustomOutlinedInput, CustomSelect } from '../../Common/CustomComponents';
import { filterStore } from 'components/store/filterStore';

export default function Selectors({ fetchFilteredQuestions, isTest, handleAddQues, handleCreateTest }) {

    const {
        questionKey,
        comprehension,
        paperTitle,
        language,
        exam,
        examGroup,
        year,
        subject,
        chapter,
        chapterGroup,
        tags,
        questionType,
        levelRating,
        topic,
        status,
        section,
        verified,
        completed,
        setQuestionKey,
        setPaperTitle,
        setLanguage,
        setExam,
        setExamGroup,
        setYear,
        setSubject,
        setChapter,
        setChapterGroup,
        setTags,
        setQuestionType,
        setLevelRating,
        setTopic,
        setStatus,
        setSection,
        setVerified,
        setCompleted,
        setComprehension,
        resetFilters

    } = filterStore(state => state);

    React.useEffect(() => {
        resetFilters();
    }, []);

    const handleTagChange = (event) => {
        const { value } = event.target;
        setTags(typeof value === 'string' ? value.split(',') : value);
    };

    const years = Array.from({ length: 2024 - 2000 + 1 }, (_, i) => 2000 + i).reverse();

    return (
        <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Paper elevation={0} sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 2 }}>

                <FormControl sx={{ minWidth: 120 }}>
                    <CustomInputLabel id="question-key-label">Question Key</CustomInputLabel>
                    <CustomOutlinedInput
                        id="question-key-label"
                        value={questionKey}
                        onChange={(e) => setQuestionKey(e.target.value)}
                    />
                </FormControl>

                <FormControl sx={{ minWidth: 120 }}>
                    <CustomInputLabel id="paper-title-label">Paper Title</CustomInputLabel>
                    <CustomOutlinedInput
                        id="paper-title-label"
                        value={paperTitle}
                        onChange={(e) => setPaperTitle(e.target.value)}
                    />
                </FormControl>


                <FormControl sx={{ minWidth: 120 }}>
                    <CustomInputLabel id="language-label">Language</CustomInputLabel>
                    <CustomSelect
                        labelId="language-label"
                        value={language}
                        label="Language"
                        onChange={(e) => setLanguage(e.target.value)}
                    >
                        <CustomMenu value="english">English</CustomMenu>
                        <CustomMenu value="hindi">Hindi</CustomMenu>
                    </CustomSelect>
                </FormControl>

                <FormControl sx={{ minWidth: 120 }}>
                    <CustomInputLabel id="comprehension-label">Comprehension</CustomInputLabel>
                    <CustomSelect
                        labelId="comprehension-label"
                        value={comprehension}
                        label="Comprehension"
                        onChange={(e) => setComprehension(e.target.value)}
                    >
                        <CustomMenu value="true">Yes</CustomMenu>
                        <CustomMenu value="false">No</CustomMenu>
                    </CustomSelect>
                </FormControl>

                <FormControl sx={{ minWidth: 120 }}>
                    <CustomInputLabel id="exam-label">Exam</CustomInputLabel>
                    <CustomSelect
                        labelId="exam-label"
                        value={exam}
                        label="Exam"
                        onChange={(e) => setExam(e.target.value)}
                    >
                        <CustomMenu value="jee-main">JEE Main</CustomMenu>
                        <CustomMenu value="jee-advanced">JEE Advanced</CustomMenu>
                    </CustomSelect>
                </FormControl>

                <FormControl sx={{ minWidth: 120 }}>
                    <CustomInputLabel id="exam-group-label">Exam Group</CustomInputLabel>
                    <CustomOutlinedInput
                        id="exam-group-label"
                        value={examGroup}
                        onChange={(e) => setExamGroup(e.target.value)}
                    />
                </FormControl>


                <FormControl sx={{ minWidth: 120 }}>
                    <CustomInputLabel id="year-label">Year</CustomInputLabel>
                    <CustomSelect
                        labelId="year-label"
                        value={year}
                        label="Year"
                        onChange={(e) => setYear(e.target.value)}
                    >
                        {years.map((year) => (
                            <CustomMenu key={year} value={year}>
                                {year}
                            </CustomMenu>
                        ))}
                    </CustomSelect>
                </FormControl>

                <FormControl sx={{ minWidth: 120 }}>
                    <CustomInputLabel id="subject-label">Subject</CustomInputLabel>
                    <CustomSelect
                        labelId="subject-label"
                        value={subject}
                        label="Subject"
                        onChange={(e) => setSubject(e.target.value)}
                    >
                        <CustomMenu value="physics">Physics</CustomMenu>
                        <CustomMenu value="chemistry">Chemistry</CustomMenu>
                        <CustomMenu value="maths">Maths</CustomMenu>
                    </CustomSelect>
                </FormControl>

                <FormControl sx={{ minWidth: 120 }}>
                    <CustomInputLabel id="chapter-label">Chapter</CustomInputLabel>
                    <CustomSelect
                        labelId="chapter-label"
                        value={chapter}
                        label="Chapter"
                        onChange={(e) => setChapter(e.target.value)}
                    >
                        <CustomMenu value="p-block-elements">p-block-elements</CustomMenu>
                    </CustomSelect>
                </FormControl>

                <FormControl sx={{ minWidth: 120 }}>
                    <CustomInputLabel id="chapterGroup-label">Chapter Group</CustomInputLabel>
                    <CustomSelect
                        labelId="chapterGroup-label"
                        value={chapterGroup}
                        label="Chapter Group"
                        onChange={(e) => setChapterGroup(e.target.value)}
                    >
                        <CustomMenu value="inorganic-chemistry">Inorganic Chemistry</CustomMenu>
                        <CustomMenu value="organic-chemistry">Organic Chemistry</CustomMenu>
                        <CustomMenu value="physical-chemistry">Physical Chemistry</CustomMenu>
                    </CustomSelect>
                </FormControl>

                <FormControl sx={{ minWidth: 120 }}>
                    <CustomInputLabel id="tags-label">Tags</CustomInputLabel>
                    <CustomSelect
                        labelId="tags-label"
                        multiple
                        value={tags}
                        onChange={handleTagChange}
                        label="Tags"
                        renderValue={(selected) => selected.join(', ')}
                    >
                        <CustomMenu value="jee-mains">jee mains</CustomMenu>
                        <CustomMenu value="organic-chemistry">organic chemistry</CustomMenu>
                    </CustomSelect>
                </FormControl>

                <FormControl sx={{ minWidth: 120 }}>
                    <CustomInputLabel id="questionType-label">Question Type</CustomInputLabel>
                    <CustomSelect
                        labelId="questionType-label"
                        value={questionType}
                        label="Question Type"
                        onChange={(e) => setQuestionType(e.target.value)}
                    >
                        <CustomMenu value="mcq">MCQ</CustomMenu>
                        <CustomMenu value="numerical">Numerical</CustomMenu>
                        <CustomMenu value="multi-correct">Multi Correct</CustomMenu>
                    </CustomSelect>
                </FormControl>

                <FormControl sx={{ minWidth: 120 }}>
                    <CustomInputLabel id="label-label">Level Rating</CustomInputLabel>
                    <CustomSelect
                        labelId="label-label"
                        value={levelRating}
                        label="Level Rating"
                        onChange={(e) => setLevelRating(e.target.value)}
                    >
                        <CustomMenu value="1">1</CustomMenu>
                        <CustomMenu value="2">2</CustomMenu>
                        <CustomMenu value="3">3</CustomMenu>
                        <CustomMenu value="4">4</CustomMenu>
                        <CustomMenu value="5">5</CustomMenu>
                    </CustomSelect>
                </FormControl>

                <FormControl sx={{ minWidth: 120 }}>
                    <CustomInputLabel id="topic-label">Topic</CustomInputLabel>
                    <CustomSelect
                        labelId="topic-label"
                        value={topic}
                        label="Topic"
                        onChange={(e) => setTopic(e.target.value)}
                    >
                        <CustomMenu value="group-14-elements-carbon-family">group-14-elements-carbon-family</CustomMenu>
                    </CustomSelect>
                </FormControl>

                <FormControl sx={{ minWidth: 120 }}>
                    <CustomInputLabel id="status-label">Status</CustomInputLabel>
                    <CustomSelect
                        labelId="status-label"
                        value={status}
                        label="Status"
                        onChange={(e) => setStatus(e.target.value)}
                    >
                        <CustomMenu value="active">Active</CustomMenu>
                        <CustomMenu value="inactive">Inactive</CustomMenu>
                    </CustomSelect>
                </FormControl>

                <FormControl sx={{ minWidth: 120 }}>
                    <CustomInputLabel id="section-label">Section</CustomInputLabel>
                    <CustomSelect
                        labelId="section-label"
                        value={section}
                        label="Section"
                        onChange={(e) => setSection(e.target.value)}
                    >
                        <CustomMenu value="A">A</CustomMenu>
                        <CustomMenu value="B">B</CustomMenu>
                        <CustomMenu value="C">C</CustomMenu>
                        <CustomMenu value="D">D</CustomMenu>
                        <CustomMenu value="E">E</CustomMenu>
                        <CustomMenu value="F">F</CustomMenu>
                    </CustomSelect>
                </FormControl>

                <FormControl sx={{ minWidth: 120 }}>
                    <CustomInputLabel id="verified-label">Verified</CustomInputLabel>
                    <CustomSelect
                        labelId="verified-label"
                        value={verified}
                        label="Verified"
                        onChange={(e) => setVerified(e.target.value)}
                    >
                        <CustomMenu value={true}>True</CustomMenu>
                        <CustomMenu value={false}>False</CustomMenu>
                    </CustomSelect>
                </FormControl>

                <FormControl sx={{ minWidth: 120 }}>
                    <CustomInputLabel id="completed-label">Completed</CustomInputLabel>
                    <CustomSelect
                        labelId="completed-label"
                        value={completed}
                        label="Completed"
                        onChange={(e) => setCompleted(e.target.value)}
                    >
                        <CustomMenu value={true}>True</CustomMenu>
                        <CustomMenu value={false}>False</CustomMenu>
                    </CustomSelect>
                </FormControl>

            </Paper>

            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, }}>
                <Button variant="contained" color="primary" onClick={() => fetchFilteredQuestions
                (
                    questionKey,
                    comprehension,
                    paperTitle,
                    language,
                    exam,
                    examGroup,
                    year,
                    subject,
                    chapter,
                    chapterGroup,
                    tags,
                    questionType,
                    levelRating,
                    topic,
                    status,
                    section,
                    verified,
                    completed,
                )}>Filter</Button>
                {isTest ?
                    <Button variant="contained" color="secondary" onClick={handleAddQues}>Add Questions</Button>
                    :
                    <Button variant="contained" color="secondary" onClick={handleCreateTest}>Create Test</Button>
                }
            </Box>
        </Box>
    );
}