import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import InfoContext from './../../contexts/infoContext';
import { Modal, Box, Typography, TextField, Button } from '@mui/material';
import { Brain, Smartphone, Key, LogIn } from 'lucide-react';
import { checkAdmin, sendOTP, verifyOTP } from '../../services/auth/auth';
import googleLogo from './../../assets/icons/logo-google.png';

const Login = ({ open, onClose }) => {
  const [formErrors, setFormErrors] = useState({});
  const [otp, setOtp] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [number, setNumber] = useState('');

  const context = useContext(InfoContext);
  const { setToken } = context;
  let navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateNumber();
    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
      try {
        const userNumber = '+91' + number;
        const data = await sendOTP(userNumber);
        if (data.otpSent === true && data.newUser === false) {
          setOtpSent(true);
          toast.success('OTP sent successfully!');
        } else {
          toast.error('Error sending OTP. Please try again.');
        }
      } catch (e) {
        toast.error('An error occurred. Please try again later.');
      }
    }
  };

  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    const errors = validateOtp();
    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
      setLoading(true);
      try {
        let formData = { mobile: '+91' + number, otp };
        const data = await verifyOTP(formData);
        if (data.verified && !data.newUser) {
          const adminCheck = await checkAdmin(data.token);
          if (adminCheck) {
            setToken(data.token);
            localStorage.setItem('token', JSON.stringify(data.token));
            navigate('/');
            onClose();
          } else {
            toast.error('You are not authorized. Please contact admin.');
          }
        } else {
          toast.error('Invalid OTP or not registered. Please try again.');
        }
      } catch (e) {
        toast.error('An error occurred. Please try again later.');
      } finally {
        setLoading(false);
      }
    }
  };

  const validateNumber = () => {
    const errors = {};
    if (!number) {
      errors.number = "Mobile number is required";
    } else if (!number.match(/^[0-9]{10}$/)) {
      errors.number = "Enter a valid 10-digit mobile number";
    }
    return errors;
  };

  const validateOtp = () => {
    const errors = {};
    if (!otp) {
      errors.otp = "OTP is required";
    } else if (!otp.match(/^[0-9]{4}$/)) {
      errors.otp = "Enter a valid 4-digit OTP";
    }
    return errors;
  };

  const handleGoogleLogin = () => {
    const redirectUri = encodeURIComponent('https://testbuddy.live/auth/google/callback');
    const clientId = encodeURIComponent('953765759054-velhe7h0ddr72u976nqclm7qvu458lba.apps.googleusercontent.com');
    const scope = encodeURIComponent('https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email');
    const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=${redirectUri}&client_id=${clientId}&access_type=offline&response_type=code&prompt=consent&scope=${scope}`;
    window.location.href = authUrl;
  };

  const handleChangeNumber = () => {
    setOtpSent(false);
    setOtp('');
    setFormErrors({});
  };

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            maxWidth: 800,
            height: 600,
            bgcolor: 'background.paper',
            borderRadius: 4,
            boxShadow: 24,
            display: 'flex',
            overflow: 'hidden',
            animation: 'fadeIn 0.5s',
            '@keyframes fadeIn': {
              from: { opacity: 0, transform: 'translate(-50%, -48%)' },
              to: { opacity: 1, transform: 'translate(-50%, -50%)' },
            },
          }}
        >
          <Box
            sx={{
              width: '40%',
              bgcolor: '#1976d2',
              color: 'white',
              p: 4,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
              <Brain size={48} />
              <Typography variant="h4" sx={{ ml: 2, fontWeight: 'bold' }}>
                TestBuddy
              </Typography>
            </Box>
            <Typography variant="h6" gutterBottom>
              Ultimate Destination for All Your Practice
            </Typography>
            <Box sx={{ mt: 4 }}>
              <Typography variant="body1" sx={{ mb: 2 }}>
                • Trusted By 4.7 Million Students
              </Typography>
              <Typography variant="body1" sx={{ mb: 2 }}>
                • 9+ Years of Excellence
              </Typography>
              <Typography variant="body1" sx={{ mb: 2 }}>
                • Expert Guidance By IIM Alumni
              </Typography>
              <Typography variant="body1">
                • Free Daily Tests and Mocks
              </Typography>
            </Box>
          </Box>

          <Box sx={{ width: '60%', p: 4 }}>
            <Typography variant="h5" align="center" gutterBottom fontWeight="bold">
              Hello! 👋
            </Typography>
            <Typography variant="subtitle1" align="center" sx={{ mb: 3 }}>
              Ready to Unlock Your Success?
            </Typography>
            <form onSubmit={otpSent ? handleOtpSubmit : handleSubmit}>
              <TextField
                fullWidth
                label="Mobile Number"
                variant="outlined"
                value={number}
                onChange={(e) => setNumber(e.target.value)}
                disabled={otpSent}
                error={!!formErrors.number}
                helperText={formErrors.number}
                margin="normal"
                InputProps={{
                  startAdornment: (
                    <Box sx={{ mr: 1, color: 'text.secondary' }}>
                      <Smartphone size={20} />
                    </Box>
                  ),
                }}
              />
              {otpSent && (
                <Box sx={{ width: 'full' }}>
                  <TextField
                    fullWidth
                    label="OTP"
                    variant="outlined"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    error={!!formErrors.otp}
                    helperText={formErrors.otp}
                    margin="normal"
                    InputProps={{
                      startAdornment: (
                        <Box sx={{ mr: 1, color: 'text.secondary' }}>
                          <Key size={20} />
                        </Box>
                      ),
                    }}
                  />
                  <Button
                    variant="outlined"
                    color="warning"
                    onClick={handleChangeNumber}
                    sx={{
                      mt: 2,
                      height: 48,
                      width: '100%',
                      borderRadius: 2,
                      textTransform: 'none',
                      fontSize: '1rem',
                    }}
                  >
                    Change Number
                  </Button>
                </Box>
              )}
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                sx={{
                  mt: 2,
                  height: 48,
                  borderRadius: 2,
                  textTransform: 'none',
                  fontSize: '1rem',
                  transition: 'all 0.3s',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: '0 4px 20px 0 rgba(0,0,0,0.12)',
                  },
                }}
                startIcon={<LogIn />}
                disabled={loading}
              >
                {loading ? 'Processing...' : (otpSent ? 'Verify OTP' : 'Send OTP')}
              </Button>
            </form>
            <Box sx={{ mt: 3, textAlign: 'center' }}>
              <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
                Or continue with
              </Typography>
              <Button
                variant="outlined"
                onClick={handleGoogleLogin}
                fullWidth
                sx={{
                  mt: 1,
                  borderRadius: 2,
                  textTransform: 'none',
                  fontSize: '1rem',
                  height: 48,
                  transition: 'all 0.3s',
                  '&:hover': {
                    backgroundColor: '#f1f3f4',
                  },
                }}
                startIcon={
                  <img
                    src={googleLogo}
                    alt="Google Logo"
                    style={{ width: '24px', height: '24px' }}
                  />
                }
              >
                Google
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="light" />
    </>
  );
};

export default Login;