import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

const Content = () => {
    const location = useLocation();
    const blogData = location.state?.blogData; // Accessing blogData from state

    const [toc, setToc] = useState([
        {
            "subject": "Physics",
            "chapters": [
              {
                "title": "Mechanics",
                "permalink": "http://testbuddy.live/blogs/physics/Mechanics",
                "topics": [
                  {
                    "title": "Kinematics",
                    "permalink": "http://testbuddy.live/blogs/physics/Mechanics/Kinematics",
                    "subtopics": [
                      {
                        "title": "Motion in a straight line",
                        "permalink": "http://testbuddy.live/blogs/physics/Mechanics/Kinematics/Motion_in_a_straight_line"
                      },
                      {
                        "title": "Motion in a plane",
                        "permalink": "http://testbuddy.live/blogs/physics/Mechanics/Kinematics/Motion_in_a_plane"
                      }
                    ]
                  },
                  {
                    "title": "Laws of Motion",
                    "permalink": "http://testbuddy.live/blogs/physics/Mechanics/Laws_of_Motion",
                    "subtopics": [
                      {
                        "title": "Newton's laws of motion",
                        "permalink": "http://testbuddy.live/blogs/physics/Mechanics/Laws_of_Motion/Newtons_laws_of_motion"
                      },
                      {
                        "title": "Inertia and mass",
                        "permalink": "http://testbuddy.live/blogs/physics/Mechanics/Laws_of_Motion/Inertia_and_mass"
                      },
                      {
                        "title": "Forces",
                        "permalink": "http://testbuddy.live/blogs/physics/Mechanics/Laws_of_Motion/Forces"
                      },
                      {
                        "title": "Friction",
                        "permalink": "http://testbuddy.live/blogs/physics/Mechanics/Laws_of_Motion/Friction"
                      }
                    ]
                  },
                  {
                    "title": "Work, Energy, and Power",
                    "permalink": "http://testbuddy.live/blogs/physics/Mechanics/Work_Energy_and_Power",
                    "subtopics": [
                      {
                        "title": "Work",
                        "permalink": "http://testbuddy.live/blogs/physics/Mechanics/Work_Energy_and_Power/Work"
                      },
                      {
                        "title": "Energy",
                        "permalink": "http://testbuddy.live/blogs/physics/Mechanics/Work_Energy_and_Power/Energy"
                      },
                      {
                        "title": "Power",
                        "permalink": "http://testbuddy.live/blogs/physics/Mechanics/Work_Energy_and_Power/Power"
                      }
                    ]
                  },
                  {
                    "title": "Rotational Motion",
                    "permalink": "http://testbuddy.live/blogs/physics/Mechanics/Rotational_Motion",
                    "subtopics": [
                      {
                        "title": "Torque",
                        "permalink": "http://testbuddy.live/blogs/physics/Mechanics/Rotational_Motion/Torque"
                      },
                      {
                        "title": "Moment of inertia",
                        "permalink": "http://testbuddy.live/blogs/physics/Mechanics/Rotational_Motion/Moment_of_inertia"
                      },
                      {
                        "title": "Angular momentum",
                        "permalink": "http://testbuddy.live/blogs/physics/Mechanics/Rotational_Motion/Angular_momentum"
                      },
                      {
                        "title": "Conservation of angular momentum",
                        "permalink": "http://testbuddy.live/blogs/physics/Mechanics/Rotational_Motion/Conservation_of_angular_momentum"
                      }
                    ]
                  },
                  {
                    "title": "Gravitation",
                    "permalink": "http://testbuddy.live/blogs/physics/Mechanics/Gravitation",
                    "subtopics": [
                      {
                        "title": "Newton's law of gravitation",
                        "permalink": "http://testbuddy.live/blogs/physics/Mechanics/Gravitation/Newtons_law_of_gravitation"
                      },
                      {
                        "title": "Gravitational potential energy",
                        "permalink": "http://testbuddy.live/blogs/physics/Mechanics/Gravitation/Gravitational_potential_energy"
                      },
                      {
                        "title": "Escape velocity",
                        "permalink": "http://testbuddy.live/blogs/physics/Mechanics/Gravitation/Escape_velocity"
                      },
                      {
                        "title": "Satellites",
                        "permalink": "http://testbuddy.live/blogs/physics/Mechanics/Gravitation/Satellites"
                      }
                    ]
                  },
                  {
                    "title": "Fluid Mechanics",
                    "permalink": "http://testbuddy.live/blogs/physics/Mechanics/Fluid_Mechanics",
                    "subtopics": [
                      {
                        "title": "Properties of fluids",
                        "permalink": "http://testbuddy.live/blogs/physics/Mechanics/Fluid_Mechanics/Properties_of_fluids"
                      },
                      {
                        "title": "Buoyancy",
                        "permalink": "http://testbuddy.live/blogs/physics/Mechanics/Fluid_Mechanics/Buoyancy"
                      },
                      {
                        "title": "Bernoulli's principle",
                        "permalink": "http://testbuddy.live/blogs/physics/Mechanics/Fluid_Mechanics/Bernoullis_principle"
                      },
                      {
                        "title": "Viscosity",
                        "permalink": "http://testbuddy.live/blogs/physics/Mechanics/Fluid_Mechanics/Viscosity"
                      }
                    ]
                  }
                ]
              },
              {
                "title": "Thermal Physics",
                "permalink": "http://testbuddy.live/blogs/physics/Thermal_Physics",
                "topics": [
                  {
                    "title": "Heat and Temperature",
                    "permalink": "http://testbuddy.live/blogs/physics/Thermal_Physics/Heat_and_Temperature",
                    "subtopics": [
                      {
                        "title": "Temperature scales",
                        "permalink": "http://testbuddy.live/blogs/physics/Thermal_Physics/Heat_and_Temperature/Temperature_scales"
                      },
                      {
                        "title": "Thermal expansion",
                        "permalink": "http://testbuddy.live/blogs/physics/Thermal_Physics/Heat_and_Temperature/Thermal_expansion"
                      }
                    ]
                  },
                  {
                    "title": "Laws of Thermodynamics",
                    "permalink": "http://testbuddy.live/blogs/physics/Thermal_Physics/Laws_of_Thermodynamics",
                    "subtopics": [
                      {
                        "title": "Zeroth law",
                        "permalink": "http://testbuddy.live/blogs/physics/Thermal_Physics/Laws_of_Thermodynamics/Zeroth_law"
                      },
                      {
                        "title": "First law",
                        "permalink": "http://testbuddy.live/blogs/physics/Thermal_Physics/Laws_of_Thermodynamics/First_law"
                      },
                      {
                        "title": "Second law",
                        "permalink": "http://testbuddy.live/blogs/physics/Thermal_Physics/Laws_of_Thermodynamics/Second_law"
                      },
                      {
                        "title": "Third law",
                        "permalink": "http://testbuddy.live/blogs/physics/Thermal_Physics/Laws_of_Thermodynamics/Third_law"
                      }
                    ]
                  },
                  {
                    "title": "Kinetic Theory of Gases",
                    "permalink": "http://testbuddy.live/blogs/physics/Thermal_Physics/Kinetic_Theory_of_Gases",
                    "subtopics": [
                      {
                        "title": "Kinetic theory of gases assumptions",
                        "permalink": "http://testbuddy.live/blogs/physics/Thermal_Physics/Kinetic_Theory_of_Gases/Kinetic_theory_of_gases_assumptions"
                      },
                      {
                        "title": "Degrees of freedom",
                        "permalink": "http://testbuddy.live/blogs/physics/Thermal_Physics/Kinetic_Theory_of_Gases/Degrees_of_freedom"
                      },
                      {
                        "title": "Equipartition of energy",
                        "permalink": "http://testbuddy.live/blogs/physics/Thermal_Physics/Kinetic_Theory_of_Gases/Equipartition_of_energy"
                      },
                      {
                        "title": "Mean free path",
                        "permalink": "http://testbuddy.live/blogs/physics/Thermal_Physics/Kinetic_Theory_of_Gases/Mean_free_path"
                      }
                    ]
                  },
                  {
                    "title": "Heat Transfer",
                    "permalink": "http://testbuddy.live/blogs/physics/Thermal_Physics/Heat_Transfer",
                    "subtopics": [
                      {
                        "title": "Conduction",
                        "permalink": "http://testbuddy.live/blogs/physics/Thermal_Physics/Heat_Transfer/Conduction"
                      },
                      {
                        "title": "Convection",
                        "permalink": "http://testbuddy.live/blogs/physics/Thermal_Physics/Heat_Transfer/Convection"
                      },
                      {
                        "title": "Radiation",
                        "permalink": "http://testbuddy.live/blogs/physics/Thermal_Physics/Heat_Transfer/Radiation"
                      },
                      {
                        "title": "Newton's law of cooling",
                        "permalink": "http://testbuddy.live/blogs/physics/Thermal_Physics/Heat_Transfer/Newtons_law_of_cooling"
                      },
                      {
                        "title": "Stefan-Boltzmann law",
                        "permalink": "http://testbuddy.live/blogs/physics/Thermal_Physics/Heat_Transfer/Stefan_Boltzmann_law"
                      }
                    ]
                  }
                ]
              },
              {
                "title": "Electromagnetism",
                "permalink": "http://testbuddy.live/blogs/physics/Electromagnetism",
                "topics": [
                  {
                    "title": "Electrostatics",
                    "permalink": "http://testbuddy.live/blogs/physics/Electromagnetism/Electrostatics",
                    "subtopics": [
                      {
                        "title": "Coulomb's law",
                        "permalink": "http://testbuddy.live/blogs/physics/Electromagnetism/Electrostatics/Coulombs_law"
                      },
                      {
                        "title": "Electric field",
                        "permalink": "http://testbuddy.live/blogs/physics/Electromagnetism/Electrostatics/Electric_field"
                      },
                      {
                        "title": "Gauss's law",
                        "permalink": "http://testbuddy.live/blogs/physics/Electromagnetism/Electrostatics/Gausss_law"
                      },
                      {
                        "title": "Electric potential",
                        "permalink": "http://testbuddy.live/blogs/physics/Electromagnetism/Electrostatics/Electric_potential"
                      }
                    ]
                  },
                  {
                    "title": "Current Electricity",
                    "permalink": "http://testbuddy.live/blogs/physics/Electromagnetism/Current_Electricity",
                    "subtopics": [
                      {
                        "title": "Ohm's law",
                        "permalink": "http://testbuddy.live/blogs/physics/Electromagnetism/Current_Electricity/Ohms_law"
                      },
                      {
                        "title": "Kirchhoff's laws",
                        "permalink": "http://testbuddy.live/blogs/physics/Electromagnetism/Current_Electricity/Kirchhoffs_laws"
                      },
                      {
                        "title": "Series and parallel circuits",
                        "permalink": "http://testbuddy.live/blogs/physics/Electromagnetism/Current_Electricity/Series_and_parallel_circuits"
                      }
                    ]
                  },
                  {
                    "title": "Magnetic Effects of Current and Magnetism",
                    "permalink": "http://testbuddy.live/blogs/physics/Electromagnetism/Magnetic_Effects_of_Current_and_Magnetism",
                    "subtopics": [
                      {
                        "title": "Biot-Savart law",
                        "permalink": "http://testbuddy.live/blogs/physics/Electromagnetism/Magnetic_Effects_of_Current_and_Magnetism/Biot_Savart_law"
                      },
                      {
                        "title": "Ampere's law",
                        "permalink": "http://testbuddy.live/blogs/physics/Electromagnetism/Magnetic_Effects_of_Current_and_Magnetism/Amperes_law"
                      },
                      {
                        "title": "Lorentz force",
                        "permalink": "http://testbuddy.live/blogs/physics/Electromagnetism/Magnetic_Effects_of_Current_and_Magnetism/Lorentz_force"
                      }
                    ]
                  },
                  {
                    "title": "Electromagnetic Induction and AC",
                    "permalink": "http://testbuddy.live/blogs/physics/Electromagnetism/Electromagnetic_Induction_and_AC",
                    "subtopics": [
                      {
                        "title": "Faraday's laws",
                        "permalink": "http://testbuddy.live/blogs/physics/Electromagnetism/Electromagnetic_Induction_and_AC/Faradays_laws"
                      },
                      {
                        "title": "Lenz's law",
                        "permalink": "http://testbuddy.live/blogs/physics/Electromagnetism/Electromagnetic_Induction_and_AC/Lenxs_law"
                      },
                      {
                        "title": "AC circuits",
                        "permalink": "http://testbuddy.live/blogs/physics/Electromagnetism/Electromagnetic_Induction_and_AC/AC_circuits"
                      }
                    ]
                  },
                  {
                    "title": "Electromagnetic Waves",
                    "permalink": "http://testbuddy.live/blogs/physics/Electromagnetism/Electromagnetic_Waves",
                    "subtopics": [
                      {
                        "title": "Wavefronts and Huygens principle",
                        "permalink": "http://testbuddy.live/blogs/physics/Electromagnetism/Electromagnetic_Waves/Wavefronts_and_Huygens_principle"
                      },
                      {
                        "title": "Electromagnetic spectrum",
                        "permalink": "http://testbuddy.live/blogs/physics/Electromagnetism/Electromagnetic_Waves/Electromagnetic_spectrum"
                      }
                    ]
                  }
                ]
              },
              {
                "title": "Optics",
                "permalink": "http://testbuddy.live/blogs/physics/Optics",
                "topics": [
                  {
                    "title": "Ray Optics",
                    "permalink": "http://testbuddy.live/blogs/physics/Optics/Ray_Optics",
                    "subtopics": [
                      {
                        "title": "Reflection",
                        "permalink": "http://testbuddy.live/blogs/physics/Optics/Ray_Optics/Reflection"
                      },
                      {
                        "title": "Refraction",
                        "permalink": "http://testbuddy.live/blogs/physics/Optics/Ray_Optics/Refraction"
                      },
                      {
                        "title": "Mirrors and lenses",
                        "permalink": "http://testbuddy.live/blogs/physics/Optics/Ray_Optics/Mirrors_and_lenses"
                      },
                      {
                        "title": "Optical instruments",
                        "permalink": "http://testbuddy.live/blogs/physics/Optics/Ray_Optics/Optical_instruments"
                      }
                    ]
                  },
                  {
                    "title": "Wave Optics",
                    "permalink": "http://testbuddy.live/blogs/physics/Optics/Wave_Optics",
                    "subtopics": [
                      {
                        "title": "Interference",
                        "permalink": "http://testbuddy.live/blogs/physics/Optics/Wave_Optics/Interference"
                      },
                      {
                        "title": "Diffraction",
                        "permalink": "http://testbuddy.live/blogs/physics/Optics/Wave_Optics/Diffraction"
                      },
                      {
                        "title": "Polarization",
                        "permalink": "http://testbuddy.live/blogs/physics/Optics/Wave_Optics/Polarization"
                      }
                    ]
                  }
                ]
              },
              {
                "title": "Modern Physics",
                "permalink": "http://testbuddy.live/blogs/physics/Modern_Physics",
                "topics": [
                  {
                    "title": "Dual Nature of Matter and Radiation",
                    "permalink": "http://testbuddy.live/blogs/physics/Modern_Physics/Dual_Nature_of_Matter_and_Radiation",
                    "subtopics": [
                      {
                        "title": "Photoelectric effect",
                        "permalink": "http://testbuddy.live/blogs/physics/Modern_Physics/Dual_Nature_of_Matter_and_Radiation/Photoelectric_effect"
                      },
                      {
                        "title": "Matter waves",
                        "permalink": "http://testbuddy.live/blogs/physics/Modern_Physics/Dual_Nature_of_Matter_and_Radiation/Matter_waves"
                      }
                    ]
                  },
                  {
                    "title": "Atoms and Nuclei",
                    "permalink": "http://testbuddy.live/blogs/physics/Modern_Physics/Atoms_and_Nuclei",
                    "subtopics": [
                      {
                        "title": "Rutherford's model",
                        "permalink": "http://testbuddy.live/blogs/physics/Modern_Physics/Atoms_and_Nuclei/Rutherfords_model"
                      },
                      {
                        "title": "Bohr's model",
                        "permalink": "http://testbuddy.live/blogs/physics/Modern_Physics/Atoms_and_Nuclei/Bohrs_model"
                      },
                      {
                        "title": "Radioactivity",
                        "permalink": "http://testbuddy.live/blogs/physics/Modern_Physics/Atoms_and_Nuclei/Radioactivity"
                      }
                    ]
                  },
                  {
                    "title": "Semiconductor Electronics",
                    "permalink": "http://testbuddy.live/blogs/physics/Modern_Physics/Semiconductor_Electronics",
                    "subtopics": [
                      {
                        "title": "Diodes",
                        "permalink": "http://testbuddy.live/blogs/physics/Modern_Physics/Semiconductor_Electronics/Diodes"
                      },
                      {
                        "title": "Transistors",
                        "permalink": "http://testbuddy.live/blogs/physics/Modern_Physics/Semiconductor_Electronics/Transistors"
                      },
                      {
                        "title": "Logic gates",
                        "permalink": "http://testbuddy.live/blogs/physics/Modern_Physics/Semiconductor_Electronics/Logic_gates"
                      }
                    ]
                  },
                  {
                    "title": "Communication Systems",
                    "permalink": "http://testbuddy.live/blogs/physics/Modern_Physics/Communication_Systems",
                    "subtopics": [
                      {
                        "title": "Modulation and demodulation",
                        "permalink": "http://testbuddy.live/blogs/physics/Modern_Physics/Communication_Systems/Modulation_and_demodulation"
                      },
                      {
                        "title": "Satellite communication",
                        "permalink": "http://testbuddy.live/blogs/physics/Modern_Physics/Communication_Systems/Satellite_communication"
                      }
                    ]
                  }
                ]
              }
            ]
          }
          
    ]);
    const [loading, setLoading] = useState(true); // Add loading state
    const [error, setError] = useState(null); // Add error state

    // Function to fetch TOC data from the API
    // const fetchTocData = async () => {
    //     try {
    //         // Replace with your API endpoint
    //         const response = await axios.get('https://your-api-endpoint.com/toc');
    //         setToc(response.data);
    //     } catch (err) {
    //         setError(err);
    //     } finally {
    //         setLoading(false);
    //     }
    // };

    // useEffect(() => {
    //     fetchTocData();
    // }, []);

    // Placeholder for blogData, if it doesn't exist
    const blogContent = blogData?.blogContent || "<p>No content available</p>";

    return (
        <div className="flex flex-col xl:flex-row justify-between">
            <div className=" fixed top-[10%] left-0 overflow-y-auto xl:w-1/4  mb-4 xl:mb-0">
                <ul>
                    {toc.map((chapter, index) => (
                        <li key={index}>
                            <ul>
                                {chapter.chapters.map((ch, chIndex) => (
                                    <li key={chIndex}>
                                        <a
                                            href={ch.permalink}
                                            className="text-black no-underline text-xl font-semibold block"
                                        >
                                            <span className="text-2xl">#</span> {ch.title}
                                        </a>
                                        <ul className="ml-4 list-disc">
                                            {ch.topics.map((topic, tIndex) => (
                                                <li key={tIndex}>
                                                    <a
                                                        href={topic.permalink}
                                                        className="text-black no-underline text-md font-medium block"
                                                    >
                                                        {topic.title}
                                                    </a>
                                                </li>
                                            ))}
                                        </ul>
                                    </li>
                                ))}
                            </ul>
                        </li>
                    ))}
                </ul>
            </div>
        </div>

    );
};

export default Content;
