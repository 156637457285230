// Function to make the OTP request
export const sendOTP = async (number) => {

  const response = await fetch('https://api.testbuddy.live/v1/auth/otp', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ mobile: number })
  });

  if (response.status >= 200 && response.status <= 299) {
    const data = await response.json();
    return data;
  } else {
    const status = response.status;
    const data = await response.json();
    return { status: status, message: data.message };
  }

};

// Function to verify OTP and user details
export const verifyOTP = async (formData) => {
  const response = await fetch('https://api.testbuddy.live/v1/auth/verifyotp', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(formData)
  });

  if (response.status >= 200 && response.status <= 399) {
    const data = await response.json();
    return data;
  } else {
    const status = response.status;
    const data = await response.json();
    return { status: status, message: data.message };
  }
};

export const checkAdmin = async (token) => {
  const response = await fetch(
    "https://api.testbuddy.live/v1/admin/admindata", {
    method: "POST",
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }
  });
  if (response.status >= 200 && response.status <= 399 ) {
    const data = await response.json();
    return true;
  } else {
    const status = response.status;
    const data = await response.json();
    return false;
  }

}
