export const fetchProfileDetails = async () => {
  try {
    const token = localStorage.getItem('token');

    if (token) {
      const response = await fetch("https://api.testbuddy.live/v1/profile", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `bearer ${JSON.parse(token)}`
        }
      });

      if (response.status >= 200 && response.status <= 399) {
        const data = await response.json();
        return data;
      } else if (response.status === 401) {
        localStorage.removeItem('token');
        const data = await response.json();
        return { status: 401, message: data.message };
      } else {
        const status = response.status;
        const data = await response.json();
        return { status: status, message: data.message };
      }

    }
  } catch (error) {
    console.error('Error fetching profile details:', error);
  }
};

export const editProfile = async (formData) => {
  try {

    const token = localStorage.getItem('token');
    if (token) {
      const response = await fetch(`https://api.testbuddy.live/v1/updateprofile`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `bearer ${JSON.parse(token)}`
        },
        body: JSON.stringify(formData)
      });

      if (response.status >= 200 && response.status <= 399) {
        const data = await response.json();
        return data;
      } else if (response.status === 401) {
        localStorage.removeItem('token');
        const data = await response.json();
        return { status: 401, message: data.message };
      } else {
        const status = response.status;
        const data = await response.json();
        return { status: status, message: data.message };
      }
      
    }
  } catch (error) {
    console.error('Error editing profile:', error);
  }
};

export const editProfilePicture = async (formData) => {
  try {
    const token = localStorage.getItem('token');

    console.log(formData);

    if (token) {
      const response = await fetch("https://api.testbuddy.live/v1/profilepic", {
        method: "POST",
        headers: {
          'Authorization': `bearer ${JSON.parse(token)}`
        },
        body: formData
      });

      if (response.status >= 200 && response.status <= 399) {
        const data = await response.json();
        return data;
      } else if (response.status === 401) {
        localStorage.removeItem('token');
        const data = await response.json();
        return { status: 401, message: data.message };
      } else {
        const status = response.status;
        const data = await response.json();
        return { status: status, message: data.message };
      }

    }
  } catch (error) {
    console.error('Error Occured! Try Again', error);
  }
}

export const fetchNotifications = async () => {
  try {
    const token = localStorage.getItem('token');

    if (token) {
      const response = await fetch("https://api.testbuddy.live/v1/notifications", {
        method: "POST",
        headers: {
          'Authorization': `bearer ${JSON.parse(token)}`
        }
      });

      if (response.status >= 200 && response.status <= 399) {
        const data = await response.json();
        return data;
      } else if (response.status === 401) {
        localStorage.removeItem('token');
        const data = await response.json();
        return { status: 401, message: data.message };
      } else {
        const status = response.status;
        const data = await response.json();
        return { status: status, message: data.message };
      }
      
    }
  } catch (error) {
    console.error('Error fetching notifications:', error);
  }
};

export const fetchAllNotifications = async () => {
  try {
    const token = localStorage.getItem('token');

    const response = await fetch("https://api.testbuddy.live/v1/notifications/all", {
      method: "POST",
      headers: {
        'Authorization': `bearer ${JSON.parse(token)}`
      }
    });
    console.log(response, token);
    if (response.status >= 200 && response.status <= 399) {
      const data = await response.json();
      return data;
    } else if (response.status === 401) {
      localStorage.removeItem('token');
      const data = await response.json();
      return { status: 401, message: data.message };
    } else {
      const status = response.status;
      const data = await response.json();
      return { status: status, message: data.message };
    }

  } catch (error) {
    console.error('Error fetching all notifications:', error);
  }
};

export const fetchOffer = async () => {
  try {
    const response = await fetch('https://api.testbuddy.live/v1/offer');

    if (response.status >= 200 && response.status <= 399) {
      const data = await response.json();
      return data;
    } else {
      const status = response.status;
      const data = await response.json();
      return { status: status, message: data.message };
    }
    
  } catch (error) {
    console.error('Error fetching notifications:', error);
  }
}