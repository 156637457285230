import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export default function QuestionImageModal({ setQuestionImageModal, Question }) {
    return (
        <Dialog
            open={true}
            onClose={() => setQuestionImageModal(false)}
            aria-labelledby="question-image-modal-title"
            fullWidth
            maxWidth="md"
            PaperProps={{
                style: {
                    borderRadius: 15,
                    overflow: 'hidden'
                }
            }}
        >
            <DialogTitle id="question-image-modal-title" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                Question Image
                <IconButton aria-label="close" onClick={() => setQuestionImageModal(false)}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <img 
                    src={Question.questionImage} 
                    alt="Question" 
                    style={{ width: '100%', height: 'auto', display: 'block', borderRadius: '0 0 15px 15px' }}
                />
            </DialogContent>
        </Dialog>
    );
}