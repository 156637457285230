import React from 'react';
import { Box, Typography, Link } from '@mui/material';
import { styled } from '@mui/system';
import CopyrightIcon from '@mui/icons-material/Copyright';

const FooterContainer = styled(Box)({
  backgroundColor: '#333',
  color: '#fff',
  padding: '10px 0',
  textAlign: 'center',
  position: 'relative',
  bottom: 0,
  width: '100%',
});

const FooterText = styled(Typography)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '& svg': {
    marginRight: '5px',
  },
});

const FooterLink = styled(Link)({
  color: '#90caf9',
  textDecoration: 'none',
  transition: 'color 0.3s ease',
  '&:hover': {
    color: '#e3f2fd',
  },
});

const Footer = () => {
  return (
    <FooterContainer>
      <FooterText variant="body2">
        <CopyrightIcon fontSize="small" />
        TestBuddy Education Pvt Ltd
      </FooterText>
      <Box sx={{display:'flex', gap: 2, justifyContent: 'center'}}>
        <FooterLink href="/terms-and-conditions">Terms and Conditions</FooterLink>
        <FooterLink href="/privacy-policy">Privacy Policy</FooterLink>
      </Box>
      <FooterText variant="body2">TestBuddy 🇮🇳</FooterText>
    </FooterContainer>
  );
}

export default Footer;
