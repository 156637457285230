import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const OnThisPage = () => {
    const location = useLocation();
    const blogData = location.state?.blogData; // Accessing blogData from state

    const [headings, setHeadings] = useState([]);
    const [loading, setLoading] = useState(true); // Add loading state
    const [error, setError] = useState(null); // Add error state
    const [currentId, setCurrentId] = useState(null); // State to track the currently highlighted heading
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    useEffect(() => {
        if (blogData) {
            const extractHeadings = (htmlContent) => {
                // Create a DOM parser to parse the HTML
                const parser = new DOMParser();
                const doc = parser.parseFromString(htmlContent, 'text/html');
                
                // Extract headings and build a nested structure
                const headingStack = [];
                const nestedHeadings = [];
                
                doc.querySelectorAll('h1, h2, h3, h4, h5, h6, strong').forEach((heading) => {
                    const level = parseInt(heading.tagName.substring(1)) || 6;
                    const headingData = {
                        level,
                        text: heading.textContent,
                        id: heading.id || heading.textContent.replace(/\s+/g, '-').toLowerCase(),
                        children: [] // Ensure children is always an array
                    };

                    // Ensure the heading element has an id for linking
                    if (!heading.id) {
                        heading.id = headingData.id;
                    }

                    while (headingStack.length > 0 && headingStack[headingStack.length - 1].level >= level) {
                        headingStack.pop();
                    }

                    if (headingStack.length === 0) {
                        nestedHeadings.push(headingData);
                    } else {
                        headingStack[headingStack.length - 1].children.push(headingData);
                    }

                    headingStack.push(headingData);
                });

                return nestedHeadings;
            };

            // Extract headings from the blog content
            const extractedHeadings = extractHeadings(blogData.blogContent);

            setHeadings(extractedHeadings);
            setLoading(false);
        } else {
            setError(new Error("No blog data found."));
            setLoading(false);
        }
    }, [blogData]);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            let mostVisibleHeading = null;
            let highestRatio = 0;

            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    const ratio = entry.intersectionRatio;
                    if (ratio > highestRatio) {
                        highestRatio = ratio;
                        mostVisibleHeading = entry.target;
                    }
                }
            });

            if (mostVisibleHeading) {
                const id = mostVisibleHeading.id;
                setCurrentId(id);
            }
        }, { 
            root: null, // Use the viewport as the root
            rootMargin: '0px 0px -15% 0px', // Trigger when heading is within 25% from the bottom of the viewport
            threshold: [0] // Trigger as soon as any part of the heading intersects
        });

        document.querySelectorAll('h1, h2, h3, h4, h5, h6').forEach(heading => {
            observer.observe(heading);
        });

        return () => {
            observer.disconnect();
        };
    }, [headings]);

    const renderHeadings = (headings) => {
        return (
            <ul>
                {headings.map((heading, index) => (
                    <li key={index}>
                        <a
                            href={`#${heading.id}`}
                            className={`text-black no-underline ${currentId === heading.id ? ' font-bold color-[#007bff]' : ''}`}
                            onClick={(e) => {
                                e.preventDefault();
                                const targetElement = document.getElementById(heading.id);
                                if (targetElement) {
                                    // Adjusting scroll to account for fixed headers
                                    const offsetTop = targetElement.offsetTop;
                                    window.scrollTo({
                                        top: offsetTop - 90, // Adjust the offset if needed
                                        behavior: 'smooth'
                                    });
                                }
                            }}
                        >
                            {heading.text}
                        </a>
                        {heading.children && heading.children.length > 0 && renderHeadings(heading.children)}
                    </li>
                ))}
            </ul>
        );
    };

    return (
        <div className="flex">
            {/* Headings Sidebar Section for xl and greater */}
            <div className="hidden xl:block fixed top-[10%] right-0 w-[25%] overflow-y-auto p-3">
                {loading ? (
                    <p>Loading...</p>
                ) : error ? (
                    <p className="text-red-500">Error loading headings: {error.message}</p>
                ) : (
                    renderHeadings(headings)
                )}
            </div>

            {/* Dropdown for smaller screens */}
            <div className="block xl:hidden w-full">
                <button
                    className="w-full text-lg font-semibold p-2 bg-gray-200 rounded"
                    onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                >
                    On This Page
                </button>
                {isDropdownOpen && (
                    <div className="bg-white border border-gray-300 rounded-lg shadow-lg">
                        {loading ? (
                            <p>Loading...</p>
                        ) : error ? (
                            <p className="text-red-500">Error loading headings: {error.message}</p>
                        ) : (
                            renderHeadings(headings)
                        )}
                    </div>
                )}
            </div>
        </div>

    );
};

export default OnThisPage;
