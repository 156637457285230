import React from 'react';
import Select from 'react-dropdown-select';

const CustomDropDown = ({ options=[], values, placeholder, multi=false, create=false, onChange, onCreate, name, className, style, disabled }) => (
    <>
        <Select
            multi={multi}
            create={create}
            onCreateNew={(value) => onCreate(value)}
            options={options}
            values={values}
            onChange={(value) => onChange(value, name, multi)}
            placeholder={placeholder}
            name={name}
            className={className+" w-100"}
            style={style}
            disabled={disabled}
        />
    </>
);

CustomDropDown.propTypes = {};

export default CustomDropDown;