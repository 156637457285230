export const transformOptions = (options) => {
    const alphabet = 'ABCD';
    return options.map((option, index) => ({
        option: alphabet[index],
        content: option
    }));
}

export const extractOptionContent = (optionsArray)=> {
    return optionsArray.map(option => option.content);
}
