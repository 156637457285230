import React, { useState, useEffect } from "react";
import InfoContext from "./infoContext";
import { editProfile, fetchProfileDetails, fetchNotifications, fetchOffer } from "../services/profile/profileService";
import { useNavigate } from "react-router-dom";

const InfoState = (props) => {
  const [userDetails, setUserDetails] = useState('');
  const [notifications, setNotifications] = useState([]);
  const [stream, setStream] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [token, setToken] = useState('');
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [offers, setOffers] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);

    const token = localStorage.getItem('token');
    setToken(token);
    console.log(token);

    if (token && !userDetails) {
      fetchData();
    } else {
      setLoading(false);
    }

  }, [token]);

  const fetchData = async () => {
    try {
      const response = await fetchProfileDetails();
      console.log(response);
      if (response.status && response.status === 401) {
        console.error(response.message);
        setToken('');
        setIsLoggedIn(false);
        setUserDetails('');
        return;
      } else if (response.status) {
        console.error(response.message);
        alert("Error Occured! Please try again.");
        return;
      }
      setIsLoggedIn(true);
      setUserDetails(response);
      setStream(response.stream);
    } catch (e) {
      console.error('Error Occured, ', e);
    } finally {
      setLoading(false);
    }
  }

  

  const handleLogout = () => {
    setToken('');
    setIsLoggedIn(false);
    setUserDetails('');
    setStream('');
    localStorage.removeItem('token');
    console.log("User Logged out");
    navigate('/');
  };

  const handleChangeStream = (stream) => {
    setLoading(true);
    try {
      const response = editProfile({stream});
      if (response.status && response.status === 401) {
        console.error(response.message);
        return;
      } else if (response.status) {
        console.error(response.message);
        alert("Error Occured! Please try again.");
        return;
      }
      setStream(stream);
      localStorage.setItem('stream', stream)
      setUserDetails({...userDetails, stream});
    } catch (e) {
      console.error('Error Occured, ', e);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    const str = localStorage.getItem('stream');
    str && setStream(str);
  }, [])

  return (
    <InfoContext.Provider value={{ token, setToken, stream, setStream, notifications, setNotifications, userDetails, setUserDetails, handleLogout, handleChangeStream, showLoginModal, setShowLoginModal, offers, setOffers }}>
      {!loading && props.children}
    </InfoContext.Provider>
  )
}

export default InfoState