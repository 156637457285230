import { extractOptionContent } from 'utils/transformOptions';
import { create } from 'zustand';

export const questionStore = create((set) => ({
  language: '',
  questionKey: '',
  questionType: '',
  section: '',
  questionContent: '', 
  comprehension: '',
  options: [],
  correct_options: [],
  answerExplanation: ``,
  marks: 0,
  negMarks: 0,
  isPartialMarks: false,
  subject: '',
  chapter: '',
  chapterGroup: '',
  topic: '',
  levelRating: -1,
  tags: [],
  paperId: '',
  paperTitle: '',
  exam: '',
  year: 0,
  isOutOfSyllabus: false,
  isBonus: false,

  setLanguage: (lang) => set({ language: lang }),
  setQuestionKey: (key) => set({ questionKey: key }),
  setQuestionType: (type) => set({ questionType: type }),
  setSection: (sec) => set({ section: sec }),
  setQuestionContent: (content) => set({ questionContent: content }),
  setComprehension: (comp) => set({ comprehension: comp }),

  setOptions: (opts) => set({ options: opts }),
  setOption: (index, option) => {
    set((state) => {
      const options = [...state.options];
      options[index] = option;
      return { options };
    });
  },
  addOptions: () => {
    set((state) => {
      const options = [...state.options, ''];
      return { options };
    });
  },
  deleteOption: (index) => {
    set((state) => {
      const options = state.options.filter((_, i) => i !== index);
      return { options };
    });
  },

  setCorrectOptions: (correctOpts) => set({ correct_options: correctOpts }),
  setAnswerExplanation: (explanation) => set({ answerExplanation: explanation }),
  setMarks: (m) => set({ marks: m }),
  setNegMarks: (nm) => set({ negMarks: nm }),
  setIsPartialMarks: (isPartial) => set({ isPartialMarks: isPartial }),
  setSubject: (sub) => set({ subject: sub }),
  setChapter: (ch) => set({ chapter: ch }),
  setChapterGroup: (cg) => set({ chapterGroup: cg }),
  setTopic: (t) => set({ topic: t }),
  setLevelRating: (lr) => set({ levelRating: lr }),
  setTags: (tgs) => set({ tags: tgs }),
  setPaperId: (pid) => set({ paperId: pid }),
  setPaperTitle: (pt) => set({ paperTitle: pt }),
  setExam: (e) => set({ exam: e }),
  setYear: (y) => set({ year: y }),
  setIsOutOfSyllabus: (oos) => set({ isOutOfSyllabus: oos }),
  setIsBonus: (bonus) => set({ isBonus: bonus }),

  clearForm: () => set({
    language: 'english',
    questionKey: '',
    questionType: 'mcq',
    section: '',
    questionContent: '',
    comprehension: '',
    options: [
    ],
    correct_options: [
    ],
    answerExplanation: '',
    marks: 4,
    negMarks: 1,
    isPartialMarks: false,
    subject: '',
    chapter: '',
    chapterGroup: '',
    topic: '',
    levelRating: 3,
    tags: [],
    paperId: '',
    paperTitle: '',
    exam: '',
    year: 2024,
    isOutOfSyllabus: false,
    isBonus: false,
  }),

  setDefaultValues: () => set({
    language: 'english',
    questionKey: '',
    questionType: 'mcq',
    section: 'A',
    questionContent: `The plot of $\\log k_f$ versus $1 / T$ for a reversible reaction $\\mathrm{A}(\\mathrm{g}) \\rightleftharpoons \\mathrm{P}(\\mathrm{g})$ is shown.<br><br>\n<img src=\"https://app-content.cdn.examgoal.net/fly/@width/image/1liir07s6/dab28081-bff3-4a0b-b1a3-06d4b0a17ba1/3af77060-0391-11ee-94c1-c5ddbb3cc6a9/file-1liir07s7.png?format=png\" data-orsrc=\"https://app-content.cdn.examgoal.net/image/1liir07s6/dab28081-bff3-4a0b-b1a3-06d4b0a17ba1/3af77060-0391-11ee-94c1-c5ddbb3cc6a9/file-1liir07s7.png\" loading=\"lazy\" style=\"max-width: 100%; height: auto; display: block; margin: 0px auto; max-height: 40vh;\" alt=\"JEE Advanced 2023 Paper 1 Online Chemistry - Chemical Equilibrium Question 1 English\"><br>Pre-exponential factors for the forward and backward reactions are $10^{15} \\mathrm{~s}^{-1}$ and $10^{11} \\mathrm{~s}^{-1}$, respectively. If the value of $\\log K$ for the reaction at $500 \\mathrm{~K}$ is 6 , the value of $\\left|\\log k_b\\right|$ at $250 \\mathrm{~K}$ is ______.<br><br>\n$$\n\\begin{aligned}\n&amp; {[K=\\text { equilibrium constant of the reaction }} \\\\\\\\\n&amp; k_f=\\text { rate constant of forward reaction } \\\\\\\\\n&amp; \\left.k_b=\\text { rate constant of backward reaction }\\right]\n\\end{aligned}\n$$`,
    comprehension: '',
    options: [
      'first option',
      'second option',
      'third option',
      'fourth option',
    ],
    correct_options: [
      'B',
    ],
    answerExplanation: ``,
    marks: 4,
    negMarks: 1,
    isPartialMarks: false,
    subject: 'physics',
    chapter: 'p-block-elements',
    chapterGroup: 'inorganic-chemistry',
    topic: 'group-14-elements-carbon-family',
    levelRating: 3,
    tags: ["jee-mains", "organic-chemistry"],
    paperId: 'jee-main-2023-online-15th-april-morning-shift',
    paperTitle: 'JEE Main 2023 (Online) 15th April Morning Shift',
    exam: 'jee-main',
    year: 2024,
    isOutOfSyllabus: false,
    isBonus: false,
  }),

  setQuestion: (question) => set((state) => ({
    language: question.language !== undefined ? question.language : state.language,
    questionKey: question.questionKey !== undefined ? question.questionKey : state.questionKey,
    questionType: question.questionType !== undefined ? question.questionType : state.questionType,
    section: question.section !== undefined ? question.section : state.section,
    questionContent: question.questionContent !== undefined ? question.questionContent : state.questionContent,
    comprehension: question.comprehension !== undefined ? question.comprehension : state.comprehension,
    options: question.options !== undefined ? extractOptionContent(question.options) : state.options,
    correct_options: question.correct_options !== undefined ? question.correct_options : state.correct_options,
    answerExplanation: question.answerExplanation !== undefined ? question.answerExplanation : state.answerExplanation,
    marks: question.marks !== undefined ? question.marks : state.marks,
    negMarks: question.negMarks !== undefined ? question.negMarks : state.negMarks,
    isPartialMarks: question.isPartialMarks !== undefined ? question.isPartialMarks : state.isPartialMarks,
    subject: question.subject !== undefined ? question.subject : state.subject,
    chapter: question.chapter !== undefined ? question.chapter : state.chapter,
    chapterGroup: question.chapterGroup !== undefined ? question.chapterGroup : state.chapterGroup,
    topic: question.topic !== undefined ? question.topic : state.topic,
    levelRating: question.levelRating !== undefined ? question.levelRating : state.levelRating,
    tags: question.tags !== undefined ? question.tags : state.tags,
    paperId: question.paperId !== undefined ? question.paperId : state.paperId,
    paperTitle: question.paperTitle !== undefined ? question.paperTitle : state.paperTitle,
    exam: question.exam !== undefined ? question.exam : state.exam,
    year: question.year !== undefined ? question.year : state.year,
    isOutOfSyllabus: question.isOutOfSyllabus !== undefined ? question.isOutOfSyllabus : state.isOutOfSyllabus,
    isBonus: question.isBonus !== undefined ? question.isBonus : state.isBonus,
  })),

}));