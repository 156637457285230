import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import OnThisPage from './OnThisPage';
import Content from './Content';

const OpenBlog = () => {
    const location = useLocation();
    const blogData = location.state?.blogData; // Accessing blogData from state

    // Placeholder for blogData, if it doesn't exist
    const blogContent = blogData?.blogContent || "<p>No content available</p>";

    return (
        <div className="flex flex-col justify-between p-4 mt-2 xl:mt-0 lg:mt-4">
            {/* Dropdown for small screens */}
            <div className="xl:hidden top-0 mt-16 left-0 w-full bg-white border border-gray-300 rounded-lg shadow-lg z-50 overflow-y-auto max-h-[80vh]">
                <OnThisPage />
            </div>

            {/* Main Blog Content */}
            <div className="lg:ml-[20%] xl:w-1/2 mt-[5%] xl:mt-0 mx-auto">
                <h1 className="text-2xl lg:text-3xl font-bold mb-4">{blogData?.title || "No Title"}</h1>
                <div className="prose lg:prose-lg" dangerouslySetInnerHTML={{ __html: blogContent }} />
            </div>

            {/* Sidebar Content for large screens */}
            <div className="hidden xl:block lg:w-1/4 mb-8 lg:mb-0 fixed top-[10%] right-0 h-[calc(100vh-10%)] overflow-y-auto z-30">
                <Content />
            </div>

            {/* On This Page Content for large screens */}
            <div className="hidden xl:block lg:w-1/4 fixed top-[10%] right-[20%] h-[calc(100vh-10%)] overflow-y-auto z-40">
                <OnThisPage />
            </div>
        </div>
    );
};

export default OpenBlog;
