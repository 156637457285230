exports.preloginNav = [
  { title: "Jee Mains", pageLink: "/jee-mains" },
  { title: "Jee Advance", pageLink: "/jee-advance" },
  { title: "Neet UG", pageLink: "/neet-ug" },
  { title: "WBJEE", pageLink: "/wbjee" },
  { title: "BITSAT", pageLink: "/bitsat" },
  { title: "VITEEE", pageLink: "/viteee" },
  { title: "COMEDK", pageLink: "/comedk" },
  { title: "MHT-CET", pageLink: "/mht-cet" }
]

exports.engNav = [
  { title: "Home", pageLink: "/engineering/dashboard" },
  { title: "Study", pageLink: "/eng/study" },
  { title: "My Test", pageLink: "/eng/mytest" },
  { title: "Analyse", pageLink: "/analyse" },
  { title: "Blogs", pageLink: "/eng/blogs" },
  { title: "Formula Buddy", pageLink: "/eng/formulas" },
  { title: "Jee Mains", pageLink: "/jee-mains" },
  { title: "Jee Advance", pageLink: "/jee-advance" },
  { title: "WBJEE", pageLink: "/wbjee" },
  { title: "BITSAT", pageLink: "/bitsat" },
  { title: "COMEDK", pageLink: "/comedk" },
  { title: "VITEEE", pageLink: "/viteee" }
]

exports.medNav = [
  { title: "Home", pageLink: "/medical/dashboard" },
  { title: "Study", pageLink: "/med/study" },
  { title: "My Test", pageLink: "/med/mytest" },
  { title: "Analyse", pageLink: "/analyse" },
  { title: "Blogs", pageLink: "/med/blogs" },
  { title: "Formula Buddy", pageLink: "/med/formulas" },
  { title: "Neet UG", pageLink: "/neet-ug" },
  { title: "Physics", pageLink: "/physics" },
  { title: "Chemistry", pageLink: "/chemistry" },
  { title: "Zoology", pageLink: "/zoology" },
  { title: "Botany", pageLink: "/botany" }
]