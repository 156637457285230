import React, { useState } from 'react';
import { createtestStore } from 'components/store/createtestStore';
import ViewQuestionPopUp from '../Popups/ViewQuestionPopUp';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Button,
    TableContainer,
    Paper
} from '@mui/material';
import { Eye, Trash2 } from 'lucide-react';

export default function ViewQues() {
    const { testQuestions, setTestQuestions } = createtestStore(state => state);
    const [viewQuestion, setViewQuestion] = useState(false);
    const [question, setQuestion] = useState(undefined);

    const handleViewQuestion = id => {
        setQuestion(id);
        setViewQuestion(true);
    };

    const handleCloseViewQuestion = () => {
        setViewQuestion(false);
    };

    const handleDelete = id => {
        setTestQuestions(testQuestions.filter(questionId => questionId !== id));
    };

    return (
        <>
            <TableContainer elevation={0} component={Paper} sx={{ border: '1px solid #ddd', borderRadius: 1, overflow: 'auto' }}>
                <Table aria-label="simple table" size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell align='center' >S.No</TableCell>
                            <TableCell align='start' >Question Id</TableCell>
                            <TableCell align='center' >View</TableCell>
                            <TableCell align='center' >Remove</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {testQuestions.map((question, index) => (
                            <TableRow key={question}>
                                <TableCell align='center' >{index + 1}</TableCell>
                                <TableCell align='start' sx={{ padding: 1, width: '80%' }}>{question}</TableCell>
                                <TableCell align='center' >
                                    <Button color='info' onClick={() => handleViewQuestion(question)} >
                                        <Eye size={16} />
                                    </Button>
                                </TableCell>
                                <TableCell align='center' >
                                    <Button color='error' onClick={() => handleDelete(question)}>
                                        <Trash2 size={16} />
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {viewQuestion && (
                <ViewQuestionPopUp
                    isTest={true}
                    handleViewQuestion={handleViewQuestion}
                    handleCloseViewQuestion={handleCloseViewQuestion}
                    setViewQuestion={setViewQuestion}
                    index={question}
                />
            )}
        </>
    );
}
