import { create } from 'zustand';

export const createtestStore = create((set) => ({
  _id: '',
  // Program related
  programName: '',
  orderSequence: null,

  // Test Informations
  instructions: '',
  exam: '',
  syllabus: '',
  testTitle: '',
  testAbr: '',
  testName: '',
  testDescription: '',
  stream: '',

  // Additional Informations based on questions
  testQuestions: [],
  testFormat: '',
  language: '',
  qCount: 0,
  attemptTime: null,
  maxMarks: null,
  levelRating: -1,
  tags: [],
  subjects: [],

  // Admin Informations
  status: 'active',
  verified: false,
  sectionPartition: false,
  resumeEnabled: true,


  setId: (_id) => set({ _id }),
  setProgramName: (programName) => set({ programName }),
  setOrderSequence: (orderSequence) => set({ orderSequence }),
  setInstructions: (instructions) => set({ instructions }),
  setExam: (exam) => set({ exam }),
  setSyllabus: (syllabus) => set({ syllabus }),
  setTestTitle: (testTitle) => set({ testTitle }),
  setTestAbr: (testAbr) => set({ testAbr }),
  setTestName: (testName) => set({ testName }),
  setTestDescription: (testDescription) => set({ testDescription }),
  setStream: (stream) => set({ stream }),
  setTestQuestions: (testQuestions) => set({ testQuestions }),
  setTestFormat: (testFormat) => set({ testFormat }),
  setLanguage: (language) => set({ language }),
  setQCount: (qCount) => set({ qCount }),
  setAttemptTime: (attemptTime) => set({ attemptTime }),
  setMaxMarks: (maxMarks) => set({ maxMarks }),
  setLevelRating: (levelRating) => set({ levelRating }),
  setTags: (tags) => set({ tags }),
  setSubjects: (subjects) => set({ subjects }),
  setStatus: (status) => set({ status }),
  setVerified: (verified) => set({ verified }),
  setSectionPartition: (sectionPartition) => set({ sectionPartition }),
  setResumeEnabled: (resumeEnabled) => set({ resumeEnabled }),

  addSomeValues: () => {
    set({
      programName: 'physics-for-jee-mains',
      orderSequence: 1,
      instructions: `Read all questions carefully before answering.", "The test is of 3 hours duration", "There are three parts in the question paper A. B, C consisting of Physics, Chemistry and Mathematics having 30 questions in each part of equal weightage.", "Each question is allotted A tfourh marks for correct response. Candidates will be awarded marks as stated above in instructior No. 5 for correct responise of each question. 1/4 (one fourth) marks will be deducted for indicating incorrect response ofeach question No deduction from the total score will be miade if no responise is indicated for an item in the ansiwter shet`,
      exam: 'Test Exam',
      syllabus: 'Test Syllabus',
      testTitle: 'Sample Test',
      testAbr: 'Sample Test Abr',
      testName: 'Sample Test',
      testDescription: 'This is a sample test description.',
      stream: 'engineering',
      testQuestions: [],
      testFormat: 'jee-mains-300',
      language: 'english',
      qCount: 0,
      attemptTime: 10800,
      maxMarks: 360,
      levelRating: 4,
      tags: ["Jee mains", "test5"],
      subjects: ["maths", "physics", "chemistry"],
    })
  },

  resetValues: () => {
    set({
      _id: '',
      programName: '',
      orderSequence: '',
      instructions: '',
      exam: '',
      syllabus: '',
      testTitle: '',
      testAbr: '',
      testName: '',
      testDescription: '',
      stream: '',
      testQuestions: [],
      testFormat: '',
      language: '',
      qCount: 0,
      attemptTime: '',
      maxMarks: '',
      levelRating: -1,
      tags: [],
      subjects: [],
      status: 'active',
      verified: false,
      sectionPartition: false,
      resumeEnabled: true,
    })
  },

  setTest: (test) => set((state) => ({
    _id: test._id !== undefined ? test._id : state._id,
    programName: test.programName !== undefined ? test.programName : state.programName,
    orderSequence: test.orderSequence !== undefined ? test.orderSequence : state.orderSequence,
    instructions: test.instructions !== undefined ? test.instructions : state.instructions,
    exam: test.exam !== undefined ? test.exam : state.exam,
    syllabus: test.syllabus !== undefined ? test.syllabus : state.syllabus,
    testTitle: test.testTitle !== undefined ? test.testTitle : state.testTitle,
    testAbr: test.testAbr !== undefined ? test.testAbr : state.testAbr,
    testName: test.testName !== undefined ? test.testName : state.testName,
    testDescription: test.testDescription !== undefined ? test.testDescription : state.testDescription,
    stream: test.stream !== undefined ? test.stream : state.stream,

  //  testQuestions: test.sections ? test.sections.flatMap(section => section.questions) : state.testQuestions,
  testQuestions: test.questions !== undefined ? test.questions.flatMap(question=> question._id) : state.testQuestions,

    testFormat: test.testFormat !== undefined ? test.testFormat : state.testFormat,
    language: test.language !== undefined ? test.language : state.language,
    qCount: test.qCount !== undefined ? test.qCount : state.qCount,
    attemptTime: test.attemptTime !== undefined ? test.attemptTime : state.attemptTime,
    maxMarks: test.maxMarks !== undefined ? test.maxMarks : state.maxMarks,
    levelRating: test.levelRating !== undefined ? test.levelRating : state.levelRating,
    tags: test.tags !== undefined ? test.tags : state.tags,
    subjects: test.subjects !== undefined ? test.subjects : state.subjects,
    status: test.status !== undefined ? test.status : state.status,
    verified: test.verified !== undefined ? test.verified : state.verified,
    sectionPartition: test.sectionPartition !== undefined ? test.sectionPartition : state.sectionPartition,
    resumeEnabled: test.resumeEnabled !== undefined ? test.resumeEnabled : state.resumeEnabled,
  })),

}));
