export   const getIndexFromOption = (option) => {
    if (option.match(/^[A-Za-z]$/)) {
      // For letters
      return option.toUpperCase().charCodeAt(0) - 65;
    } else if (option.match(/^\d+$/)) {
      // For numbers
      return parseInt(option, 10) - 1;
    } else if (option.match(/^[IVXLCDM]+$/i)) {
      // For Roman numerals (both uppercase and lowercase)
      const romanToInt = { I: 1, V: 5, X: 10, L: 50, C: 100, D: 500, M: 1000 };
      let num = 0;
      for (let i = 0; i < option.length; i++) {
        const curr = romanToInt[option[i].toUpperCase()];
        const next = romanToInt[option[i + 1]?.toUpperCase()];
        if (curr < next) {
          num -= curr;
        } else {
          num += curr;
        }
      }
      return num - 1;
    }
    return -1; 
  };
