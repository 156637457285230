import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Button, Menu, MenuItem, Box, Avatar } from '@mui/material';
import logo from 'assets/icons/logo.png';
import icon from 'assets/icons/logo-icon.png';
import ProfilePic from 'components/Common/ProfilePic';

const PostLoginHeader = () => {
  const navigate = useNavigate();
  const [anchorElQuestion, setAnchorElQuestion] = useState(null);
  const [anchorElTest, setAnchorElTest] = useState(null);
  const [anchorElBlog, setAnchorElBlog] = useState(null);

  const handleOpenQuestionMenu = (event) => {
    setAnchorElQuestion(event.currentTarget);
  };

  const handleCloseQuestionMenu = () => {
    setAnchorElQuestion(null);
  };

  const handleOpenTestMenu = (event) => {
    setAnchorElTest(event.currentTarget);
  };

  const handleCloseTestMenu = () => {
    setAnchorElTest(null);
  };

  const handleOpenBlogMenu = (event) => {
    setAnchorElBlog(event.currentTarget);
  };

  const handleCloseBlogMenu = () => {
    setAnchorElBlog(null);
  };

  return (
      <AppBar position="static" style={{ backgroundColor: '#87CEEB', height: '4.5rem' }}>
        <Toolbar>
          <Link to="/">
            <Avatar src={logo} alt='logo' variant="square" sx={{ height: { xs: '1.8rem', sm: '2.5rem', md: '3rem' }, width: 'auto' }} />
          </Link>
          <Typography variant="h6" fontWeight='bold' style={{ flexGrow: 1, textAlign: 'center' }}>
            Admin Panel
          </Typography>
          <Box style={{ marginRight: '1rem' }}>
            <Button aria-controls="question-menu" aria-haspopup="true" onClick={handleOpenQuestionMenu} style={{ marginRight: '1rem' }}>
              Question
            </Button>
            <Menu
                id="question-menu"
                anchorEl={anchorElQuestion}
                keepMounted
                open={Boolean(anchorElQuestion)}
                onClose={handleCloseQuestionMenu}
            >
              <MenuItem onClick={() => {
                handleCloseQuestionMenu();
                navigate("/admin/dashboard/addquestion");
              }}>Add questions</MenuItem>
              <MenuItem onClick={() => {
                handleCloseQuestionMenu();
                navigate("/admin/dashboard/viewquestions");
              }}>View Questions</MenuItem>
            </Menu>
          </Box>
          <Box style={{ marginRight: '1rem' }}>
            <Button aria-controls="test-menu" aria-haspopup="true" onClick={handleOpenTestMenu} style={{ marginRight: '1rem' }}>
              Test
            </Button>
            <Menu
                id="test-menu"
                anchorEl={anchorElTest}
                keepMounted
                open={Boolean(anchorElTest)}
                onClose={handleCloseTestMenu}
            >
              <MenuItem onClick={() => {
                handleCloseTestMenu();
                navigate("/admin/dashboard/createtest");
              }}>Create Test</MenuItem>
              <MenuItem onClick={() => {
                handleCloseTestMenu();
                navigate("/admin/dashboard/viewtest");
              }}>View Test</MenuItem>
            </Menu>
          </Box>
          <Box style={{ marginRight: '1rem' }}>
            <Button aria-controls="blog-menu" aria-haspopup="true" onClick={handleOpenBlogMenu} style={{ marginRight: '1rem' }}>
              Blog
            </Button>
            <Menu
                id="blog-menu"
                anchorEl={anchorElBlog}
                keepMounted
                open={Boolean(anchorElBlog)}
                onClose={handleCloseBlogMenu}
            >
              <MenuItem onClick={() => {
                handleCloseBlogMenu();
                navigate("/admin/dashboard/createblog");
              }}>Add Blog</MenuItem>
              <MenuItem onClick={() => {
                handleCloseBlogMenu();
                navigate("/admin/dashboard/viewblog");
              }}>View Blogs</MenuItem>
            </Menu>
          </Box>
          <Box>
            <ProfilePic profilePic={icon} />
          </Box>
        </Toolbar>
      </AppBar>
  );
};

export default PostLoginHeader;
