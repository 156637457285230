import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import {
  Typography,
  Box,
  CircularProgress,
  Paper, 
  AccordionSummary,
  AccordionDetails,
  Accordion,
  Button,
  Tooltip,
  Grid,
  CardActions,
  CardContent,
  Card,
} from '@mui/material';
import { BadgeCheck,  ChevronDown, Pencil, Trash2 } from 'lucide-react';
import Filters from './components/Filters';
import CustomChip from '../Common/CustomChip';

import { programStore } from 'components/store/viewtestStore';

export default function ViewTest() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  const {
    programs,
    setPrograms,
  } = programStore(state => state);


  useEffect(() => {
    applyFilters();
  }, []);

  const handleEditTest = (test) => {
    navigate('/admin/dashboard/edittest/' + test._id);
  };

  const handleDeleteTest = async (id) => {
    try {
      const token = JSON.parse(localStorage.getItem("token"));
      const response = await axios.post("https://api.testbuddy.live/v1/admin/test/delete",
        { testId: id },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          }
        }
      );
      if (response.status === 200) {
        console.log(response);
        applyFilters();
      } else {
        console.log("some error occurred");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleVerifyTest = async (id, testFormat) => {
    try {
      const token = JSON.parse(localStorage.getItem("token"));
      const response = await axios.post("https://api.testbuddy.live/v1/admin/test/verify",
        { testId: id, verifyTestFormat: testFormat },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          }
        }
      );
      if (response.status === 200) {
        console.log(response);
        applyFilters();
      } else {
        console.log("some error occurred");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const applyFilters = async (testName,
    testFormat,
    tags,
    levelRating,
    stream,
    subjects,
    status,
    verified,
    programName,
    orderSequence,
    instructions,
    exam,
    testTitle,
    resumeEnabled,
    sectionPartition,
    maxMarks) => {
    setLoading(true);

    const filterObject = Object.fromEntries(
      Object.entries({
        testName,
        testFormat,
        tags,
        levelRating,
        stream,
        subjects,
        status,
        verified,
        programName,
        orderSequence,
        instructions,
        exam,
        testTitle,
        resumeEnabled,
        sectionPartition,
        maxMarks
      }).filter(([key, value]) => {
        if (typeof value === 'boolean') return value; // include boolean values
        if (Array.isArray(value)) {
          return value.length > 0;
        }
        return value !== undefined && value !== null && value !== '' && value !== 0;
      })
    );

    try {
      const token = JSON.parse(localStorage.getItem("token"));
      const response = await axios.post("https://api.testbuddy.live/v1/admin/tests",
        { filter: filterObject },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          }
        }
      );
      const data = response.data;
      console.log("data: ", data);

      setPrograms(data);

    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 2, width: '100%', position: 'relative' }}>
      <Paper elevation={0} sx={{ border: '1px solid #C4C4C4' }}>
        <Filters applyFilters={applyFilters} />
      </Paper>
      <Box sx={{ minHeight: '60vh', position: 'relative', width: '100%' }}>
        {loading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 2, p: 2 }}>
            {programs.map((program, index) => {
              const {
                programName,
                programTitle,
                stream,
                exam,
                programDescription,
                tests,
                testsOrderSequenceAscending,

              } = program;

              return (
                <Accordion elevation={0} sx={{ width: '100%', border: '1px solid #ccc', borderRadius: 2, p: 1 }} key={index}>
                  <AccordionSummary
                    expandIcon={<ChevronDown />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Box sx={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', alignItems: 'center', gap: 2, }}>
                      <Typography variant="h6">
                        {programTitle}
                      </Typography>
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', gap: 2, px: 2 }}>
                        <Typography variant="body2">
                          <strong>Stream:</strong> {stream}
                        </Typography>
                        <Typography variant="body2">
                          <strong>Exam:</strong> {exam}
                        </Typography>
                        <Typography variant="body2">
                          <strong>Description:</strong> {programDescription}
                        </Typography>
                      </Box>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails sx={{ p: 1 }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                      <Grid container spacing={2}>
                        {tests && tests
                          .sort((a, b) => {
                            return testsOrderSequenceAscending ? (a.orderSequence - b.orderSequence) : (b.orderSequence - a.orderSequence)
                          })
                          .map((test, ind) => {
                            const {
                              _id,
                              testName,
                              testFormat,
                              tags,
                              levelRating,
                              stream,
                              subjects,
                              status,
                              verified,
                              orderSequence,
                              instructions,
                              exam,
                              testTitle,
                              resumeEnabled,
                              sectionPartition,
                              maxMarks,
                            } = test;

                            return (
                              <Grid item key={ind} xs={12} sm={6} md={4} lg={3}>
                                <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column', borderRadius: 2, p:2 }}>
                                  <CardContent>
                                    <Typography variant="h6">{testTitle}</Typography>
                                    <Typography variant="body2" color="text.secondary">
                                      <strong>Test Name:</strong> {testName}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                      <strong>Format:</strong> {testFormat}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                      <strong>Tags:</strong> {tags.map((tag, index) => (
                                        <CustomChip key={index} label={tag} size="small" />
                                      ))}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                      <strong>Level:</strong> {levelRating}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                      <strong>Stream:</strong> {stream}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                      <strong>Subjects:</strong> {subjects.map((subject, index) => (
                                        <CustomChip key={index} label={subject} size="small" />
                                      ))}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                      <strong>Status:</strong> {status}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                      <strong>Order Seq.:</strong> {orderSequence}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                      <strong>Exam:</strong> {exam}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                      <strong>Resume:</strong> {resumeEnabled ? 'Yes' : 'No'}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                      <strong>Section Partition:</strong> {sectionPartition ? 'Yes' : 'No'}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                      <strong>Max Marks:</strong> {maxMarks}
                                    </Typography>
                                  </CardContent>
                                  <CardActions sx={{display:'flex', justifyContent:'end'}}>
                                    {verified ? (
                                      <Tooltip title="Verified" arrow>
                                        <Button color="success">
                                          <BadgeCheck size={20} />
                                        </Button>
                                      </Tooltip>
                                    ) : (
                                      <Button color="primary" onClick={() => handleVerifyTest(_id, testFormat)}>
                                        Verify
                                      </Button>
                                    )}
                                    <Button color="primary" onClick={() => handleEditTest(test)}>
                                      <Pencil size={20} />
                                    </Button>
                                    <Button color="error" onClick={() => handleDeleteTest(test._id)}>
                                      <Trash2 size={20} />
                                    </Button>
                                  </CardActions>
                                </Card>
                              </Grid>
                            );
                          })}
                      </Grid>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </Box>
        )}
      </Box>
    </Paper>
  );
}