import React from 'react';
import { Box, Container } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { Header } from 'components/Header/Header';
import Footer from 'components/Footer/Footer';

const AdminDashboardLayout = () => {
  return (
    <Box sx={{
      display: 'flex', flexDirection: 'column', minHeight: '100vh', width: '100%',
    }}>
      <Header />
      <Container maxWidth={false}
        component="main"
        disableGutters
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
          minHeight: '50vh',
        }}
      >
        <Outlet />
      </Container>
      <Footer />
    </Box>
  );
};

export default AdminDashboardLayout;