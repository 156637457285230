import { questionStore } from 'components/store/questionStore';
import { createtestStore } from 'components/store/createtestStore';
import { blogStore } from 'components/store/blogStore';

const storeMap = {
  questionStore: questionStore,
  createtestStore: createtestStore,
  blogStore: blogStore,
  // add more stores here
};

const storeValues = (tab, store) => {
  switch (store) {
    case questionStore:
      switch (tab) {
        case 'question':
          return 'questionContent';
        case 'options':
          return 'options';
        case 'explanation':
          return 'answerExplanation';
        case 'comprehension':
          return 'comprehension';
        case 'language':
          return 'language';
        case 'questionKey':
          return 'questionKey';
        case 'questionType':
          return 'questionType';
        case 'section':
          return 'section';
        case 'marks':
          return 'marks';
        case 'negMarks':
          return 'negMarks';
        case 'isPartialMarks':
          return 'isPartialMarks';
        case 'subject':
          return 'subject';
        case 'chapter':
          return 'chapter';
        case 'chapterGroup':
          return 'chapterGroup';
        case 'topic':
          return 'topic';
        case 'levelRating':
          return 'levelRating';
        case 'tags':
          return 'tags';
        case 'paperId':
          return 'paperId';
        case 'paperTitle':
          return 'paperTitle';
        case 'exam':
          return 'exam';
        case 'year':
          return 'year';
        case 'isOutOfSyllabus':
          return 'isOutOfSyllabus';
        case 'isBonus':
          return 'isBonus';
        default:
          return 'questionContent';
      }
    case createtestStore:
      switch (tab) {
        case 'instructions':
          return 'instructions';
        case 'syllabus':
          return 'syllabus';
        case 'programName':
          return 'programName';
        case 'orderSequence':
          return 'orderSequence';
        case 'exam':
          return 'exam';
        case 'testTitle':
          return 'testTitle';
        case 'testAbr':
          return 'testAbr';
        case 'testName':
          return 'testName';
        case 'testDescription':
          return 'testDescription';
        case 'stream':
          return 'stream';
        case 'testQuestions':
          return 'testQuestions';
        case 'testFormat':
          return 'testFormat';
        case 'language':
          return 'language';
        case 'qCount':
          return 'qCount';
        case 'attemptTime':
          return 'attemptTime';
        case 'maxMarks':
          return 'maxMarks';
        case 'levelRating':
          return 'levelRating';
        case 'tags':
          return 'tags';
        case 'subjects':
          return 'subjects';
        case 'status':
          return 'status';
        case 'verified':
          return 'verified';
        case 'sectionPartition':
          return 'sectionPartition';
        case 'resumeEnabled':
          return 'resumeEnabled';
        default:
          return 'instructions';
      }
    case blogStore:
      switch (tab) {
        case 'title':
          return 'title';
        case 'description':
          return 'description';
        case 'coverImage':
          return 'coverImage';
        case 'blogContent':
          return 'blogContent';
        case 'tags':
          return 'tags';
        case 'category':
          return 'category';
        case 'readTime':
          return 'readTime';
        case 'subject':
          return 'subject';
        case 'chapter':
          return 'chapter';
        default:
          return 'title';
      }
    default:
      return '';
  }
}

export const getContent = (storename, tab) => {
  const store = storeMap[`${storename}Store`];

  if (!store) {
    throw new Error(`Store not found: ${storename}Store`);
  }

  return store(state => {
    const [field, index] = tab.split(' ');

    if (field === 'options' && !isNaN(index)) {
      return state.options[parseInt(index)];
    }
    return state[storeValues(tab, store)];
  });
};

export const getSetContent = (storename, tab) => {
  const store = storeMap[`${storename}Store`];

  if (!store) {
    throw new Error(`Store not found: ${storename}Store`);
  }

  return store(state => {
    const [field, index] = tab.split(' ');

    if (field === 'options' && !isNaN(index)) {
      return (value) => state.setOption(parseInt(index), value);
    }
    return state[`set${storeValues(tab, store).charAt(0).toUpperCase()}${storeValues(tab, store).slice(1)}`];
  });
};
