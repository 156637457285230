import React, { useEffect, useState, Fragment } from 'react';
import SingleQuestion from './components/SingleQuestion';
import { Pagination, Box, Typography, Table, TableBody, TableCell, TableHead, TableRow, Paper, TableContainer, Checkbox, CircularProgress } from '@mui/material';
import { usePagination } from '../hooks/Pagination';
import Selectors from './components/Selectors';
import { createtestStore } from 'components/store/createtestStore';
import { useNavigate } from 'react-router-dom';


const ViewQuestion = ({ isTest = false, setAddQuestions }) => {
  const navigate = useNavigate();

  const store = createtestStore();


  const [questions, setQuestions] = useState([]);
  const { totalPages, startPageIndex, endPageIndex, displayPage } = usePagination(100, questions.length);
  const [selectedQues, setSelectedQues] = useState([]);

  const [allSelected, setAllSelected] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (isTest) {
      setSelectedQues([...store.testQuestions]);
    }
    fetchFilteredQuestions();
  }, []);

  const handleAllSelect = () => {
    if (allSelected) {
      setSelectedQues([]);
      setAllSelected(false);
    } else {
      setSelectedQues(questions.map(ques => ques._id));
      setAllSelected(true);
    }
  };

  const handleSelectQuestion = (quesId, flag, verified) => {
    if (verified) {
      if (!flag) {
        if (selectedQues.includes(quesId)) {
          console.log("Question already exists");
        } else {
          setSelectedQues([...selectedQues, quesId]);
        }
      } else {
        setSelectedQues(selectedQues.filter(id => id !== quesId));
      }

    } else {
      console.log("Question not verified");
    }
  };

  const handleAddQues = () => {
    store.setTestQuestions(selectedQues);
    setAddQuestions(false);
  };

  const handleCreateTest = () => {
    store.setTestQuestions(selectedQues);
    navigate('/admin/dashboard/createtest');
  };

  async function fetchFilteredQuestions(
    questionKey,
    comprehension,
    paperTitle,
    language,
    exam,
    examGroup,
    year,
    subject,
    chapter,
    chapterGroup,
    tags,
    questionType,
    levelRating,
    topic,
    status,
    section,
    verified,
    completed
  ) {
    setLoading(true);
    try {
      const filterObject = Object.fromEntries(
        Object.entries({
          questionKey,
          comprehension,
          paperTitle,
          language,
          exam,
          examGroup,
          year,
          subject,
          chapter,
          chapterGroup,
          tags,
          questionType,
          levelRating,
          topic,
          status,
          section,
          verified,
          completed
        }).filter(([key, value]) => {
          if (Array.isArray(value)) {
            return value.length > 0;
          }
          return value !== undefined && value !== null && value !== '' && value !== 0;
        })
      );

      const response = await fetch(
        "https://api.testbuddy.live/v1/admin/questions",
        {
          method: "POST",
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
          },
          body: JSON.stringify({ filter: filterObject }),
        }
      );

      const data = await response.json();
      // console.log(data);
      setQuestions(data);
    } catch (error) {
      console.error('Error fetching filtered questions:', error);
    } finally{
      setLoading(false);
    }
  }

  function deleteFromArray() {
    fetchFilteredQuestions();
  }

  return (
    <Box sx={{ bgcolor: 'background.paper', display: 'flex', flexDirection: 'column', flexGrow: 1, p: 2, gap: 2 }}>
      <Paper elevation={0} sx={{ border: '1px solid #C4C4C4' }}>
        <Selectors
          fetchFilteredQuestions={fetchFilteredQuestions}
          isTest={isTest}
          handleAddQues={handleAddQues}
          handleCreateTest={handleCreateTest}
        />
      </Paper>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="body1">
              <strong>Selected Questions:</strong> {selectedQues.length}
            </Typography>
            <Typography variant="body1">
              <strong>Total Fetched Questions:</strong> {questions.length}
            </Typography>
          </Box>
          <TableContainer component={Paper} sx={{ border: '1px solid #ddd', borderRadius: 2, overflowX: 'hidden' }}>
            <Table size="small" sx={{ tableLayout: 'fixed', width: '100%' }}>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox" sx={{ width: '5%' }}>
                    <Checkbox
                      checked={allSelected}
                      onChange={handleAllSelect}
                      size="small"
                    />
                  </TableCell>
                  <TableCell sx={{ width: '10%' }}>Id</TableCell>
                  <TableCell sx={{ width: '10%' }}>Key</TableCell>
                  <TableCell sx={{ width: '50%' }}>Question</TableCell>
                  <TableCell sx={{ width: '7.5%' }}>Verify</TableCell>
                  <TableCell sx={{ width: '7.5%' }}>Delete</TableCell>
                  <TableCell sx={{ width: '7.5%' }}>Update</TableCell>
                  <TableCell sx={{ width: '7.5%' }}>View</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {questions.slice(startPageIndex, endPageIndex + 1).map((question) => (
                  <SingleQuestion
                    key={question._id}
                    Question={question}
                    index={question._id}
                    deleteFromArray={deleteFromArray}
                    handleSelectQuestion={handleSelectQuestion}
                    selected={selectedQues.includes(question._id)}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
            <Pagination
              count={totalPages}
              onChange={(event, value) => displayPage(value)}
              color="primary"
              sx={{ border: '1px solid #DDDDDD', borderRadius: 1, p: 1 }}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default ViewQuestion;