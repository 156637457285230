import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Box } from '@mui/material';

export default function DeleteBlog({ showModal, handleCloseModal, setShowDeleteModal }) {
    return (
        <Dialog open={showModal} onClose={() => setShowDeleteModal(false)}>
            <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <DialogTitle sx={{ fontWeight: 'bold', color: 'error.main' }}>
                    Delete Blog
                </DialogTitle>
                <DialogContent>
                    <Typography variant="body1" align="center">
                        Are you sure you want to delete this blog?
                    </Typography>
                </DialogContent>
                <DialogActions sx={{ width: '100%', justifyContent: 'space-between' }}>
                    <Button 
                        variant="outlined" 
                        color="success" 
                        onClick={() => setShowDeleteModal(false)}
                        sx={{ flex: 1, mr: 1 }}
                    >
                        No
                    </Button>
                    <Button 
                        variant="contained" 
                        color="error" 
                        onClick={handleCloseModal}
                        sx={{ flex: 1, ml: 1 }}
                    >
                        Yes
                    </Button>
                </DialogActions>
            </Box>
        </Dialog>
    );
}