import { create } from 'zustand';

export const filterStore = create((set) => ({
    questionKey: '',
    comprehension: null,
    paperTitle: '',
    language: '',
    exam: '',
    examGroup: '',
    year: null,
    subject: '',
    chapter: '',
    chapterGroup: '',
    tags: Array(0),
    questionType: '',
    levelRating: 0,
    topic: '',
    status: '',
    section: '',
    verified: null,
    completed: null,

    setQuestionKey: (questionKey) => set({ questionKey }),
    setPaperTitle: (paperTitle) => set({ paperTitle }),
    setLanguage: (language) => set({ language }),
    setExam: (exam) => set({ exam }),
    setExamGroup: (examGroup) => set({ examGroup }),
    setYear: (year) => set({ year }),
    setSubject: (subject) => set({ subject }),
    setChapter: (chapter) => set({ chapter }),
    setChapterGroup: (chapterGroup) => set({ chapterGroup }),
    setTags: (tags) => set({ tags }),
    setQuestionType: (questionType) => set({ questionType }),
    setLevelRating: (levelRating) => set({ levelRating }),
    setTopic: (topic) => set({ topic }),
    setStatus: (status) => set({ status }),
    setSection: (section) => set({ section }),
    setVerified: (verified) => set({ verified }),
    setCompleted: (completed) => set({ completed }),
    setComprehension: (comprehension) => set({ comprehension }),

    resetFilters: () => set({
        questionKey: '',
        comprehension: null,
        paperTitle: '',
        language: '',
        exam: '',
        examGroup: '',
        year: null,
        subject: '',
        chapter: '',
        chapterGroup: '',
        tags: Array(0),
        questionType: '',
        levelRating: null,
        topic: '',
        status: '',
        section: '',
        verified: null,
        completed: null,
    }),

}));