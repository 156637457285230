import React, { useState, useEffect } from 'react';
import SingleBlog from './SingleBlog';
import { Pagination, Box, Typography, CircularProgress, TableBody, TableCell, TableHead, TableRow, TableContainer, Table, Checkbox, Paper } from '@mui/material';
import { usePagination } from '../hooks/Pagination';
import { getFilteredBlogs } from '../../../../services/blog/blogServices';
import { blogStore } from 'components/store/blogStore';
import { useNavigate } from 'react-router-dom';

const ViewBlog = () => {
    const navigate = useNavigate();
    const store = blogStore();
    const [blogs, setBlogs] = useState([]);
    const { totalPages, startPageIndex, endPageIndex, displayPage } = usePagination(100, blogs.length);
    const [selectedBlog, setSelectedBlog] = useState([]);
    const [allSelected, setAllSelected] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);


    const handleAllSelect = () => {
        if (allSelected) {
        setSelectedBlog([]);
        setAllSelected(false);
        } else {
        setSelectedBlog(blogs.map(blog => blog._id));
        setAllSelected(true);
        }
    };

    const handleSelectBlog = (blogId, flag, verified) => {
        if (verified) {
        if (!flag) {
            if (selectedBlog.includes(blogId)) {
            console.log("Blog already exists");
            } else {
            setSelectedBlog([...selectedBlog, blogId]);
            }
        } else {
            setSelectedBlog(selectedBlog.filter(id => id !== blogId));
        }
        } else {
        console.log("Blog not verified");
        }
    };

    async function fetchFilteredBlogs(
        blogType,
        title,
        blogContent,
        category,
        readTime,
        subject,
        chapter,
        status,
        noOfLikes,
        noOfViews,
    ) {
    setLoading(true);
    try {
    const filterObject = Object.fromEntries(
    Object.entries({
        blogType,
        title,
        blogContent,
        category,
        readTime,
        subject,
        chapter,
        status,
        noOfLikes,
        noOfViews,
    }).filter(([key, value]) => {
        if (Array.isArray(value)) {
        return value.length > 0;
        }
        return value !== undefined && value !== null && value !== '' && value !== 0;
    })
    );

    const response = await fetch(
    "https://api.testbuddy.live/v1/admin/blogs",
    {
        method: "POST",
        headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
        },
        body: JSON.stringify({ filter: filterObject }),
    }
    );

    const data = await response.json();
    setBlogs(data);
    } catch (error) {
      console.error('Error fetching filtered blogs:', error);
    } finally {
      setLoading(false);
    }
  }

  function deleteFromArray() {
    fetchFilteredBlogs();
  }


    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                const response = await getFilteredBlogs();
                if (response.error) {
                    throw new Error(response.error);
                }
                setBlogs(response);
            } catch (error) {
                setError(error.message);
                console.error('Error fetching blogs:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchBlogs();
    }, []);

    if (loading) {
        return <CircularProgress />;
    }

    if (error) {
        return <Typography variant="body1" color="error">Error: {error}</Typography>;
    }

    if (!blogs.length) {
        return <Typography variant="body1">No blogs available.</Typography>;
    }

    return (
        <Box sx={{ bgcolor: 'background.paper', display: 'flex', flexDirection: 'column', flexGrow: 1, p: 2, gap: 2 }}>
            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress />
                </Box>
            ) : (
            <>
            <TableContainer component={Paper} sx={{ border: '1px solid #ddd', borderRadius: 2, overflowX: 'hidden' }}>
                <Table size="small" sx={{ tableLayout: 'fixed', width: '100%' }}>
                <TableHead>
                    <TableRow>
                    <TableCell padding="checkbox" sx={{ width: '5%' }}>
                        <Checkbox
                        checked={allSelected}
                        onChange={handleAllSelect}
                        size="small"
                        />
                    </TableCell>
                    <TableCell sx={{ width: '10%' }}>Id</TableCell>
                    <TableCell sx={{ width: '10%' }}>BlogType</TableCell>
                    <TableCell sx={{ width: '50%' }}>Blog</TableCell>
                    <TableCell sx={{ width: '7.5%' }}>Verify</TableCell>
                    <TableCell sx={{ width: '7.5%' }}>Delete</TableCell>
                    <TableCell sx={{ width: '7.5%' }}>Update</TableCell>
                    <TableCell sx={{ width: '7.5%' }}>View</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {blogs.slice(startPageIndex, endPageIndex + 1).map((blog) => (
                    <SingleBlog
                        key={blog._id}
                        Blog={blog}
                        index={blog._id}
                        deleteFromArray={deleteFromArray}
                        handleSelectBlog={handleSelectBlog}
                        selected={selectedBlog.includes(blog._id)}
                    />
                    ))}
                </TableBody>
                </Table>
            </TableContainer>

            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                <Pagination
                count={totalPages}
                onChange={(event, value) => displayPage(value)}
                color="primary"
                sx={{ border: '1px solid #DDDDDD', borderRadius: 1, p: 1 }}
                />
            </Box>
        </>
      )}
    </Box>
    );
};

export default ViewBlog;
