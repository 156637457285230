import React, { useState, useEffect, useRef } from 'react';
import { EditorState } from 'draft-js';

import { convertToRaw } from 'draft-js';
import CustomDropDown from '../Admin/AdminComponents/Common/CustomDropdown';
import TextField from '@mui/material/TextField';
import { toast, ToastContainer } from 'react-toastify';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import './EditorPage.css'
import { createBlogAdmin, getBlogAdmin, updateBlogAdmin} from '../../services/blog/blogServices';
import { useParams } from 'react-router';
import ImageUploader from 'components/Admin/AdminComponents/Common/ImageUpload';
import { Box} from '@mui/material';
import MathJax from 'react-mathjax';
import CustomJoditEditor from './CustomJoditEditor';

const Options = {
    position: "bottom-right",
    autoClose: 10000,
    pauseOnHover: true,
    draggable: false,
    theme: 'dark'
};

const EditorPage = () => {
    const [content, setContent] = useState('');
    const [ editorState, setEditorState ] = useState(
        () => EditorState.createEmpty(),
    );
    const [ convertedContent, setConvertedContent ] = useState(null);
    const [ copySuccess, setCopySuccess ] = useState(false);
    const [ blogId, setBlogId ] = useState('');
    const imageUrlRef = useRef(null);
    const [ blogData, setBlogData ] = useState({ title: '', blogContent: '',readTime:'', subject:'', chapter:'', tags: [], category: 'engineering' }); 
    const [ coverPic, setCoverPic ] = useState(null);
    const [ tagsData, setTagsData ] = useState([
        { value: 'javascript', label: 'JavaScript' },
        { value: 'react', label: 'React' },
        { value: 'webdev', label: 'Web Development' },
        { value: 'frontend', label: 'Frontend' }
    ]);
    const [ tagsValue, setTagsValue ] = useState([]);
    const [ loading, setLoading ] = useState(true);
    const { id } = useParams();
    const [blogJson, setBlogJson] = useState({ blocks: [], entityMap: [] });
    const blogRef = useRef();

    useEffect(() => {
        if(id && window.location.pathname === `/admin/updateblog/${id}`) {
            getBlogData(id);
        } else {
            setLoading(false);
        }
    }, [id])

    const handleEditorChange = (newContent) => {
        setContent(newContent);
    };

    useEffect(() => {
        let data = convertToRaw(editorState.getCurrentContent());
        setConvertedContent(data);
    }, [editorState]);

    const handleState = (data, name, multi) => {
        if (!multi) {
            if (data.length === 0) {
                setBlogData((prevValue) => ({ ...prevValue, [name]: '' }));
                return;
            };
            const { value } = data[0];
            setBlogData((prevValue) => ({ ...prevValue, [name]: value }));
        } else {
            const values = data.map((val) => val.value);
            setBlogData((prevValue) => ({ ...prevValue, [name]: values }));
        }
    }

    function handleTagCustomOptions(data) {
        setTagsData([...tagsData, data]);
    }

    function copyToClipboard(e) {
        navigator.clipboard.writeText(imageUrlRef.current.value);
        setCopySuccess(true);
    };

    const handleSave = (e) => {
        e.preventDefault();

        console.log(convertedContent);
        let temp = convertedContent;
        let entityMap = Object.values(temp.entityMap).map((val) => (val));
        entityMap = entityMap.map((entity) => {
            if ( entity.type === 'IMAGE' && (entity.data.alignment === 'none' || !entity.data.alignment) ){
                return { ...entity, data: { ...entity.data, alignment: 'center' }};
            }
            return entity;
        })
        temp = {...temp, entityMap, blogId };
        handleUpdateBlog(temp);
    }

    const handleCoverPic = () => {
        setCoverPic(true);
    }

    const validateBlogData = () => {
        let temp = [];

        if (blogData.title === "") {
            temp = [...temp, 'title'];
        }
        if (blogData.blogContent === "") {
            temp = [...temp, 'blogContent'];
        }
        if (blogData.readTime === "") {
            temp = [...temp, 'readTime'];
        }
        if (blogData.subject === "") {
            temp = [...temp, 'subject'];
        }
        if (blogData.chapter === "") {
            temp = [...temp, 'chapter'];
        }
        if (blogData.tags.length === 0) {
            temp = [...temp, 'tags'];
        }
        if (temp.length === 0) return true;
        console.log(temp);
        return false;
    }

    const getBlogData = async (id) => {
        try {
            const response = await getBlogAdmin(id);
            console.log(response);
            if (response.error) {
                throw Error(response.error);
            }

            let objTemp = [...response.entityMap];
            const obj = new Object();
            objTemp.forEach((entity, i) => {
                obj[i] = entity;
            })

            const temp = response.tags.map((tag) => ({value: tag, label: tag}));
            setBlogId(response._id);
            setBlogData({title: response.title, blogContent: response.blogContent,readTime: response.readTime,subject: response.subject,chapter: response.chapter, tags: [...response.tags], category: response.category});
            setTagsValue([...tagsValue, ...temp]);
            setTagsData([...tagsData, ...temp]);
            setBlogJson({blocks: response.blocks, entityMap: objTemp})
            
            scrollToBottom();
        } catch (e) {
            console.error('Error Occured: ', e);
            alert('Error Occured! Please try again.')
        } finally {
            setLoading(false);
        }
    }

    const handleCreateBlog = async (e) => {
        e.preventDefault();
        console.log(blogData);
        try {
            if (validateBlogData()) {
                const response = await createBlogAdmin(blogData);
                console.log(response);

                if (response.error) {
                    throw Error(response.error);
                }
                setBlogId(response._id);
                scrollToBottom();
            } else {
                toast.error("enter all the required fields", Options);
            }
        } catch (e) {
            console.error('Error Occured: ', e);
            alert('Error Occured! Please try again.')
        } 
    }

    const handleUpdateBlog = async (data) => {
        try {
            if (validateBlogData()) {
                const response = await updateBlogAdmin(data);
                console.log(response);

                if (response.error) {
                    throw Error(response.error);
                }
                setBlogId(response._id);
                scrollToBottom();
            } else {
                toast.error("enter all the required fields", Options);
            }
        } catch (e) {
            console.error('Error Occured: ', e);
            alert('Error Occured! Please try again.')
        } 
    }

    const scrollToBottom = () => {
        if (!blogRef.current) return;
        blogRef.current.scrollIntoView({ behavior: "smooth" });
    } 

    return ( !loading &&
        <div className="App d-flex flex-column gap-2">
            <header className="header px-3 d-flex flex-column align-items-center justify-content-center fs-3 sticky-top" style={{height:'10vh', backgroundColor:'#282c34', color:'white'}}>
                Blog Editor
            </header>
            
            <div className=' flex'>
                
                <Box sx={{ display: 'flex', flexGrow: 1, height: '80vh', p: 2, gap: 2, width:'50%' }}>
                    <MathJax.Provider>
                        <Box sx={{
                            flex: 1, overflow: 'auto', display: 'flex', flexDirection: 'column', gap: 2, border: '1px solid #ccc', borderRadius: 2, p: 4,
                            // Hide scrollbar for Chrome, Safari, and Opera
                            '&::-webkit-scrollbar': {
                            display: 'none',
                            },
                            // Hide scrollbar for IE, Edge, and Firefox
                            '-ms-overflow-style': 'none', // IE and Edge
                            'scrollbar-width': 'none', // Firefox
                        }}>
                            <h3 className=' mt-[-1rem] mr-[45rem]'>Output</h3>
                            <div dangerouslySetInnerHTML={{ __html: content }} />
                        </Box>
                    </MathJax.Provider>   
                </Box>
                
                <div className=' flex flex-col w-[50%]'>
                    <div className='container pt-2'>
                        <div className='d-flex flex-row column-gap-4 row-gap-2 flex-wrap justify-content-around align-items-end'>
                            <TextField
                                name="title"
                                value={blogData.title}
                                onChange={(e) => { setBlogData({ ...blogData, 'title': e.target.value }) }}
                                label="Title" variant="standard"
                            />

                            <TextField
                                name="blogContent"
                                value={blogData.blogContent}
                                onChange={(e) => { setBlogData({ ...blogData, 'blogContent': e.target.value }) }}
                                label="Blog Content" variant="standard"
                            />

                            <CustomDropDown
                                options={tagsData}
                                create={true}
                                placeholder='Tags'
                                onChange={handleState}
                                name='tags'
                                multi={true}
                                onCreate={handleTagCustomOptions}
                                values={blogData.tags.length ? tagsValue : []}
                                style={{maxWidth:'240px', minWidth:'210px', backgroundColor:'white'}}
                            />

                            <CustomDropDown
                                options={[
                                    {value: 'engineering', label: 'Engineering'},
                                    {value: 'medical', label: 'Medical'},
                                ]}
                                placeholder='Category'
                                onChange={handleState}
                                name='category'
                                values={(blogData.category === 'medical') ? [{ value: 'medical', label: 'Medical' }] : [{ value: 'engineering', label: 'Engineering' }]}
                                style={{maxWidth:'240px', minWidth:'210px', backgroundColor:'white'}}
                            />

                            <TextField
                                name="readTime"
                                value={blogData.readTime}
                                onChange={(e) => { setBlogData({ ...blogData, 'readTime': e.target.value }) }}
                                label="Reading Time" variant="standard"
                            />

                            <TextField
                                name="subject"
                                value={blogData.subject}
                                onChange={(e) => { setBlogData({ ...blogData, 'subject': e.target.value }) }}
                                label="Subject" variant="standard"
                            />

                            <TextField
                                name="chapter"
                                value={blogData.chapter}
                                onChange={(e) => { setBlogData({ ...blogData, 'chapter': e.target.value }) }}
                                label="Chapter" variant="standard"
                            />

                            {/* <div className='d-flex flex-row gap-2 align-items-center'>
                                <div className='d-flex flex-column ' style={{height:'36px', width:'210px'}}>
                                    <label htmlFor='coverPic' className='w-100 h-100'>
                                        <div className='w-100 h-100 d-flex align-items-center justify-content-center' style={{ backgroundColor: 'white', color: '#0964b0', border:'1px #c4c1c0 solid'}} >
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" style={{width:'18px', height:'18px'}}>
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5" />
                                            </svg>
                                            <span className='ps-2'>Cover Picture</span>
                                        </div>
                                    </label>
                                    <input type="file" id="coverPic" name='coverPic' accept="image/*" style={{ height:'0', objectFit: 'contain', opacity:'0'}} onChange={handleCoverPic}/>
                                </div>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" style={{ width: '24px', height: '24px', opacity: (coverPic ? '1' : '0') }}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            </div> */}
                        </div>
                    </div>
                    
                    <div style={{minHeight:'90vh'}} ref={blogRef}>
                        <div className=' ml-8 mr-7 mt-11'>
                            <ImageUploader/>
                        </div> 
                        <div className=' mt-8 ml-8 mr-7'>
                            <CustomJoditEditor initialValue={content} onChange={handleEditorChange} />
                        </div>

                        <br/>
                        <div className='pt-3 mb-7'>
                            <button className="btn btn-success" style={{ height: '42', width: '180px', borderRadius: '0', border: 'none', color: 'white', fontSize: '14px' }} onClick={blogId ? () => handleUpdateBlog(blogData) : handleCreateBlog }>{blogId ? 'Update':'Create'}</button>
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </div>
        </div>
    )
};
const StringFromIndex = (index) => {
    return String.fromCharCode(65 + index);
}

export default EditorPage;