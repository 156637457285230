import { create } from 'zustand';

export const viewtestFilterStore = create((set) => ({
    testName: '', //string input
    testFormat: '', 
    tags: [],
    levelRating: 0,
    stream: '',
    subjects: [],
    status: '',
    verified: null,
    programName: null, //string input
    orderSequence: null,
    instructions: null,
    exam: '',
    testTitle: '', //string input
    resumeEnabled: null,
    sectionPartition: null,
    maxMarks: '',

    setTestName: (testName) => set({ testName }),
    setTestFormat: (testFormat) => set({ testFormat }),
    setTags: (tags) => set({ tags }),
    setLevelRating: (levelRating) => set({ levelRating }),
    setStream: (stream) => set({ stream }),
    setSubjects: (subjects) => set({ subjects }),
    setStatus: (status) => set({ status }),
    setVerified: (verified) => set({ verified }),
    setProgramName: (programName) => set({ programName }),
    setOrderSequence: (orderSequence) => set({ orderSequence }),
    setInstructions: (instructions) => set({ instructions }),
    setExam: (exam) => set({ exam }),
    setTestTitle: (testTitle) => set({ testTitle }),
    setResumeEnabled: (resumeEnabled) => set({ resumeEnabled }),
    setSectionPartition: (sectionPartition) => set({ sectionPartition }),
    setMaxMarks: (maxMarks) => set({ maxMarks }),

    resetFilters: () => set({
        testName: '',
        testFormat: '',
        tags: [],
        levelRating: 0,
        stream: '',
        subjects: [],
        status: '',
        verified: null,
        programName: null,
        orderSequence: null,
        instructions: null,
        exam: '',
        testTitle: '',
        resumeEnabled: null,
        sectionPartition: null,
        maxMarks: '',
    }),

    addSomeValues: () => set({
        testName: '',
        testFormat: 'jee-mains-300',
        tags: ['jee-main'],
        levelRating: 3,
        stream: 'engineering',
        subjects: ['chemistry', 'physics'],
        status: 'active',
        verified: true,
        programName: 'maths-for-jee-mains',
        orderSequence: 2,
        instructions: true,
        exam: 'jee-mains',
        testTitle: '',
        resumeEnabled: true,
        sectionPartition: true,
        maxMarks: 300,
    }),
}));
