import { create } from 'zustand';

export const blogStore = create((set) => ({
  blogType: 'blog',
  title: '',
  coverImage: '',
  description: '',
  blogContent: '',
  tags: [],
  category: '',
  readTime: '',
  subject: '',
  chapter: '',

  setBlogType: (blogType) => set({ blogType }),
  setTitle: (title) => set({ title }),
  setCoverImage: (coverImage) => set({ coverImage }),
  setDescription: (description) => set({ description }),
  setBlogContent: (blogContent) => set({ blogContent }),
  setTags: (tags) => set({ tags }),
  setCategory: (category) => set({ category }),
  setReadTime: (readTime) => set({ readTime }),
  setSubject: (subject) => set({ subject }),
  setChapter: (chapter) => set({ chapter }),

  setBlogValues: (blogValues) => set((state) => ({
    blogType: blogValues.blogType !== undefined ? blogValues.blogType : state.blogType,
    title: blogValues.title !== undefined ? blogValues.title : state.title,
    coverImage: blogValues.coverImage !== undefined ? blogValues.coverImage : state.coverImage,
    description: blogValues.description !== undefined ? blogValues.description : state.description,
    blogContent: blogValues.blogContent !== undefined ? blogValues.blogContent : state.blogContent,
    tags: blogValues.tags !== undefined ? blogValues.tags : state.tags,
    category: blogValues.category !== undefined ? blogValues.category : state.category,
    readTime: blogValues.readTime !== undefined ? blogValues.readTime : state.readTime,
    subject: blogValues.subject !== undefined ? blogValues.subject : state.subject,
    chapter: blogValues.chapter !== undefined ? blogValues.chapter : state.chapter,
  })),

  addTestValue: () => set({
    blogType: 'blog',
    title: 'Introduction to Quantum Computing',
    coverImage: 'https://picsum.photos/',
    description: 'A brief overview of quantum computing and its potential applications.',
    blogContent: '<p>Quantum computing is a rapidly emerging technology...</p>',
    tags: ['quantum computing', 'technology', 'future'],
    category: 'engineering',
    readTime: '5 min',
    subject: 'Computer Science',
    chapter: 'Quantum Computing Basics',
  }),

  reset: () =>
    set({
      blogType: 'blog',
      title: '',
      coverImage: '',
      description: '',
      blogContent: '',
      tags: [],
      category: '',
      readTime: '',
      subject: '',
      chapter: '',
    }),
}));