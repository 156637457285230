import React, { createContext, useState } from 'react';

export const Test = createContext();

export default function TestContext({ children }) {
    const [testQuestions, setTestQuestions] = useState([]);
    return (
        <Test.Provider value={{ testQuestions, setTestQuestions }} >
            {children}
        </Test.Provider >
    );
}

