import React, { useEffect, useState } from 'react';
import Editor2 from 'components/Editor2/Editor2';
import MathJax from 'react-mathjax';
import { Box, Paper, Typography, Tabs, Tab, Divider, Button, Dialog, DialogContent, DialogContentText, DialogTitle, DialogActions, Checkbox, Tooltip, CircularProgress } from '@mui/material';
import { getIndexFromOption } from 'utils/getIndex';
import { PlusIcon, Trash2 } from 'lucide-react';
import axios from 'axios';
import { QuesDetailDropdowns } from './components/QuesDetailDropdowns';
import ImageUploader from '../Common/ImageUpload';
import { transformOptions } from 'utils/transformOptions';
import { questionStore } from 'components/store/questionStore';
import { useParams } from 'react-router-dom';
import { questionEditorState } from './store/questionEditorState';
import { renderMixedContent } from 'utils/renderMathExp';

const tabs = ['Question', 'Options', 'Explanation', 'Comprehension'];

const QuestionEditorLayout = () => {
  const {
    language,
    questionKey,
    questionType,
    section,
    questionContent,
    comprehension,
    options,
    correct_options,
    answerExplanation,
    marks,
    negMarks,
    isPartialMarks,
    subject,
    chapter,
    chapterGroup,
    topic,
    levelRating,
    tags,
    paperId,
    paperTitle,
    exam,
    year,
    isOutOfSyllabus,
    isBonus,
    addOptions,
    deleteOption,
    setCorrectOptions,
    clearForm,
    setDefaultValues,
    setQuestion,
  } = questionStore(state => state);

  const StringFromIndex = (index) => {
    return String.fromCharCode(65 + index);
  }


  const {
    isFetchingQuestions,
    setFetchingQuestions,
  } = questionEditorState(state => state);
  

  const [tabValue, setTabValue] = useState(-1);
  const [optionIndex, setOptionsIndex] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);

  const { quesId } = useParams();

  useEffect(() => {
    clearForm();
    setTabValue(-1);

    if (quesId) {
      fetchQuestionData();
    }
    else {
      // setDefaultValues();
    }
  }, [quesId]);

  const fetchQuestionData = async () => {
    setFetchingQuestions(true);
    try {
      const response = await fetch(
        "https://api.testbuddy.live/v1/admin/question/detail",
        {
          method: "POST",
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`,
          },
          body: JSON.stringify({ "questionId": quesId })
        }
      );
      const data = await response.json();
      console.log(data);
      setQuestion(data);
    } catch (error) {
      console.error("An error occurred while fetching data:", error);
    } finally {
      setFetchingQuestions(false);
    }
  };


  const handleDeleteOption = () => {
    setOpenDialog(true);
  };

  const handleConfirmDelete = () => {
    deleteOption(optionIndex);
    setOpenDialog(false);

    setOptionsIndex(0);

    const newCorrectOptions = correct_options.filter((option) => option !== StringFromIndex(optionIndex));
    setCorrectOptions(newCorrectOptions);

  };

  const handleCancelDelete = () => {
    setOpenDialog(false);
  };


  const handleOptionChange = (index) => {
    setOptionsIndex(index);
  };

  const handleAddOption = () => {
    addOptions();
    setOptionsIndex(options.length);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleNextButton = () => {
    setTabValue((prev) => prev + 1);
  };

  const handlePrevButton = () => {
    setTabValue((prev) => prev - 1);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const errors = {};
    if (!language) errors.language = 'Please select a language';
    if (language === 'hindi' && !questionKey) errors.questionKey = 'Please enter the question key';
    if (!questionType) errors.questionType = 'Please select a question type';
    if (!section) errors.section = 'Please select a section';
    if (!questionContent) errors.questionContent = 'Please enter the question content';
    // if (!comprehension) errors.comprehension = 'Please enter the comprehension';
    if (!options) errors.options = 'Please enter the options';
    if (!correct_options) errors.correct_options = 'Please select the correct options';
    // if (!answerExplanation) errors.answerExplanation = 'Please enter the answer explanation';
    if (!marks) errors.marks = 'Please enter the marks';
    if (!negMarks) errors.negMarks = 'Please enter the negative marks';
    if (!subject) errors.subject = 'Please select a subject';
    if (!chapter) errors.chapter = 'Please select a chapter';
    if (!chapterGroup) errors.chapterGroup = 'Please select a chapter group';
    if (!topic) errors.topic = 'Please select a topic';
    if (!levelRating) errors.levelRating = 'Please select a level rating';
    if (!tags) errors.tags = 'Please enter the tags';
    if (!paperId) errors.paperId = 'Please enter the paper ID';
    if (!paperTitle) errors.paperTitle = 'Please enter the paper title';
    if (!exam) errors.exam = 'Please select an exam';
    if (!year) errors.year = 'Please select a year';
    if (!String(isOutOfSyllabus)) errors.isOutOfSyllabus = 'Please select an out of syllabus option';
    if (!String(isBonus)) errors.isBonus = 'Please select a bonus option';

    if (Object.keys(errors).length > 0) {
      alert(Object.values(errors).join('\n'));
      return;
    }

    const JsonData = {
      _id: quesId,
      language,
      questionKey,
      questionType,
      section,
      questionContent,
      options: transformOptions(options),
      correct_options,
      answerExplanation : answerExplanation ? answerExplanation : "",
      comprehension : comprehension ? comprehension : "",
      marks,
      negMarks,
      isPartialMarks,
      subject,
      chapter,
      chapterGroup,
      topic,
      levelRating,
      tags,
      paperId,
      paperTitle,
      exam,
      year,
      isOutOfSyllabus,
      isBonus,
    };

    handleAddQuestion(JSON.stringify(JsonData));

    console.log('Submitted');
  };

  //todo: currently works only with capital letters
  const handleCorrectOptionChange = (index) => {
    const opt = StringFromIndex(index);
    const newCorrectOptions = correct_options.includes(opt)
      ? correct_options.filter((option) => option !== opt)
      : [...correct_options, opt];
    setCorrectOptions(newCorrectOptions);
  };

  const handleAddQuestion = async (data) => {
    console.log(data);

    try {
      const response = await axios.post('https://api.testbuddy.live/v1/admin/question/update', data, {
        headers: {
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("token")
          )}`,
        },
      })
        .then(response => {
          // handle response
          console.log("response: ", response);

          // clear the form
          clearForm();
        })
        .catch(error => {
          // handle error
          console.log("error:", error);
        });
      console.log(response);
    }
    catch (error) {
      console.error(error);
    }
  }


  const correctIndices = correct_options.map(getIndexFromOption);

  return (
    !isFetchingQuestions ?
      (<Box sx={{ display: 'flex', flexGrow: 1, height: '80vh', p: 2, gap: 2 }}>
        {/* Left side - Preview */}
        <MathJax.Provider>
          <Box sx={{
            flex: 1, overflow: 'auto', display: 'flex', flexDirection: 'column', gap: 2, border: '1px solid #ccc', borderRadius: 2, p: 4,
            // Hide scrollbar for Chrome, Safari, and Opera
            '&::-webkit-scrollbar': {
              display: 'none',
            },
            // Hide scrollbar for IE, Edge, and Firefox
            '-ms-overflow-style': 'none', // IE and Edge
            'scrollbar-width': 'none', // Firefox
          }}>

          {comprehension && (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <Typography variant="h6">Comprehension</Typography>
              <div style={{ whiteSpace: 'normal' }}>
                <Typography variant="body1">
                  {renderMixedContent(comprehension)}
                </Typography>
              </div>
              <Divider sx={{ backgroundColor: 'black' }} />
            </Box>
          )
          }

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Typography variant="h6">Question</Typography>
            <div style={{ whiteSpace: 'normal' }}>
              <Typography variant="body1">
                {renderMixedContent(questionContent)}
              </Typography>
            </div>
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mx: 2 }}>
            {options.map((option, index) => (
              <Box
                fullWidth
                key={index}
                sx={{
                  cursor: "pointer",
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'start',
                  py: 1,
                  px: 2,
                  border: '1px solid #ccc',
                  borderRadius: 1,
                  ...(correctIndices.includes(index) && {
                    borderColor: 'green',
                    backgroundColor: 'rgba(0, 255, 0, 0.1)',
                  }),
                }}
              > 
              <div style={{ whiteSpace: 'normal' }}>
                <Typography variant="body1" sx={{ mb: 1 }}>
                  {StringFromIndex(index)} .{renderMixedContent(option)}
                </Typography>
              </div>
              </Box>  
            ))}
          </Box>

          {answerExplanation && (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <Divider sx={{ backgroundColor: 'black' }} />
              <Typography variant="h6">Explanation</Typography>
              <div style={{ whiteSpace: 'normal' }}>
                <Typography variant="body1">{renderMixedContent(answerExplanation)}
                </Typography>
              </div>
            </Box>
          )
          }
          </Box>
        </MathJax.Provider>

        {/* Right side - Editor */} 
        <Box sx={{
          flex: 1, overflow: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2,
          // Hide scrollbar for Chrome, Safari, and Opera
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          // Hide scrollbar for IE, Edge, and Firefox
          '-ms-overflow-style': 'none', // IE and Edge
          'scrollbar-width': 'none', // Firefox
        }}
        >

          <QuesDetailDropdowns />
          <ImageUploader sx={{ width: '100%' }} />

          <Paper elevation={0} sx={{ flexGrow: 1, width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, border: '1px solid #ccc', borderRadius: 2, p: 2 }}>

            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
            >
              {tabs.map((tab, index) => (
                <Tab key={index} label={tab} />
              ))}
            </Tabs>
            {tabValue === 0 && <Editor2 tab="question" storename={'question'}/>}
            {tabValue === 1 &&
              <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, }}>
                  {Array.from(Array(options.length).keys()).map((index) => (
                    <Button
                      key={index}
                      onClick={() => handleOptionChange(index)}
                      sx={{
                        fontWeight: 'bold',
                        border: '1px solid #2196f3',
                        borderRadius: 1,
                        backgroundColor: index === optionIndex ? '#2196f3' : 'white',
                        color: index === optionIndex ? 'white' : '#2196f3',
                        '&:hover': {
                          backgroundColor: '#2196f3',
                          color: 'white',
                        },
                        padding: '4px 8px',
                        fontSize: '12px',
                        minWidth: '30px',
                      }}
                    >
                      {index + 1}
                    </Button>
                  ))}
                  <Button sx={{
                    fontWeight: 'bold',
                    border: '1px solid #2196f3',
                    borderRadius: 1,
                    backgroundColor: 'white',
                    color: '#2196f3',
                    '&:hover': {
                      backgroundColor: '#2196f3',
                      color: 'white',
                    },
                    padding: '4px 8px',
                    fontSize: '12px',
                    minWidth: '30px',
                  }}
                    onClick={handleAddOption}
                  >
                    <PlusIcon size={20} />
                  </Button>
                </Box>

                {/* editor with correct and delete option */}
                <Box sx={{ display: 'flex', flexDirection: 'row', position: 'relative', gap: 2 }}>
                  <Editor2 tab={`options ${optionIndex}`} storename={'question'}/>
                  <Box sx={{ position: 'absolute', top: 0, right: 0, marginRight: 4, marginTop: 1 }}>
                    <Tooltip title="Correct option">
                      <Checkbox
                        checked={correctIndices.includes(optionIndex)}
                        onChange={(e) => handleCorrectOptionChange(optionIndex)}
                        sx={{ marginRight: 1 }}
                      />
                    </Tooltip>
                    <Button onClick={handleDeleteOption}>
                      <Trash2 size={20} color="red" />
                    </Button>
                  </Box>
                </Box>

                {/* delete dialog */}
                <Dialog
                  open={openDialog}
                  onClose={handleCancelDelete}
                  aria-labelledby="delete-option-dialog"
                  aria-describedby="delete-option-dialog-description"
                  sx={{
                    '& .MuiDialog-paper': {
                      borderRadius: 4,
                      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
                      padding: 2,
                    },
                  }}
                >
                  <DialogTitle id="delete-option-dialog" sx={{ fontSize: 18, fontWeight: 'bold' }}>
                    Delete Option
                  </DialogTitle>
                  <DialogContent sx={{ padding: 2 }}>
                    <DialogContentText sx={{ fontSize: 16, color: '#333' }}>
                      Are you sure you want to delete option {optionIndex + 1}?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions sx={{ padding: 2, justifyContent: 'space-between' }}>
                    <Button onClick={handleCancelDelete} sx={{ fontSize: 16, color: '#666' }}>
                      Cancel
                    </Button>
                    <Button onClick={handleConfirmDelete} color="error" sx={{ fontSize: 16, }}>
                      Delete
                    </Button>
                  </DialogActions>
                </Dialog>
              </Box>
            }
            {tabValue === 2 && <Editor2 tab="explanation" storename={'question'}/>}
            {tabValue === 3 && <Editor2 tab="comprehension" storename={'question'}/>}
          </Paper>
          <Box sx={{ display: 'flex', gap: 2 }}>
            {tabValue > 0 && <Button variant="outlined" color="primary" sx={{ width: 120, fontSize: 16 }} onClick={handlePrevButton}>
              Prev
            </Button>}
            {tabValue < tabs.length - 1 &&
              <Button variant="contained" color="primary" sx={{ width: 120, fontSize: 16 }} onClick={handleNextButton}>
                Next
              </Button>}
            {tabValue === tabs.length - 1 && <Button variant="contained" color="primary" sx={{ width: 120, fontSize: 16 }} onClick={handleSubmit}>
              Submit
            </Button>}
          </Box>
        </Box>
      </Box>)
      : (<Box sx={{ height: '100%', width: '100%', display: 'flex', flexGrow: 1, p: 2, placeContent: 'center', }}> <CircularProgress /></Box>)
  );
};

export default QuestionEditorLayout;