import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, Modal, Box, IconButton, Typography, Avatar } from '@mui/material';
import profilePic from 'assets/icons/logo-icon.png';
import logo from 'assets/icons/logo.png';
import Login from 'components/Auth/Login';
import InfoContext from 'contexts/infoContext';

const PreloginHeader = () => {
  const context = useContext(InfoContext);
  const { showLoginModal, setShowLoginModal } = context;
  const [loginShow, setLoginShow] = useState(false);

  const handleHideLogin = () => {
    setLoginShow(false);
    setShowLoginModal(false);
  };

  return (
    <AppBar position="static" sx={{ height: '4.5rem', backgroundColor: '#87CEEB' }}>
      <Toolbar>
        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" width="100%" height="100%">
          <Link to="/">
            <Avatar src={logo} alt='logo' variant="square" sx={{ height: { xs: '1.8rem', sm: '2.5rem', md: '3rem' }, width: 'auto' }} />
          </Link>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Modal open={loginShow || showLoginModal} onClose={handleHideLogin}>
              <Login open={loginShow || showLoginModal} onClose={handleHideLogin} />
            </Modal>
            <IconButton onClick={() => setLoginShow(true)} sx={{ '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.1)', borderRadius: '4px' } }}>
              <Typography variant="button" sx={{ marginRight: '0.5rem', color: 'white', fontWeight: 'bold' }}>
                Sign Up
              </Typography>
              <Avatar src={profilePic} alt="profile" sx={{ height: { xs: '2rem', md: '2.5rem' }, width: { xs: '2rem', md: '2.5rem' } }} />
            </IconButton>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default PreloginHeader;