import React, { useRef } from 'react';
import JoditEditor from 'jodit-react';

const CustomJoditEditor = ({ initialValue, onChange }) => {
    const editor = useRef(null);

    return (
        <JoditEditor
            ref={editor}
            value={initialValue}
            config={{
                readonly: false, // all options from https://xdsoft.net/jodit/doc/
                toolbar: true,
                height: 400,
            }}
            tabIndex={1} // tabIndex of textarea
            onBlur={(newContent) => onChange(newContent)} // preferred to use only this option to update the content for performance reasons
            // onChange={(newContent) => {}}
        />
    );
};

export default CustomJoditEditor;
