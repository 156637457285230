import React, { useEffect, useState } from 'react';
import { Modal, Button, Typography, Box, Paper, Divider } from '@mui/material';
import MathJax from 'react-mathjax';
import { renderMixedContent } from 'utils/renderMathExp';

export default function ViewQuestionPopUp({ handleViewQuestion, handleCloseViewQuestion, setShowDeleteModal, setViewQuestion, index, isTest }) {
    const [question, setQuestion] = useState(undefined);

    useEffect(() => {
        questionDetail();
    }, [index]);

    async function questionDetail() {
        try {
            const response = await fetch("https://api.testbuddy.live/v1/admin/question/detail", {
                method: "POST",
                headers: {
                    "content-type": "application/json",
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
                },
                body: JSON.stringify({ questionId: index })
            });
            const data = await response.json();
            if (response.status === 200) {
                setQuestion(data);
            } else {
                console.log("Some error occurred");
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <>
            {question !== undefined && (
                <Modal
                    open={true}
                    onClose={() => setViewQuestion(false)}
                    aria-labelledby="modal-title"
                    aria-describedby="modal-description"
                >
                    <Paper sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '90%',
                        maxWidth: 800,
                        maxHeight: '90vh',
                        p: 4,
                        overflowY: 'auto',
                        borderRadius: '16px',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                    }}>

                        <Typography id="modal-title" variant="h6" component="h2" gutterBottom>
                            <strong>Question Id: </strong> {index}
                        </Typography>
                        <Divider sx={{ backgroundColor: 'black' }} />
                        <MathJax.Provider>
                            <div style={{ whiteSpace: 'normal' }}>

                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, }}>
                                    {question.comprehension &&
                                        <Box >
                                            <Typography variant="body1" paragraph>
                                                {renderMixedContent(question.comprehension)}
                                            </Typography>
                                            <Divider sx={{ backgroundColor: 'black' }} />
                                        </Box>

                                    }

                                    <Box >
                                        <Typography variant="body1" paragraph>
                                            {renderMixedContent(question.questionContent)}
                                        </Typography>
                                    </Box>

                                    {question.questionType === "mcq" && (
                                        <Box className='options-view-question'>
                                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, m: 2 }}>
                                                {question.options && (question.options.map((option, index) => (
                                                    <Box
                                                        fullWidth
                                                        key={index}
                                                        sx={{
                                                            cursor: "pointer",
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            alignItems: 'center',
                                                            justifyContent: 'start',
                                                            py: 1,
                                                            px: 2,
                                                            border: '1px solid #ccc',
                                                            borderRadius: 1,
                                                            ...(question.correct_options.includes(String.fromCharCode(65 + index)) && {
                                                                borderColor: 'green',
                                                                backgroundColor: 'rgba(0, 255, 0, 0.1)',
                                                            }),
                                                        }}
                                                    >
                                                        <Typography key={index} variant="body2" paragraph>
                                                            {String.fromCharCode(65 + index)}. {renderMixedContent(option.content)}
                                                        </Typography>
                                                    </Box>
                                                )))}
                                            </Box>
                                        </Box>
                                    )}

                                    {question.answerExplanation && (
                                        <>
                                            <Divider sx={{ backgroundColor: 'black' }} />
                                            <Box>
                                                <Typography variant="h6" gutterBottom><strong>Explanation</strong></Typography>
                                                <Typography variant="body1" paragraph>
                                                    {renderMixedContent(question.answerExplanation)}
                                                </Typography>
                                            </Box>
                                        </>
                                    )}

                                </Box>
                            </div>
                        </MathJax.Provider>

                        {!isTest && (
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
                                <Button
                                    variant="contained"
                                    color="error"
                                    onClick={() => {
                                        handleCloseViewQuestion();
                                        setShowDeleteModal(true);
                                    }}
                                >
                                    Delete Question
                                </Button>
                                <Button
                                    variant="contained"
                                    onClick={() => handleCloseViewQuestion()}
                                >
                                    Edit Question
                                </Button>
                            </Box>
                        )}
                    </Paper>
                </Modal>

            )}
        </>
    );
}