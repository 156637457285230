import React, { useContext } from 'react';
import { Button, Menu, MenuItem, Modal, Box } from '@mui/material';
import { styled } from '@mui/system';
import InfoContext from '../../contexts/infoContext';
import Login from 'components/Auth/Login';

const ProfilePic = ({ profilePic, setLoginShow }) => {
  const context = useContext(InfoContext);
  const { token, handleLogout } = context;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        aria-controls="profile-menu"
        aria-haspopup="true"
        onClick={handleClick}
        style={{ padding: 0, minWidth: 'auto' }}
      >
        <ProfileImg src={profilePic} alt="profileimage" style={{ borderRadius: '50%', objectFit: 'cover' }} />
      </Button>
      <Menu
        id="profile-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {token ? (
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        ) : (
          <MenuItem onClick={() => setLoginShow(true)}><Login /></MenuItem>
        )}
      </Menu>
    </>
  );
};

export default ProfilePic;

const ProfileImg = styled('img')(({ theme }) => ({
  height: '2rem',
  width: '2rem',
  [theme.breakpoints.up('md')]: {
    width: '2.5rem',
    height: '2.5rem',
  },
}));