import React, { useContext } from 'react';
import PreloginHeader from './components/PreloginHeader';
import PostLoginHeader from './components/PostLoginHeader';
import InfoContext from '../../contexts/infoContext';
import { engNav, medNav } from '../../assets/data/navigations';


export const Header = () => {
    const context = useContext(InfoContext);
    const { token, stream } = context;

    return (
        <>
            {
                !token ? <PreloginHeader /> : <PostLoginHeader navigation={stream === 'medical' ? medNav : engNav} />
            }
        </>
    );
};